import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $searchString: String, $currencyFromId: String, $currencyToId: String, $networkFromId: String, $networkToId: String, $trxType: String, $limit: Number!, $offset: Number!, $dateFrom: String, $dateTo: String) {
        getAllTransactionOperations(organizationId: $organizationId, searchString: $searchString, currencyFromId: $currencyFromId, currencyToId: $currencyToId, networkFromId: $networkFromId, networkToId: $networkToId, trxType: $trxType, limit: $limit, offset: $offset, dateFrom: $dateFrom, dateTo: $dateTo) {
            transactions {
                id
                operationId
                parentOperationId
                isParent
                organizationId
                organizationAlias
                organizationInternalName
                organizationIsInternal
                organizationKyc
                organizationAml
                organizationOwnerEmail
                organizationOwnerId
                organizationIsTestnet
                userId
                userEmail
                userRole
                apiKeyId
                apiKeyPublic
                apiKeyAlias
                startTs
                finishTs
                amountTo
                amountFrom
                rateFromTo
                currencyFromId
                currencyFrom
                currencyToId
                currencyTo
                currencyFromType
                currencyToType
                networkFromId
                networkFrom
                networkToId
                networkTo
                addressFrom
                addressTo
                addressRiskFrom
                addressRiskTo
                exchangeName
                exchangeAddressFrom
                exchangeAddressTo
                exchangeTxFrom
                exchangeTxTo
                transactionType
                status
                networkFeeFrom
                networkFeeFromSource
                networkFeeFromAmountInCurrencyFrom
                networkFeeFromUSD
                networkFeeFromEUR
                networkFeeTo
                networkFeeToSource
                externalNetworkFee
                serviceFee
                serviceFeeInCurrency
                serviceFeeUSD
                serviceFeeEUR
                advanceBalanceStart
                advanceBalanceFinish
                advanceBalanceStartTs
                advanceBalanceFinishTs
                advanceBalanceFullComissionUSD
                advanceBalanceFullComissionEUR
                advanceBalanceNetworkFeeUSD
                advanceBalanceNetworkFeeEUR
                advanceBalanceServiceFeeAmountUSD
                advanceBalanceServiceFeeAmountEUR
                advanceBalanceServiceFeePercent
                successWebhookUrl
                errorWebhookUrl
                returnUrl
                entityId
                relatedEntityId
                txHash
                errorMessage
                isOrphan
                orphanId
                hasImmediateData
                createdAt
                updateAt
                deletedAt
            }
            totalCount
        }
    }
`;

