export const mutations = {
    transactions: (state, transactions) => {
        state.transactions = transactions;
    },
    setTransactionsNew: (state, transactions) => {
        state.transactionsNew = transactions;
    },
    totalTransactionsCount: (state, count) => {
        state.totalTransactionsCount = count;
    },
    setFetching: (state, payload) => {
        state.isLoading = payload
    },
    setTransaction: (state, payload) => {
        state.transaction = payload
    },
    setTransactionTypes: (state, payload) => {
        state.types = payload
    },

    setTransactionInfoState : (state, payload) => {
        state.isInfoOpened = payload
    },
    setColumns: (state, payload) => {
        state.columns = payload
    },
    clearAllStates (state) {
        state.transactionsCountOnPage = 10
        state.transactions = []
        state.totalTransactionsCount = null
        state.isLoading = false
        state.transaction = null
        state.isInfoOpened = false

    }
};
