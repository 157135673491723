import { fetchTransactions } from "./actions/fetchTransactions";
import { fetchTransactionsNew } from "./actions/fetchTransactionsNew";
import { fetchTransaction } from "./actions/fetchTransaction";
import { downloadCSV } from './actions/downloadCSV';
import { downloadXLSX } from './actions/downloadXLSX';
import { sendColumns } from "@/public/store/modules/transactions/actions/sendColumns";
import { getColumns } from "@/public/store/modules/transactions/actions/getColumns";
import { fetchTransactionsFilters } from "@/public/store/modules/transactions/actions/fetchTransactionsFilters";

export const actions = {
    fetchTransactions,
    fetchTransactionsNew,
    fetchTransaction,
    fetchTransactionsFilters,
    downloadCSV,
    downloadXLSX,
    sendColumns,
    getColumns
}
