import getTransactionsQuery from "/public/graphQl/queries/getAllTransactionOperations";

export const fetchTransactionsNew = async ({state, rootState, rootGetters, commit, dispatch}, payload) => {
    const {
        page,
        searchString,
        trxType,
        currencyFromId,
        networkFromId,
        dateTo,
        dateFrom
    } = payload;

    const { transactionsCountOnPage } = state;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        limit: transactionsCountOnPage,
        offset: (page - 1) * 10,
        searchString,
        trxType,
        currencyFromId,
        networkFromId,
        dateTo,
        dateFrom
    };

    let transactionsData;

    try {
        commit('app/toggleLoading', null, {root: true});
        transactionsData = await dispatch('app/request', {
            type: 'query',
            gql: getTransactionsQuery,
            variables,
        }, {root: true});
    } catch (error) {
        console.error(error);
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    // Если нет доступа или данных - незачем блокировать всю страницу
    // if (transactionsData.message) {
    //     throw transactionsData.message;
    // }
    //
    // if (transactionsData.errors) {
    //     throw transactionsData.errors;
    // }

    if (!transactionsData.message && !transactionsData.errors) {
        const {
            data: {
                getAllTransactionOperations: {
                    transactions,
                    totalCount
                }
            },
        } = transactionsData;

        // addressFrom: "TUdtD3oXvX37NM5mH5W561p6GSeDHeUDTD"
        // addressTo: "TYNrmdQ8HXYxxDqhm2ZyKoFHNkPBHHfCJp"
        // basis: "deposit"
        // createdAt: "2025-01-27T09:01:13.121Z"
        // credit: null
        // currencyId: "03dbd2a5-3148-413d-a26c-8de1103d34a5"
        // deposit: "9.44"
        // externalId: null
        // id: "11af58da-d102-4653-849f-9fc31bda470c"
        // networkId: "5e5add4a-2e2b-4067-bcb3-a65aad748b1a"
        // orderId: null
        // organizationId: "1f07eb01-5fd8-4e05-89b5-bebcd1d1fc39"
        // side: "external"
        // source: null
        // sourceId: null
        // status: "processed"
        // txId: "662b9fc7789e37fd1b2f20e1282b7aa4504cffcce3abcba50f24e69ec0ae246c"
        // type: "deposit_collect"


        commit('setTransactionsNew', transactions);
        commit('totalTransactionsCount', totalCount);
    }

    return true;
};
