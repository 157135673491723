export const getters = {
    userRoleRules: state => {
        if (!state.role) {
            return {};
        }
        return JSON.parse(state.role.rules);
    },
    userAllowedPages: (state, getters) => {
        const filteredObj = Object.entries(getters.userRoleRules).filter(([key, value]) => value.isAllowed);
        const allowedPages = filteredObj.map((item) => item[0]);
        //TODO удалить когда Хоня добавит разрешение для transactions_new
        allowedPages.push('transactions_new')
        return allowedPages;
     }
}
