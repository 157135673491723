<template>
<div class="filter">
    <div
      v-show="isFiltered"
      class="marker"
    />
    <div
      class="filter__trigger"
      @click="toggleFilter"
    >
      <slot name="trigger-inside">
        <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2C3.5 2.82843 2.82843 3.5 2 3.5ZM7 3.5C6.17157 3.5 5.5 2.82843 5.5 2C5.5 1.17157 6.17157 0.5 7 0.5C7.82843 0.5 8.5 1.17157 8.5 2C8.5 2.82843 7.82843 3.5 7 3.5ZM12 3.5C11.1716 3.5 10.5 2.82843 10.5 2C10.5 1.17157 11.1716 0.5 12 0.5C12.8284 0.5 13.5 1.17157 13.5 2C13.5 2.82843 12.8284 3.5 12 3.5Z"
            fill="var(--new-front-text-link-bg-modal-secondary)"
          />
        </svg>
      </slot>
    </div>

    <transition name="slide-fade">
        <div v-show="isFilterOpened" class="filter__content" v-click-outside="closeModal">
            <p class="filter__content-title">{{ $t('transaction-column-select.title') }}</p>
            <draggable
              :list="dragColumns"
              class="filter__draggable"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              handle=".drag-icon"
              @change="changeColumns"
            >
            <div :class="['filter__content-item', !active ? 'inactive' : '']" v-for="({ title, active }) in dragColumns">
                <div class="filter__content-item-left">
                    <svg class="drag-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 3.5C4 2.67157 4.67157 2 5.5 2C6.32843 2 7 2.67157 7 3.5C7 4.32843 6.32843 5 5.5 5C4.67157 5 4 4.32843 4 3.5ZM4 8.5C4 7.67157 4.67157 7 5.5 7C6.32843 7 7 7.67157 7 8.5C7 9.32843 6.32843 10 5.5 10C4.67157 10 4 9.32843 4 8.5ZM4 13.5C4 12.6716 4.67157 12 5.5 12C6.32843 12 7 12.6716 7 13.5C7 14.3284 6.32843 15 5.5 15C4.67157 15 4 14.3284 4 13.5Z" fill="var(--new-front-secondary-font-color)"/>
                        <path d="M9 3.5C9 2.67157 9.67157 2 10.5 2C11.3284 2 12 2.67157 12 3.5C12 4.32843 11.3284 5 10.5 5C9.67157 5 9 4.32843 9 3.5ZM9 8.5C9 7.67157 9.67157 7 10.5 7C11.3284 7 12 7.67157 12 8.5C12 9.32843 11.3284 10 10.5 10C9.67157 10 9 9.32843 9 8.5ZM9 13.5C9 12.6716 9.67157 12 10.5 12C11.3284 12 12 12.6716 12 13.5C12 14.3284 11.3284 15 10.5 15C9.67157 15 9 14.3284 9 13.5Z" fill="var(--new-front-secondary-font-color)"/>
                    </svg>
                    <span>{{ isNewTransactionsPage ? $t(`transactions_new.transaction_fields.${title}`) : $t(`transactions.${title}`) }}</span>
                </div>
                <svg v-if="active" class="eye-icon" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" @click="toggleColumn(title, active)">
                    <path d="M10.5 6C10.5 7.38071 9.38071 8.5 8 8.5C6.61929 8.5 5.5 7.38071 5.5 6C5.5 4.61929 6.61929 3.5 8 3.5C9.38071 3.5 10.5 4.61929 10.5 6Z" fill="var(--new-front-secondary-font-color)"/>
                    <path d="M0 6C0 6 3 0.5 8 0.5C13 0.5 16 6 16 6C16 6 13 11.5 8 11.5C3 11.5 0 6 0 6ZM8 9.5C9.933 9.5 11.5 7.933 11.5 6C11.5 4.067 9.933 2.5 8 2.5C6.067 2.5 4.5 4.067 4.5 6C4.5 7.933 6.067 9.5 8 9.5Z" fill="var(--new-front-secondary-font-color)"/>
                </svg>
                <svg v-else class="eye-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="toggleColumn(title, active)">
                    <path d="M10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61929 6.61929 5.5 8 5.5C9.38071 5.5 10.5 6.61929 10.5 8Z" fill="var(--new-front-secondary-font-color)"/>
                    <path d="M0 8C0 8 3 2.5 8 2.5C13 2.5 16 8 16 8C16 8 13 13.5 8 13.5C3 13.5 0 8 0 8ZM8 11.5C9.933 11.5 11.5 9.933 11.5 8C11.5 6.067 9.933 4.5 8 4.5C6.067 4.5 4.5 6.067 4.5 8C4.5 9.933 6.067 11.5 8 11.5Z" fill="var(--new-front-secondary-font-color)"/>
                    <rect x="0.0605469" y="1.36621" width="1.62771" height="20.5155" rx="0.813855" transform="rotate(-45.4573 0.0605469 1.36621)" fill="var(--new-front-secondary-font-color)"/>
                </svg>
            </div>
            </draggable>
        </div>
    </transition>
</div>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import vClickOutside from 'v-click-outside'
    import draggable from "vuedraggable";
    export default {
        name: "ColumnsFilter",
        components: { draggable, BaseButton },
        directives: {
            clickOutside: vClickOutside.directive
        },
        props: {
          closeOutside: {
            type: Boolean,
            default: true
          },
          isFiltered: {
            type: Boolean,
            default: false
          },
          isNewTransactionsPage: {
            type: Boolean,
            default: false
          }
        },
        data () {
          return {
              isFilterOpened: false,
              drag:false,
              dragColumns: []
          }
        },
        computed: {
            ...mapState('transactions', ['columns']),
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            localColumns () {
                // const unexpectedList = []
                // const mappedArr = this.columns.map(item => ({
                //     title: item,
                //     active: true
                // }))
                // this.defaultColumns.forEach(item => {
                //     const el = this.columns.find(col => col === item )
                //     if (!el) {
                //         unexpectedList.push({ title: item, active: false })
                //     }
                // })
                // return [
                //     ...mappedArr,
                //     ...unexpectedList
                // ]
            }
        },
        watch: {
          columns: {
            deep: true,
            immediate: true,
            handler (value) {
              this.dragColumns = value
            }
          }
        },
        methods: {
            ...mapActions('transactions', [
                'sendColumns',
                'getColumns'
            ]),
            toggleColumn (title, active) {
                const item = this.dragColumns.find((item) => item.title === title)
                item.active = !item.active

                console.log('toggleColumn this.dragColumns ---> ', this.dragColumns)
                this.sendColumns([this.isNewTransactionsPage ? 'transactionsColumnsNew' : 'transactionsColumns', this.dragColumns])
            },
            emitClick () {
                this.$emit('click')
            },
            toggleFilter (event) {
                this.isFilterOpened = !this.isFilterOpened
            },
            confirm () {
                this.$emit('confirm')
                this.isFilterOpened = false
            },
            closeModal (event) {
                if (event?.target?._prevClass === 'filter__trigger' || event?.target?.parentElement._prevClass === 'filter__trigger') return;
                this.isFilterOpened = false
            },
            changeColumns () {
                console.log('changeColumns this.dragColumns ---> ', this.dragColumns)
                this.sendColumns([this.isNewTransactionsPage ? 'transactionsColumnsNew' : 'transactionsColumns', this.dragColumns])
            }
        }
    }
</script>
<style scoped lang="scss">
.filter {
  position: relative;
    z-index: 4;
    &__trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      width: 48px;
      height: 48px;

      font-weight: 500;
      background: transparent!important;
      color: var(--text-text-primary-accent);

      border: 1px solid var(--new-front-border-btn-primary-bg-primary);
      border-radius: 10px;

      transition: background .2s ease;
      cursor: pointer;

      &:hover {
        background: var(--new-front-bg-btn-secondary-bg-modal-primary-hover);
      }
    }
    &__draggable {
        width: 100%;
    }
    &__content {
        position: absolute;
        right: 0;
        top: 58px;
        background: var(--new-front-main-bg);
        border: 1px solid var(--new-front-input-border);
        border-radius: 10px;
        min-width: 250px;
        min-height: 100px;
        max-height: calc(100vh / 1.5);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 10px 20px;
        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 0;
            width: 100%;
            &:hover {
               span {
                   color:  var(--new-front-purple);
               }
            }
            &-left {
                display: flex;
                gap: 10px;

                span {
                  padding-right: 5px;
                  color: var(--new-front-secondary-font-color);
                  font-weight: 500;
                  font-size: $h4;
                  text-align: left;
                }
            }
        }
        &-title {
            color: var(--new-front-primary-font-color);
            font-size: $h4;
            font-weight: 500;
        }
    }
    .marker {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-15%, -50%);

      width: 16px;
      height: 16px;
      background: #FFFFFF;
      border-radius: 50%;;
      border: 5px solid #744DB2
    }
}
.drag-icon {
    cursor: pointer;
    &:hover {
        path {
            fill: var(--new-front-purple)
        }
    }
}
.eye-icon {
    min-width: 16px;
    min-height: 11px;
    cursor: pointer;
    &:hover {
        path {
            fill: var(--new-front-purple)
        }
        rect {
            fill: var(--new-front-purple)
        }
    }
}
.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-leave-active {
    transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
}
.inactive {
    opacity: 0.6;
}
</style>
