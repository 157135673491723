import MainPage from '/public/pages/index';
import Analytics from '/public/pages/analytics';
import DashboardPage from '/public/pages/dashboard';
import OrdersPage from '/public/pages/orders';
import OrderPage from '/public/pages/order';
import TransactionsPage from '/public/pages/transactions';
import TransactionsNewPage from '/public/pages/transactions_new';
import TransactionPage from '/public/pages/transaction';
import BalancesPage from '/public/pages/balances';
import ExchangesPage from '/public/pages/exchanges';
import RequestsPage from '/public/pages/requests';
import RequestPage from '/public/pages/request';
import TestApiPage from '/public/pages/test-api';
import ApiKeysPage from '/public/pages/api-keys/api-keys.vue';
import ExampleApiPage from '/public/pages/example-api';
import ApiDocumentation from '/public/pages/api-documentation';
import SettingsPage from '/public/pages/settings';
import ExchangePage from '/public/pages/exchange';
import RollingReservePage from '/public/pages/rolling-reserve';
import WithdrawalFeesPage from '/public/pages/withdrawal-fees';
import AdvancedBalances from '/public/pages/advanced-balances';
import AccountHistoryPage from '/public/pages/account-history';
import DashboardOrders from "../components/elements/dashboard/DashboardOrders";
import orderTransaction from "../pages/order-transaction";
import PayoutWallet from "../pages/payout-wallet";
import PayoutWalletBalance from "../components/elements/payoutWallet/PayoutWalletBalance";
import PayoutWalletRegistry from "../components/elements/payoutWallet/PayoutWalletRegistry";
import DonationsPage from '/public/pages/donations';
import DonationPage from "/public/pages/donation";
import CreateDonation from "../pages/create-donation";
import EditDonation from '../pages/edit-donation';
import DonationOrderInfo from "../pages/DonationOrderInfo";
import Invoices from "../pages/invoices";
import InvoicePage from '/public/pages/invoice';
// import change from "/public/pages/change"; //ComingSoon компонент
import crossChain from "/public/pages/crossChain";
import crossChainHistory from "/public/pages/crossChainHistory";
import swap from "/public/pages/swap";
import swapHistory from "/public/pages/swap/History";

import organizations from "/public/pages/organizations";
import subscriptions from "/public/pages/subscriptions";
import addressBook from "/public/pages/addressBook";
import historyAddresses from "/public/pages/historyAddresses";
import historyPayIn from "/public/pages/historyPayIn";
import historyBusiness from "/public/pages/historyBusiness";
import historyAddress from '/public/pages/historyAddress';
import currentHistoryAddress from '/public/pages/currentHistoryAddress';
// import businessWallet from "../pages/business-wallet-old";
import businessWallet from "../pages/business-wallet";
import referral from "../pages/referral";
import BufferBalanceHistory from "../pages/buffer-balance-history";
import RemunerationBalanceHistory from "../pages/remuneration-balance-history";
import subscriptionsAddress from "../pages/subscriptions-address";
import Login from "@/public/pages/login";
import LoginApollo from "@/public/pages/apollo/apolloLogin.vue";
import LoginConfirm from "@/public/pages/login-confirm";
import SignUp from "@/public/pages/sign-up";
import SignUpConfirm from "@/public/pages/sign-up-confirm";
import Registration from "@/public/pages/registration";
import OrphanTransactions from "@/public/pages/orphan-transactions.vue";
import OrphanInfo from "@/public/pages/orphan-info.vue";
import PersonalAddressesPage from '/public/pages/personal-addresses';
import webhooks from "@/public/pages/webhooks.vue";
import webhookInfo from "@/public/pages/webhook-info.vue";
import CollectingProfits from "@/public/pages/collecting-profits.vue";
import CollectingHistory from "@/public/pages/collecting-history.vue";
import AuthorizationHistory from "@/public/pages/authorization-history.vue";
import ClosedSession from "@/public/pages/closedSession.vue";
import RestorePassword from "@/public/pages/restore-password.vue";
import ApiKeysCreate from "@/public/pages/api-keys/api-keys-create";
import ApiKeysEdit from "@/public/pages/api-keys/api-keys-edit";
import bankInvoices from "@/public/pages/bankInvoices.vue";
import sepaSwift from "@/public/pages/sepaSwift.vue";

import LoginContainer from "@/public/pages/authContainers/LoginContainer.vue";
import registrationContainer from "@/public/pages/authContainers/registrationContainer.vue";
import ClosedSessionContainer from "@/public/pages/authContainers/closedSessionContainer.vue";
import RestoreContainer from "@/public/pages/authContainers/RestoreContainer.vue";
import ConfirmContainer from "@/public/pages/authContainers/ConfirmContainer.vue";
import integrations from "@/public/pages/integrations.vue";

import IntegrationSettings from "@/public/pages/integration-settings";
import payments from "@/public/pages/payments.vue";
import widgets from "@/public/pages/widgets/widgets.vue";
import widgetsManage from "@/public/pages/widgets/widgetsManage.vue";
import BuyCrypto from "@/public/pages/buy-crypto.vue";



export const routes = [
    {
        name: 'login',
        path: '/merchant/login',
        component: LoginContainer,
        meta: { title: 'Login' },
    },
    {
        name: 'login',
        path: '/login',
        component: LoginContainer,
        meta: { title: 'Login' },
    },
    {
        name: 'restore-password',
        path: '/restore-password/:linkId',
        component: RestoreContainer,
        meta: { title: 'Restore password' },
    },
    {
        name: 'sign-up',
        path: '/merchant/registration',
        component: SignUp,
        meta: { title: 'Registration' },
    },
    {
        name: 'closed-session',
        path: '/closed-session',
        component: ClosedSessionContainer,
        meta: { title: 'Security message' },
    },
    { path: '/logout', redirect: { name: 'login' }, meta: { title: 'Logout' }, },
    {
        name: 'login-2fa',
        path: '/login/2fa',
        component: ConfirmContainer,
    },
    {
        name: 'sign-up-confirm',
        path: '/sign-up/:linkId',
        component: SignUpConfirm,
        meta: { title: 'Sign up confirm' },
    },
    {
        name: 'sign-up',
        path: '/sign-up',
        component: SignUp,
        meta: { title: 'Sign up' },
    },

    /**
     * это нужно для обратной совместимости, удалить через пару месяцев после релиза новых урлов
     * */
    {
        name: 'registration-confirm',
        path: '/registration/:linkId',
        component: SignUpConfirm,
        meta: { title: 'Registration confirm' },
    },
    {
        name: 'registration',
        path: '/registration',
        component: registrationContainer,
        meta: { title: 'Registration' },
    },
    {
        name: 'registration-2-confirm',
        path: '/registration/:linkId',
        component: registrationContainer,
    },
    {
        name: 'registration-2',
        path: '/registration',
        component: registrationContainer,
    },
    /***/

    {
        name: 'index',
        path: '/',
        // redirect: {name: 'dashboard'},
        component: MainPage,
    },
    // the path is hidden in task 4061
    // {
    //     name: 'dashboard',
    //     path: '/dashboard',
    //     meta: { title: 'Dashboard' },
    //     components: {
    //         default: DashboardPage,
    //         DashboardOrders,
    //     },
    // },
    {
        name: 'donations',
        path: '/donations/',
        components: {
            default: DonationsPage,
            DonationsPage,
        },
        meta: { title: 'Donations' },
    },
    {
        name: 'donation',
        path: '/donation/:id',
        component: DonationPage,
        meta: { title: 'Donation' },
    },
    {
        name: 'donation-order',
        path: '/donation/order/:id',
        component: DonationOrderInfo,
        alias: 'donations',
        meta: { title: 'Donation order' },
    },
    {
        name: 'create-donation',
        path: '/create-donation',
        component: CreateDonation,
        meta: { title: 'Create donation' },
    },
    {
        name: 'edit-donation',
        path: '/edit-donation/:id',
        component: EditDonation,
        meta: { title: 'Edit donation' },
    },
    {
        name: 'orders',
        path: '/orders',
        component: OrdersPage,
        meta: { title: 'Orders' },
    },
    {
        name: 'order',
        path: '/order/:id',
        component: OrderPage,
        meta: { title: 'Order' },
    },
    {
        name: 'orders-transaction',
        path: '/order-transaction/:id',
        component: orderTransaction,
        meta: { title: 'Orders transaction' },
    },
    {
        name: 'transactions',
        path: '/transactions/',
        component: TransactionsPage,
        meta: { title: 'Transactions' },
    },
    {
        name: 'transactions-new',
        path: '/transactions-new/',
        component: TransactionsNewPage,
        meta: { title: 'Transactions New' },
    },
    {
        name: 'transaction',
        path: '/transaction/:id',
        component: TransactionPage,
        meta: { title: 'Transaction' },
    },
    {
        name: 'wallets',
        path: '/wallets',
        component: BalancesPage,
        meta: { title: 'Pay in' },
    },
    {
        name: 'exchanges',
        path: '/exchanges',
        component: ExchangesPage,
        meta: { title: 'Exchanges' },
    },
    {
        name: 'exchange',
        path: '/exchanges/:id',
        component: ExchangePage,
        meta: { title: 'Exchange' },
    },
    {
        name: 'requests',
        path: '/requests',
        component: RequestsPage,
        meta: { title: 'Requests' },
    },
    {
        name: 'request',
        path: '/request/:id',
        component: RequestPage,
        meta: { title: 'Request' },
    },
    {
        name: 'rolling-reserve',
        path: '/rolling-reserve',
        component: RollingReservePage,
        meta: { title: 'Rolling reserve' },
    },
    {
        name: 'apiKeys',
        path: '/keys-api/',
        component: ApiKeysPage,
        meta: { title: 'Api keys' },
    },
    {
        name: 'apiKeysCreate',
        path: '/keys-api-create',
        component: { ApiKeysCreate },
    },
    {
        name: 'apiKeysEdit',
        path: '/keys-api-edit',
        component: { ApiKeysEdit },
    },
    {
        name: 'testApi',
        path: '/api-test/',
        redirect: {name: 'apiExample', params: {type: 'get-balance'}},
        component: TestApiPage,
        meta: { title: 'Test api' },
        children: [
            {
                name: 'apiExample',
                path: ':type',
                component: ExampleApiPage,
            },
        ],
    },
    {
        name: 'apiDocumentation',
        path: '/documentation-api/',
        component: ApiDocumentation,
        meta: { title: 'Api documentation' },
    },
    {
        name: 'settings',
        path: '/settings/',
        component: SettingsPage,
        meta: { title: 'Settings' },
    },
    {
        name: 'withdrawal-fees',
        path: '/withdrawal-fees/',
        component: WithdrawalFeesPage,
        meta: { title: 'Information' },
    },
    // {
    //     name: 'advanced-balance-history',
    //     path: '/advanced-balances/:accountId',
    //     component: AccountHistoryPage,
    //     meta: { title: 'Advanced balances history' },
    // },
    {
        name: 'advanced-balances',
        path: '/advanced-balances/:accountId?',
        component: AdvancedBalances,
        meta: { title: 'Advanced balances' },
    },
    {
        name: 'payout-wallet',
        path: '/payout-wallet/',
        meta: { title: 'Payout wallet' },
        children: [
            {
                name: 'payout-wallet-balance',
                path: 'balance',
                meta: { title: 'Payout wallet' },
                components: {
                    balance: PayoutWalletBalance,
                },
            },
        ],

        component: PayoutWallet,
    },
    {
        name: 'invoices',
        path: '/invoices/',
        component: Invoices,
        meta: { title: 'Invoices' },
    },
    {
        name: 'invoice',
        path: '/invoices/:id',
        component: InvoicePage,
        meta: { title: 'Invoice' },
    },
    {
        name: 'addressBook',
        path: '/addresses-book/',
        component: addressBook,
        meta: { title: 'Address book' },
    },
    // // Temporary decision for nav links
    // {
    //     name: 'changes',
    //     path: '/swap/',
    //     component: change,
    //     meta: { title: 'Changes' },
    // },
    {
        name: 'bridge',
        path: '/bridge/',
        component: crossChain,
        meta: { title: 'Bridge' },
        children: [
            {
                name: 'bridgeHistory',
                path: 'history',
                components: {
                    history: crossChainHistory,
                },
            },
        ],
    },
    {
        name: 'swap',
        path: '/swap/',
        component: swap,
        meta: { title: 'Swap' },
        children: [
            {
                name: 'swapHistory',
                path: 'history',
                components: {
                    history: swapHistory,
                },
            },
        ],
    },
    {
        name: 'subscriptions',
        path: '/subscriptions',
        component: subscriptions,
        meta: { title: 'Subscription' },
    },
    {
        name: 'recurrentsAddress',
        path: '/recurrents-address',
        component: subscriptionsAddress,
        meta: { title: 'Recurrent addresses' },
    },
    {
        name: 'organizations',
        path: '/organizations/',
        component: organizations,
        meta: { title: 'Organizations' },
    },
    {
        name: 'referral',
        path: '/referral/',
        component: referral,
        meta: { title: 'Referral' },
    },
    {
        name: 'bufferBalanceHistory',
        path: '/referral/buffer-balance-history/',
        component: BufferBalanceHistory,
        meta: { title: 'Buffer Balance History' },
    },
    {
        name: 'remunerationBalanceHistory',
        path: '/referral/remuneration-balance-history/',
        component: RemunerationBalanceHistory,
        meta: { title: 'Remuneration Balance History' },
    },
    {
        name: 'businessWallet',
        path: '/business-wallet/',
        component: businessWallet,
        meta: { title: 'Business wallet' },
    },
    {
        name: 'buyCrypto',
        path: '/buy-crypto/',
        component: BuyCrypto,
        meta: { title: 'Buy crypto' },
    },
    {
        path: '/history-addresses/',
        name: 'historyAddresses',
        component: historyAddresses,
        meta: { title: 'Addresses History' },
        children: [
            {
                name: 'pay_in',
                path: 'pay-in',
                components: {
                    historyPayIn: historyPayIn,
                },
            },
            {
                name: 'historyAddressesBusiness',
                path: 'business',
                components: {
                    historyBusiness: historyBusiness,
                },
            },
        ],
    },
    {
        name: 'historyAddress',
        path: '/history-addresses/:id',
        component: historyAddress,
    },
    {
        name: 'currentHistoryAddress',
        path: '/history-address/:id',
        component: currentHistoryAddress,
        meta: { title: 'Address History' }
    },
    {
        name: 'merchant-index',
        path: '/merchant/*',
        redirect: {name: 'payments'},
        meta: { title: 'Merchant' }
    },
    {
        name: 'personal-addresses',
        path: '/personal-addresses/',
        components: {
            default: PersonalAddressesPage,
            PersonalAddressesPage,
        },
        meta: { title: 'Personal Addresses' },
    },
    {
        name: 'analytics',
        path: '/analytics/:wallet?',
        component: Analytics,
        meta: { title: 'Analytics' },
    },
    {
        name: 'orphan',
        path: '/orphan-transactions',
        component: OrphanTransactions,
        meta: { title: 'Orphan Transactions' },
    },
    {
        name: 'orphanInfo',
        path: '/orphan-info/:id',
        component: OrphanInfo,
        meta: { title: 'Orphan Info' },
    },
    {
        name: 'collectingProfits',
        path: '/collecting-profits',
        component: CollectingProfits,
    },
    {
        name: 'collectingHistory',
        path: '/collecting-history/:id?',
        component: CollectingHistory,
    },
    {
        name: 'collectingHistory',
        path: '/collecting-history/:id?',
        component: CollectingHistory,
    },
    {
        name: 'authorizationHistory',
        path: '/authorization-history/:id?/:email?',
        component: AuthorizationHistory,
    },
    {
        name: 'webhooks',
        path: '/webhooks',
        component: webhooks,
        meta: { title: 'webhooks' },
    },
    {
        name: 'sepa-swift',
        path: '/sepa-swift',
        component: sepaSwift,
        meta: { title: 'Sepa/Swift' },
    },
    {
        name: 'bank-invoices',
        path: '/bank-accounts',
        component: bankInvoices,
        meta: { title: 'Invoices' },
    },
    {
        name: 'webhook-info',
        path: '/webhooks/:id',
        component: webhookInfo,
        meta: { title: 'Webhooks' },
    },
    {
        name: 'integrations',
        path: '/integrations',
        component: integrations,
        meta: { title: 'Integrations' },
    },
    {
        name: 'payments',
        path: '/payments',
        component: payments,
        meta: { title: 'Payments' },
    },
    {
        name: 'integration-settings',
        path: '/integration-settings',
        component: IntegrationSettings,
        meta: { title: 'Integration Settings' },
    },

    {
        name: 'widgets',
        path: '/widgets',
        component: widgets,
        meta: { title: 'Widgets' },
    },
    {
        name: 'widgetsManage',
        path: '/widgets/manage',
        component: widgetsManage,
        meta: { title: 'Widgets manage' },
    },

    {
        path: '*',
        redirect: {name: 'dashboard'}, // redirect to dashboard if route not found
    },
];

