<template>
  <div class="transactions-top-bar">
    <div class="transactions-top-bar__button-container">
        <downloader
          v-model="selectedDownloadType"
          class="file-downloader"
          type="secondary"
        >
            <template #icon>
              <svg
                id="btn-select-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                  fill="#744DB2"
                />
                <path
                  d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                  fill="#744DB2"
                />
              </svg>
            </template>
        </downloader>
      <refresh-button @click="updateTransactions(true)" />
        <columns-filter class="columns-filter"/>
        <filter-mobile class="filters--mobile" :is-filtered="!!isFiltered" @reset="resetFilters">
            <orphan-search
              class="transactions-top-bar__filters-search"
              :placeholder="statusSelectPlaceholder"
              v-model="search"
            />
            <transactions-types-select
              v-model="selectedType"
              :placeholder="$t('transactions.selects.type')"
              :list="types"
              clearable
              uniq-key="value"
              @input="setStatus"
            />
            <new-datepicker
              v-model="dates"
              :placeholder="$t('transactions.selects.period')"
              clearable
              @input="handleSelectDates"
            />
            <business-wallet-network-select
              v-model="currencyModel"
              :list="sortCurrencies"
              :placeholder="$t('transactions.selects.currency')"
              clearable
              uniq-key="id"
              @input="updateTransactions(true)"
            />
            <business-wallet-network-select
              v-model="networkModel"
              :list="networks"
              :placeholder="$t('transactions.selects.network')"
              clearable
              uniq-key="id"
              @input="updateTransactions(true)"
            />
        </filter-mobile>
    </div>
    <div class="transactions-top-bar__filters">
        <orphan-search
          class="transactions-top-bar__filters-search"
          :placeholder="statusSelectPlaceholder"
          v-model="search"
        />
        <transactions-types-select
          v-model="selectedType"
          :placeholder="$t('transactions.selects.type')"
          :list="types"
          clearable
          uniq-key="value"
          @input="setStatus"
        />
        <new-datepicker
          v-model="dates"
          :placeholder="$t('webhooks.period')"
          @input="handleSelectDates"
        />
        <business-wallet-network-select
          v-model="currencyModel"
          :list="sortCurrencies"
          :placeholder="$t('transactions.selects.currency')"
          clearable
          uniq-key="id"
          @input="updateTransactions(true)"
        />
        <business-wallet-network-select
          v-model="networkModel"
          :list="networks"
          :placeholder="$t('transactions.selects.network')"
          clearable
          uniq-key="id"
          @input="updateTransactions(true)"
        />
    </div>

  </div>

</template>

<script>

import {mapState, mapActions} from 'vuex';

import AtButton from 'at-ui/src/components/button';
import TransactionsSortByType from "./TransactionsSortByType";
import TransactionsSortByCurrencyFrom from "./TransactionsSortByCurrencyFrom";
import TransactionsSortByCurrencyTo from "./TransactionsSortByCurrencyTo";
import TransactionsFilterByDate from "./TransactionsFilterByDate";
import TransactionsSortByNetwork from "./TransactionsSortByNetwork";
import BtnRefresh from "../../common/btn-refresh";
import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
import Downloader from "@/public/components/elements/personal-addresses/Select/Downloader/index.vue";
import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
import InvoiceStatusSelect from "@/public/components/elements/invoice/Select/invoiceStatusSelect.vue";
import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
import NewDatepicker from "@/public/components/common/uiKit/Datepicker/Datepicker.vue";
import ColumnsFilter from "@/public/components/elements/transactions/column-filter/index.vue";
import FilterMobile from "@/public/components/elements/accounts/History/FilterToggler/index.vue";
import OrdersStatusSelect from "@/public/components/elements/orders/Select/OrdersStatusSelect.vue";
import {TRANSACTION_HISTORY_TYPE_ENUM} from "@/common/constants/transaction-history-type-enum";
import TransactionsTypesSelect from "@/public/components/elements/transactions/Select/TransctionsTypesSelect.vue";
import _ from "lodash";

export default {
    name: 'TransactionsTopBar',
    components: {
        TransactionsTypesSelect,
        OrdersStatusSelect,
        FilterMobile,
        ColumnsFilter,
        NewDatepicker: () => import(/* webpackChunkName: "public/components/elements/analytics/selects/DatePicker" */ '/public/components/elements/analytics/selects/DatePicker'),
        BusinessWalletNetworkSelect,
        InvoiceStatusSelect,
        OrphanSearch,
        Downloader,
        RefreshButton,
        BtnRefresh,
        AtButton,
        TransactionsSortByType,
        TransactionsSortByCurrencyFrom,
        TransactionsSortByCurrencyTo,
        TransactionsFilterByDate,
        TransactionsSortByNetwork,
    },
    data() {
        return {
            isLoading: false,
            selectedDownloadType: undefined,
            search: '',
            selectedType: null,
            currencyModel: null,
            networkModel: null,
            dates: []
        };
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        sortValue: {
            type: String,
            default: 'DESC'
        }
    },
    computed: {
        ...mapState('transactions', [
            'transactionsCountOnPage',
        ]),
        ...mapState('app', ['networks', 'currencies']),
        isFiltered ({ selectedType,  search, currencyModel, networkModel, dates }) {
            return selectedType || search || currencyModel || networkModel || dates.some(d => !!d)
        },
        sortCurrencies() {
            return this.currencies.filter(el => !el.isFiat);
        },
        refreshIcon() {
            return this.isLoading ? '' : 'icon-rotate-cw';
        },
        statusSelectPlaceholder () {
            return `${this.$t('transaction.search')}`
        },
        types () {
            return [
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.ORDER_TRANSACTION,
                    title: 'transactions.order_transaction',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT,
                    title: 'transactions.deposit',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.ADVANCED_BALANCE_REFUND,
                    title: 'transactions.advanced_balance_refund',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_WALLET,
                    title: 'transactions.deposit_wallet',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_RECURRENT,
                    title: 'transactions.deposit_recurrent',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_PERSONAL,
                    title: 'transactions.deposit_personal',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_PAYOUT_BALANCE,
                    title: 'transactions.deposit_payout_balance',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.TRANSFER,
                    title: 'transactions.transfer',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.WITHDRAWAL,
                    title: 'transactions.withdrawal',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_EXECUTE_ORDER,
                    title: 'transactions.commission_execute_order',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_WALLET_DEPOSIT,
                    title: 'transactions.commission_wallet_deposit',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_RECURRENT_DEPOSIT,
                    title: 'transactions.commission_recurrent_deposit',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PERSONAL_DEPOSIT,
                    title: 'transactions.commission_personal_deposit',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PAYOUT_DEPOSIT,
                    title: 'transactions.commission_payout_deposit',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_TRANSFER,
                    title: 'transactions.commission-transfer',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_WALLET_WITHDRAWAL,
                    title: 'transactions.commission_wallet_withdrawal',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_RECURRENT_WITHDRAWAL,
                    title: 'transactions.commission_recurrent_withdrawal',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PERSONAL_WITHDRAWAL,
                    title: 'transactions.commission_personal_withdrawal',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PAYOUT_WITHDRAWAL,
                    title: 'transactions.commission_payout_withdrawal',
                },
              {
                value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_COLLECT,
                title: 'transactions.deposit_collect',
              },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_INTERNAL,
                    title: 'transactions.bridge_internal',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_EXTERNAL,
                    title: 'transactions.bridge_external',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_INTERNAL_FEE,
                    title: 'transactions.bridge_internal_fee',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_EXTERNAL_FEE,
                    title: 'transactions.bridge_external_fee',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.KYT_TRANSACTION_FEE,
                    title: 'advance_balances.kyt-transaction-fee',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.KYT_WITHDRAWAL_ADDRESS_FEE,
                    title: 'advance_balances.kyt-withdrawal-address-fee',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_AUTO,
                    title: 'advance_balances.exchange-auto',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_AUTO_FEE,
                    title: 'advance_balances.exchange-auto-fee',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_INTERNAL,
                    title: 'transactions.exchange_internal',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_INTERNAL_FEE,
                    title: 'transactions.exchange_internal_fee',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.ORPHAN_DEPOSIT_WITHDRAWAL_FEE,
                    title: 'advance_balances.orphan-deposit-withdrawal-fee',
                },
                {
                    value: TRANSACTION_HISTORY_TYPE_ENUM.ORPHAN_DEPOSIT_WITHDRAWAL,
                    title: 'advance_balances.orphan-deposit-withdrawal',
                },
                {
                  value: TRANSACTION_HISTORY_TYPE_ENUM.GAS_DEPOSIT,
                  title: 'advance_balances.gas-deposit',
                },
                {
                  value: TRANSACTION_HISTORY_TYPE_ENUM.KYT_ADDRESS_FEE,
                  title: 'advance_balances.kyt_address_fee',
                },
                {
                  value: TRANSACTION_HISTORY_TYPE_ENUM.PAYOUT_AUTO_SWAP,
                  title: 'advance_balances.payout_auto_swap',
                },
                {
                  value: TRANSACTION_HISTORY_TYPE_ENUM.PAYOUT_AUTO_SWAP_FEE,
                  title: 'advance_balances.payout_auto_swap_fee',
                }
            ]
        },
    },
    methods: {
        ...mapActions('transactions', [
            'fetchTransactions',
            'downloadCSV',
            'downloadXLSX',
        ]),
        setStatus (value) {
            this.selectedStatus = value
            this.updateTransactions(true)
        },
        updateTransactions(changePage = true) {
            const {
                $route,
                $router,
                fetchTransactions,
                page,
                search
            } = this;

            this.isLoading = true;
            if (changePage) {
                this.$emit('changePage', 1)
            }
            fetchTransactions({
                route: $route,
                router: $router,
                currencyId: this.currencyModel?.id ? [this.currencyModel?.id] : null,
                networks: this.networkModel?.id ? [this.networkModel?.id] : null,
                page,
	              anyId: search,
                date: this.dates,
                type: this.selectedType?.value ? [this.selectedType?.value] : null,
                sortDate: this.sortValue ? this.sortValue : 'DESC'
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleSelectDates(value) {
            this.dates = value;
            this.updateTransactions()
            // this.loadWebhooks()
        },
        resetFilters() {
          const filters = ['networkModel', 'currencyModel', 'selectedType', 'search']

          filters.forEach(filter => {
            this.$set(this, filter, '')
          })

          this.dates = []

          this.updateTransactions()
        }
    },
    watch: {
        isFiltered: {
          handler(v) {
            this.$nextTick(() => {
              this.$emit('is-empty-filters', !v)
            })
          },
          immediate: true
        },
        page() {
            this.updateTransactions(false)
        },
        sortValue () {
            this.updateTransactions(true)
        },
        search () {
            this.updateTransactions(true)
        },
        selectedDownloadType(v) {
            const {
                $route,
                invoiceCurrency,
                paymentCurrency,
                paymentNetwork,
                search,
                dates,
                status,
                sortValue,
                currencyModel,
                networkModel,
            } = this;
            if (!v) {return}
            if (v.id === 'csv') {
                this.downloadCSV({
                  route: $route,
                  id: search || null,
                  status,
                  type: this.selectedType?.value,
                  currencies: currencyModel?.id,
                  networks: networkModel?.id,
                  date: dates ? dates : null,
                  sortDate: this.sortValue,
                });
            } else {
                this.downloadXLSX({
                  route: $route,
                  id: search || null,
                  status,
                  currencies: currencyModel?.id,
                  type: this.selectedType?.value,
                  networks: networkModel?.id,
                  date: dates ? dates : null,
                  sortDate: this.sortValue,
                });
            }
            this.selectedDownloadType = undefined
        },
    }
};

</script>

<style lang="scss" scoped>
.transactions-top-bar {
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;

  &__filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    &-search {
      grid-row-start: 1;
      grid-column-start: 1;

      grid-row-end: 2;
      grid-column-end: 3;
    }
  }

  &__button-container {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  &__left-side {
    width: 100%;
    display: flex;
    gap: 30px;
  }

  &__filter-item {
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.button {
  height: 46px;
  width: 60px;
  background: #F9F9F9;
  border-radius: 12px;
  border: none;
}


.button ::v-deep .at-btn__icon {
  color: #0059F7;
  font-size: 18px;
  line-height: 18px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  gap: 30px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
::v-deep {
  .search-input::placeholder {
    opacity: 1;
  }
  .advanced-balances-select-wrapper .sublabel {
    padding: 0;
  }
  .file-downloader {
    width: 48px;
  }
  .filter-toggler__content {
    gap: 10px;
    width: 300px;
  }
  .advanced-balances-select-wrapper {
    width: 100%;
  }
}
.filters--mobile {
  display: none;
}
@media screen and (max-width: 1330px) {

  .transactions-top-bar {
    padding-left: 15px;
    padding-right: 15px;

    &__left-side {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
    }
  }

}
@media screen and (max-width: 950px) {
  .transactions-top-bar {
    &__left-side {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      padding-top: 20px;
    }

  }
  .columns-filter {
    display: none;
  }
  .transactions-top-bar__filters {
    display: none;
  }
  .filters--mobile {
    display: flex;
    &::v-deep {
      .input {
        font-size: $h6!important;
        padding: 0 10px!important;
      }
      .orphan-search-wrapper {
        width: 100%;

        .search-input::placeholder {
          font-size: $h6!important;
          opacity: 1 !important;
        }

        .search-input {
          padding: 0 40px 0 10px;
        }
      }
      .mx-input::placeholder {
        font-size: 13px;
        opacity: 1 !important;
      }
      .mx-input-wrapper {
        input {
          padding-left: 10px !important;
        }
      }
      .accounts-filter-by-date {
        width: 100%;
      }
    }
  }
}
::v-deep .icon  {
  .currency-network-icon__network {
    width: 22px !important;
    height: 22px !important;

    border: none !important;
    position: unset !important;

    top: unset !important;
    left: unset !important;

    transform: unset !important;
  }
}
</style>
