import setUserInterfaceSettingsQuery from "/public/graphQl/mutations/setUserInterfaceSettings";

export const sendColumns = async ({dispatch, commit, rootGetters}, [name, entities]) => {
    let settingsData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        name,
        entities: JSON.stringify(entities)
    }

    try {
        settingsData = await dispatch('app/request', {
            type: 'mutation',
            gql: setUserInterfaceSettingsQuery,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
    }

    if (settingsData.message) {
        throw settingsData.message;
    }

    if (settingsData.errors) {
        throw settingsData.errors;
    }

    const {
        data: {setUserInterfaceSettings},
    } = settingsData;
    const menu = setUserInterfaceSettings.result.entities ? setUserInterfaceSettings.result.entities : []
    if (!menu.length) {
        commit('setColumns', menu)
    } else {
        commit('setColumns', JSON.parse(menu))
    }
    return setUserInterfaceSettings;
};
