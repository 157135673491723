<template>
  <base-select
      v-bind="$attrs"
      v-model="valueModel"
      :label="label"
      :placeholder="placeholder"
      :list="selectedList"
      :with-search="false"
      class="advanced_balances__network-select"
      @closed="search = undefined"
  >
      <template #selected="data">
        <net-curr-item
          v-bind="data"
          :showCurrencyName="showCurrencyName"
        />
      </template>
      <template v-if="withSearch" #search>
        <search
          id="search-input"
          class="search"
          v-model="search"
        />
      </template>
      <template #info="data">
        <net-curr-item
          v-bind="data"
          height="22px"
          :showCurrencyName="showCurrencyName"
        />
      </template>
  </base-select>
</template>

<script>
    import { mapState } from "vuex";
    import placeholder from "lodash/fp/placeholder";

    export default {
        name: "BusinessWalletNetworkSelect",
        components: {
            Search: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Search" */ './Search'),
            BaseSelect: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/bh-select" */ '/public/components/elements/advance-balances/bh-select'),
            NetCurrItem: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Item" */ './Item'),
        },
        data() {
            return {
                search: undefined
            }
        },
        props: {
            value: {
                type: [ String, Object ],
                default: () => {}
            },
            invoiceAmount: {
                type: String,
                default: ''
            },
            invoiceCurrency: {
                type: String,
                default: ''
            },
            list: {
                type: Array,
                default: () => ([])
            },
            withSearch: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            showCurrencyName: {
              type: Boolean,
              default: true
            }
        },
        computed: {
            ...mapState({
                currentCurrencyInfo: state => state.currency,
            }),
            valueModel: {
                get({ value }) {
                    return value
                },
                set(v) {
                    this.$emit('input', v)
                }
            },
            selectedList () {
              return this.withSearch ? this.filteredList : this.list
            },
            filteredList({ list, search }) {
                //метод фильтрации общего списка валют
                if (search) {
                    return list.filter((netCurr = {}) => {
                        const currencyName = netCurr['currency'] ? netCurr['currency'].toLowerCase() : ''
                        const currencyAlias = netCurr['alias'] ? netCurr['alias'].toLowerCase() : ''
                        const networkName = ( netCurr['networks'] && netCurr['networks']['name'] ) ? netCurr['networks']['name'].toLowerCase() : ''
                        const networkAlias = ( netCurr['networks'] && netCurr['networks']['alias'] ) ? netCurr['networks']['alias'].toLowerCase() : ''

                        return networkName.includes(search.toLowerCase()) || networkAlias.includes(search.toLowerCase()) || currencyName.includes(search.toLowerCase()) || currencyAlias.includes(search.toLowerCase())
                    })
                } else {
                    return list
                }
            }
        },
    }
</script>

<style lang="scss">
.advanced_balances {
  &__network-select {
    margin: 20px 0;

    .search {
      margin-bottom: 15px;
    }
  }
}
.advanced_balances__network-select {
    ::v-deep  .label {
        color: var(--new-front-input-label-font-color)!important;
    }
}
</style>
