import getTransactionsFiltersQuery from "/public/graphQl/queries/getTransactionOperationFilters";

export const fetchTransactionsFilters = async ({ state, rootState, rootGetters, commit, dispatch }) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id

    const variables = {
        organizationId
    }

    let transactionsFilters

    try {
        commit('app/toggleLoading', null, {root: true});
        transactionsFilters = await dispatch('app/request', {
            type: 'query',
            gql: getTransactionsFiltersQuery,
            variables,
        }, {root: true});
    } catch (error) {
        console.error(error);
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    console.log('transactionsFilters ----> ', transactionsFilters)

    if (!transactionsFilters.message && !transactionsFilters.errors) {
        const {
            data: {
                getTransactionOperationFilters: {
                    trxType,
                    status,
                    addressType,
                    addressRisk,
                    currencyType
                }
            },
        } = transactionsFilters

        commit('setTransactionTypes', trxType);
    }

    return true
}
