<template>
  <div class="info">
    <div class="info__bg" @click="closeInfo"></div>
    <div  class="info__content">
      <div class="info__close" @click="closeInfo">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.712087 0.712087C1.0782 0.345971 1.6718 0.345971 2.03791 0.712087L7 5.67418L11.9621 0.712087C12.3282 0.345971 12.9218 0.345971 13.2879 0.712087C13.654 1.0782 13.654 1.6718 13.2879 2.03791L8.32582 7L13.2879 11.9621C13.654 12.3282 13.654 12.9218 13.2879 13.2879C12.9218 13.654 12.3282 13.654 11.9621 13.2879L7 8.32582L2.03791 13.2879C1.6718 13.654 1.0782 13.654 0.712087 13.2879C0.345971 12.9218 0.345971 12.3282 0.712087 11.9621L5.67418 7L0.712087 2.03791C0.345971 1.6718 0.345971 1.0782 0.712087 0.712087Z" fill="var(--new-front-primary-font-color)"/>
        </svg>
      </div>
      <div>
        <div class="transaction-info__title">
          {{ $t('transactions_new.transaction_info_drawer.title') }}
        </div>
        <div class="transaction-info__number">
          № {{ transaction.id }}
        </div>
        <transaction-groups :transaction="transaction" :fields-type="fieldsType" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TransactionsInfoNew',
    components: {
      TransactionGroups: () => import(/* webpackChunkName: "public/components/elements/transactions/TransactionInfoNew/Groups.vue" */ '/public/components/elements/transactions/TransactionInfoNew/Groups.vue'),
    },
    title: 'transactions.title',
    props: {
      transaction: {
        type: Object,
        default: () => ({})
      },
      fieldsType: {
        type: Object,
        default: () => ({}),
      }
    },
    methods: {
      closeInfo() {
        this.$emit('close')
      }
    }
  }
</script>

<style scoped lang="scss">
.info {
  font-family: $Gilroy;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // Огромный z-index для перекрытия виджета интеркома
  z-index: 2147483001;

  &__close {
    position: absolute;
    top: 30px;
    cursor: pointer;
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .50);
    height: 100vh;
    width: 100vw;
    z-index: 1;
    cursor: pointer;
  }
  &__content {
    width: 530px;
    height: 100vh;
    box-sizing: border-box;
    padding: 100px 50px 30px 50px;
    background: var(--new-front-main-bg);
    position: absolute;
    right: 0;
    z-index: 2;
    overflow: auto;

    .transaction-info {
      font-family: $Gilroy;

      &__title {
        font-size: $h1;
        color: var(--text-text-primary);
      }
      &__number {
        font-size: $h4;
        font-weight: 500;
        color: var(--text-color-text-secondary);
      }
    }
  }
}

//.slide-fade-enter-active {
//  transition: all .3s ease;
//}
//.slide-fade-leave-active {
//  transition: all .3s ease;
//}
//.slide-fade-enter, .slide-fade-leave-to
//  /* .slide-fade-leave-active до версии 2.1.8 */ {
//  transform: translateX(10px);
//  opacity: 0;
//}
@media screen and (max-width: 576px) {
  .info__content {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 70px 20px 30px;
    overflow: auto;
  }
}
</style>
