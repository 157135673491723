export const state = {
    transactionsCountOnPage: 10,
    transactions: [],
    totalTransactionsCount: null,
    transactionsNew: undefined,
    totalTransactionsCountNew: null,
    isLoading: false,
    transaction: null,
    isInfoOpened: false,
    columns: [],
    types: [],
    defaultColumns: [
        { title: 'date_of_creation', active: true },
        { title: 'type', active: true },
        { title: 'basis', active: true },
        { title: 'source', active: true },
        { title: 'method', active: true },
        { title: 'CurrencyNetwork', active: true },
        { title: 'address_from', active: true },
        { title: 'address_to', active: true },
        { title: 'tx_hash', active: true },
        { title: 'status', active: true },
        { title: 'debitCredit', active: true },
    ],
    defaultColumnsNew: [
        { title: 'id', active: true, group: 'transaction', type: 'uid' },
        { title: 'parentId', active: true, group: 'organization', type: 'uid' },
        { title: 'organizationId', active: true, group: 'organization', type: 'uid' },
        { title: 'txHash', active: true, group: 'transaction', type: 'tx_link' },
        { title: 'operationId', active: true, group: 'organization', type: 'uid' },
        { title: 'organizationAlias', active: true, group: 'organization', type: 'string' },
        { title: 'organizationInternalName', active: true, group: 'organization', type: 'string' },
        { title: 'organizationIsInternal', active: true, group: 'organization', type: 'boolean' },
        { title: 'organizationKyc', active: true, group: 'organization', type: 'boolean' },
        { title: 'organizationAml', active: true, group: 'organization', type: 'boolean' },
        { title: 'organizationOwnerEmail', active: true, group: 'organization', type: 'email' },
        { title: 'organizationOwnerId', active: true, group: 'organization', type: 'uid' },
        { title: 'organizationIsTestnet', active: true, group: 'organization', type: 'boolean' },
        { title: 'userId', active: true, group: 'organization', type: 'uid' },
        { title: 'userEmail', active: true, group: 'organization', type: 'email' },
        { title: 'userRole', active: true, group: 'organization', type: 'string' },
        { title: 'transactionType', active: true, group: 'transaction', type: 'string' },
        { title: 'status', active: true, group: 'transaction', type: 'status' },
        { title: 'apiKeyAlias', active: true, group: 'transaction', type: 'string' },
        { title: 'apiKeyId', active: true, group: 'transaction', type: 'uid' },
        { title: 'apiKeyPublic', active: true, group: 'transaction', type: 'uid' },
        { title: 'startTs', active: true, group: 'transaction', type: 'date' },
        { title: 'finishTs', active: true, group: 'transaction', type: 'date' },
        { title: 'amountTo', active: true, group: 'currencyNetworkTo', type: 'number' },
        { title: 'currencyToType', active: true, group: 'currencyNetworkTo', type: 'string' },
        { title: 'rateFromTo', active: true, group: 'transaction', type: 'number' },
        { title: 'currencyTo', active: true, group: 'currencyNetworkTo', type: 'currency' },
        { title: 'networkTo', active: true, group: 'currencyNetworkTo', type: 'network' },
        { title: 'addressTo', active: true, group: 'currencyNetworkTo', type: 'address' },
        { title: 'addressRiskTo', active: true, group: 'currencyNetworkTo', type: 'risk' },
        { title: 'amountFrom', active: true, group: 'currencyNetworkFrom', type: 'number' },
        { title: 'currencyFromType', active: true, group: 'currencyNetworkFrom', type: 'string' },
        { title: 'rateToFrom', active: true, group: 'transaction', type: 'number' },
        { title: 'currencyFrom', active: true, group: 'currencyNetworkFrom', type: 'currency' },
        { title: 'networkFrom', active: true, group: 'currencyNetworkFrom', type: 'network' },
        { title: 'addressFrom', active: true, group: 'currencyNetworkFrom', type: 'address' },
        { title: 'addressRiskFrom', active: true, group: 'currencyNetworkFrom', type: 'risk' },
        { title: 'networkFeeTo', active: true, group: 'commission', type: 'number' },
        { title: 'networkFeeToSource', active: true, group: 'commission', type: 'number' },
        { title: 'externalNetworkFee', active: true, group: 'commission', type: 'number' },
        { title: 'networkFeeFrom', active: true, group: 'commission', type: 'number' },
        { title: 'networkFeeFromSource', active: true, group: 'commission', type: 'number' },
        { title: 'networkFeeFromAmountInCurrencyFrom', active: true, group: 'commission', type: 'number' },
        { title: 'networkFeeFromUSD', active: true, group: 'commission', type: 'number' },
        { title: 'networkFeeFromEUR', active: true, group: 'commission', type: 'number' },
        { title: 'exchangeName', active: true, group: 'commission', type: 'string' },
        { title: 'exchangeAddressTo', active: true, group: 'commission', type: 'address' },
        { title: 'exchangeTxTo', active: true, group: 'commission', type: 'date' },
        { title: 'exchangeAddressFrom', active: true, group: 'commission', type: 'address' },
        { title: 'exchangeTxFrom', active: true, group: 'commission', type: 'date' },
        { title: 'advanceBalanceStart', active: true, group: 'advanced', type: 'number' },
        { title: 'advanceBalanceFinish', active: true, group: 'advanced', type: 'number' },
        { title: 'advanceBalanceStartTs', active: true, group: 'advanced', type: 'date' },
        { title: 'advanceBalanceFinishTs', active: true, group: 'advanced', type: 'date' },
        { title: 'advanceBalanceFullComissionUSD', active: true, group: 'advanced', type: 'number' },
        { title: 'advanceBalanceFullComissionEUR', active: true, group: 'advanced', type: 'number' },
        { title: 'advanceBalanceNetworkFeeUSD', active: true, group: 'advanced', type: 'number' },
        { title: 'advanceBalanceNetworkFeeEUR', active: true, group: 'advanced', type: 'number' },
        { title: 'advanceBalanceServiceFeeAmountUSD', active: true, group: 'advanced', type: 'number' },
        { title: 'advanceBalanceServiceFeeAmountEUR', active: true, group: 'advanced', type: 'number' },
        { title: 'advanceBalanceServiceFeePercent', active: true, group: 'advanced', type: 'number' }
    ],
}
