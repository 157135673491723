import gql from 'graphql-tag';

export default gql`
    query( $organizationId: String! ) {
        getTransactionOperationFilters( organizationId: $organizationId ) {
            trxType, 
            status, 
            addressType, 
            addressRisk, 
            currencyType
        }
    }
`;