import getUserInterfaceSettingsQuery from "/public/graphQl/queries/getUserInterfaceSettings";

export const getColumns = async ({ dispatch, commit, rootGetters }, columnsPath) => {
    let settingsData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        name: columnsPath
    }

    try {
        settingsData = await dispatch('app/request', {
            type: 'query',
            gql: getUserInterfaceSettingsQuery,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
    }


    if (settingsData.message) {
        throw settingsData.message;
    }

    if (settingsData.errors) {
        throw settingsData.errors;
    }

    const {
        data: { getUserInterfaceSettings },
    } = settingsData;
    const menu = getUserInterfaceSettings.entities ? getUserInterfaceSettings.entities : []
    if (!menu.length) {
        commit('setColumns', menu)
    } else {
        commit('setColumns', JSON.parse(menu))
    }

    return getUserInterfaceSettings;
};
