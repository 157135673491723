<template>
<div class="header">
  <div class="header__item" v-for="(item, idx) in localList" @click="emitSort(item, idx)">
      <span class="header__item-title">{{ item.title }}</span>
      <div class="sort-icon" v-if="item.isSort">
          <svg
              v-if="item.sortDirection"
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="17"
              viewBox="0 0 23 17"
              fill="none"
          >
              <path d="M5.25 16.25C5.25 16.6642 4.91421 17 4.5 17C4.08579 17 3.75 16.6642 3.75 16.25L3.75 3.06066L2.03033 4.78033C1.73744 5.07322 1.26256 5.07322 0.96967 4.78033C0.676777 4.48744 0.676777 4.01256 0.96967 3.71967L3.96843 0.720914L3.97955 0.709971C4.04913 0.642865 4.12848 0.591844 4.21291 0.556908C4.30134 0.520239 4.39831 0.5 4.5 0.5C4.60169 0.5 4.69866 0.520239 4.78709 0.556909C4.87555 0.593509 4.95842 0.647763 5.03033 0.719671L8.03033 3.71967C8.32322 4.01256 8.32322 4.48744 8.03033 4.78033C7.73744 5.07322 7.26256 5.07322 6.96967 4.78033L5.25 3.06066L5.25 16.25Z" fill="var(--new-front-secondary-font-color)"/>
              <path d="M11.25 2C10.8358 2 10.5 1.66421 10.5 1.25C10.5 0.835786 10.8358 0.5 11.25 0.5H12.75C13.1642 0.5 13.5 0.835786 13.5 1.25C13.5 1.66421 13.1642 2 12.75 2H11.25Z" fill="var(--new-front-secondary-font-color)"/>
              <path d="M11.25 6.5C10.8358 6.5 10.5 6.16421 10.5 5.75C10.5 5.33579 10.8358 5 11.25 5H15.75C16.1642 5 16.5 5.33579 16.5 5.75C16.5 6.16421 16.1642 6.5 15.75 6.5H11.25Z" fill="var(--new-front-secondary-font-color)"/>
              <path d="M11.25 11C10.8358 11 10.5 10.6642 10.5 10.25C10.5 9.83579 10.8358 9.5 11.25 9.5H18.75C19.1642 9.5 19.5 9.83579 19.5 10.25C19.5 10.6642 19.1642 11 18.75 11H11.25Z" fill="var(--new-front-secondary-font-color)"/>
              <path d="M10.5 14.75C10.5 15.1642 10.8358 15.5 11.25 15.5H21.75C22.1642 15.5 22.5 15.1642 22.5 14.75C22.5 14.3358 22.1642 14 21.75 14H11.25C10.8358 14 10.5 14.3358 10.5 14.75Z" fill="var(--new-front-secondary-font-color)"/>
          </svg>
          <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
          >
              <path
                  d="M3.5 2.5C3.5 2.22386 3.27614 2 3 2C2.72386 2 2.5 2.22386 2.5 2.5L2.5 11.2929L1.35355 10.1464C1.15829 9.95118 0.841709 9.95118 0.646447 10.1464C0.451184 10.3417 0.451184 10.6583 0.646447 10.8536L2.6456 12.8527L2.65303 12.86C2.69942 12.9048 2.75232 12.9388 2.80861 12.9621C2.86756 12.9865 2.9322 13 3 13C3.06779 13 3.13244 12.9865 3.19139 12.9621C3.25036 12.9377 3.30561 12.9015 3.35355 12.8536L5.35355 10.8536C5.54882 10.6583 5.54882 10.3417 5.35355 10.1464C5.15829 9.95118 4.84171 9.95118 4.64645 10.1464L3.5 11.2929L3.5 2.5Z"
                  fill="var(--new-front-secondary-font-color)"
              />
              <path d="M7 3.5C7 3.22386 7.22386 3 7.5 3H14.5C14.7761 3 15 3.22386 15 3.5C15 3.77614 14.7761 4 14.5 4H7.5C7.22386 4 7 3.77614 7 3.5Z" fill="var(--new-front-secondary-font-color)"/>
              <path d="M7.5 6C7.22386 6 7 6.22386 7 6.5C7 6.77614 7.22386 7 7.5 7H12.5C12.7761 7 13 6.77614 13 6.5C13 6.22386 12.7761 6 12.5 6H7.5Z" fill="var(--new-front-secondary-font-color)"/>
              <path d="M7.5 9C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10H10.5C10.7761 10 11 9.77614 11 9.5C11 9.22386 10.7761 9 10.5 9H7.5Z" fill="var(--new-front-secondary-font-color)"/>
              <path d="M7.5 12C7.22386 12 7 12.2239 7 12.5C7 12.7761 7.22386 13 7.5 13H8.5C8.77614 13 9 12.7761 9 12.5C9 12.2239 8.77614 12 8.5 12H7.5Z" fill="var(--new-front-secondary-font-color)"/>
          </svg>
      </div>
  </div>
</div>
</template>
<script>
    // List Example
    // tableHeader: [
    //     {
    //         title: 'Date',
    //         slug: 'date',
    //         isSort: true
    //     }
    // ]
    export default {
        name: "TableHeader",
        props: {
            list: {
                type: Array,
                default: () => ([])
            }
        },
        data () {
            return {
                localList: []
            }
        },
        watch: {
            list: {
                immediate: true,
                deep: true,
                handler () {
                    this.localList = this.list.map(item => ({
                        ...item,
                        sortDirection: false
                    }))
                }
            }
        },
        methods: {
            emitSort ({slug, isSort, sortDirection}, idx) {
                if (!isSort) return
                this.$emit('sort', slug, !sortDirection)
                this.localList[idx].sortDirection = !sortDirection
                this.localList.forEach(item => {
                    if (item.slug !== slug) {
                        item.sortDirection = false
                    }
                })
            },
        },
        // computed: {
        //     localList () {
        //         return
        //     }
        // }
    }
</script>
<style scoped lang="scss">
.sort-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    cursor: pointer;
}
.header {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(105px,1fr));
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
  padding: 0 $p30;
  background: var(--new-front-select-item-bg-active);
  border-radius: 10px;
  font-family: $Gilroy;
  &__item {
    display: flex;
    align-items: center;
    gap: 5px;

    min-height: 56px;
    max-height: 56px;

    &-title {
      font-size: $h4;
      color: var(--new-front-secondary-font-color);;
      font-weight: 500;
    }
    &-sort {
      cursor: pointer;
      //transition: transform .2s ease;
      &--active {
        transform: rotate(180deg);
      }
    }
  }
}
@media (max-width: 1410px) {
  .header__item-title {
    font-size: $h5;
  }
}
</style>
