import { InfoModals } from "./InfoModals";

export const ru = {
    'exit': 'Выход',
    'copy': {
        'id_copied': 'id скопировано',
        'id_not_copied': 'id не скопировано',
        'notification': 'Уведомление',
        'token_copied': 'Токен скопирован',
        'addresses_copied': 'Адрес скопирован',
        'addresses_not_copied': 'Адрес не скопирован',
        'the_name_of_the_key_copied': 'Название ключа скопировано',
        'the_name_of_the_key_not_copied': 'Название ключа не скопировано',
        'the_public_key_copied': 'Публичный ключ скопирован',
        'the_public_key_not_copied': 'Публичный ключ не скопирован',
        'the_private_key_copied': 'Приватный ключ скопирован',
        'the_private_key_not_copied': 'Приватный ключ не скопирован',
        'api_key_successfully_updated': 'Api ключ успешно обновлен',
        'failed_to_update_api_key': 'Не удалось обновить api ключ',
        'order_title': 'Название ордера скопировано',
        'order_title_failed': 'Название ордера не скопировано',
        'transaction_hash': 'Хеш транзакции скопирован',
        'transaction_hash_failed': 'Хеш транзакции не скопирован',
        'link_copied': 'Ссылка скопирована',
        'edit': 'Редактировать',
        'deleteKey': 'Удалить',
    },
    'navigation': {
        'main': 'Главная',
        'pinned': 'Закреплено',
        'advance_balances': 'Авансовые балансы',
        'addresses': 'Кошельки',
        'addresses_sub': 'Pay-in',
        'change': 'Обмены',
        'subscriptions': 'Рекурренты',
        'payment-bundle': 'Платежные связки',
        'payment-address': 'Платежные адреса',
        'organizations': 'Организации',
        'payments': 'Платежи',
        'orders': 'Ордера',
        'transactions': 'Транзакции',
        'withdrawal': 'Вывод',
        'info': 'Информация',
        'api_keys': 'Ключи Api',
        'api_docs': 'Документация Api',
        'donations': 'Донаты',
        'payout_wallet': 'Выплаты',
        'invoice': 'Счета',
        'bridge': 'Мост',
        'bank': 'Банк',
        'widgets': 'Виджеты',
        'widgetsManage': 'Управление виджетом',
        'sepa-swift': 'SEPA/SWIFT',
        'cross-chain': 'Мост',
        'cross-chain-history': 'История мостов',
        'swap': 'Обмен',
        'swap-history': 'История обменов',
        'business-wallet': 'Бизнес-кошелек',
        'wallet': 'Кошелек',
        'referral': 'Реферальная программа',
        'support': 'Поддержка',
        'address-book': 'Адресная книга',
        'integrations': 'Интеграции',
        'orphan': 'Сиротские транзакции',
        'personal-addresses': 'Персональные адреса',
        'webhooks': 'Вебхуки',
        'new': 'НОВОЕ',
        'accounts': 'Счета',
        'integration-settings': 'Настройки интеграции'
    },
    'pages-description': {
      'pay-in-description': 'Кошелек для приема оплаты по ордерам. Доступно сделать выводы прибыли и внутренние транзакции для пополнения авансового баланса.',
       'business-wallet-description': 'Адреса для приема свободных депозитов без ордеров и счетов.',
        'address-book-description': 'Удобное хранение внешних адресов для быстрого вывода.',
        'crosschain-description': 'Блокчейн мост для токенов на ваших адресах между сетями',
        'transactions-description': 'Все ваши транзакции внутри сервиса с полной информацией по каждому движению средств.',
        'invoices-description': 'Создание счета в удобной валюте и отслеживание оплат счетов.',
        'orders-description': 'Создание ордеров и отслеживание оплат ордеров.'
    },
    'settings-modal': {
        'title': 'Бизнес аккаунт',
        'dark-theme': 'Темная тема',
        'settings': 'Настройки',
        'aml-policy': 'AML политика',
        'business-settings': 'Бизнес настройки',
        'logout': 'Выйти',
        'language': 'Язык',
        'collect': 'Сбор прибыли'
    },
    'authorization': {
      'login-title': 'Авторизация',
        'registration-title': 'Регистрация',
        'restore-password-title': 'Восстановление пароля',
        'execute': 'Подтвердить',
        'verif-title': 'Двухфакторная аутентификация (2FA)',
        'verification-email': 'Подтверждение почты',
        'password': 'Пароль',
        'repeat-password': 'Повторите пароль',
        'email-confirm': 'Подтверждение e-mail',
        'email-description': 'Ссылка для подтверждения регистрации отправлена на почту. Письмо придет с адреса {email}',
        'dont-have-acc': 'Вы еще не зарегистрировались?',
        'have-acc': 'Уже есть аккаунт?',
        'ref-code': 'Реферальный код',
        'username': 'Имя пользователя',
        'email': 'E-mail',
        'incorrect-data': 'Неправильный email или пароль',
        'invalid-email': 'Неверный email',
        'invalid-passwords': 'Неверный пароль',
        'invalid-password-not-match': 'Пароли не совпадают',
        'user-existing': 'Пользователь уже существует',
        'send': 'Отправить',
        'logout': 'Выйти',
        'enter-code': 'Ввести код',
        'verification-description': 'Введите одноразовый код из приложения аутентификатора.',
        'code-not-entered': 'Код не введен',
        'code-not-valid': 'Код не верен',
        'code': 'Код',
        'invalid-password': 'Неверный пароль',
        'invalid-repassword': 'Пароли не совпадают',
        'invalid-link': 'Неверная ссылка',
        'minimum-characters': 'Минимум 6 символов',
        'uppercase-restriction': 'Одна заглавная и строчная буква a...Z',
        'spec-char': 'Хотя бы один специальный символ !@#%^&*}{[]',
        'number-char': 'Хотя бы одно число 0...9',
        'return-to-login': 'Вернуться на страницу входа',
        'forgot': 'Забыли пароль?',
        'restore-title': 'Сброс пароля',
        'restore-desc': 'Вы уверены что хотите сбросить текущий пароль?',
        'restore-subDesc': 'Для восстановления пароля нужно будет связаться со службой поддержки.',
        'close': 'Закрыть',
    },
    'dashboard': {
        'main': 'Главная',
        'balance': 'Баланс',
        'balances': 'Все балансы',
        'fold': 'Свернуть',
        'orders': 'Ордера',
        'there_is_nothing': 'Ничего нет',
        'period': 'Период',
        'successful_transactions': 'Успешные транзакции',
        'type': 'Тип',
        'quantity': 'Количество',
        'amount': 'Сумма',
        'currency': 'Валюта',
        'deposits': 'Пополнение',
        'withdrawals': 'Выводы',
        'orders_decl': 'Ордеров',
        'created_decl': 'Созданных',
        'executed_decl': 'Выполненных',
        'created': 'Созданые',
        'completed': 'Выполненные',
        'incoming_transactions': 'Входящие транзакции',
        'outgoing_transactions': 'Исходящие транзакции',
        'completed_withdrawal_requests': 'Выполненные заявки на вывод',
        'enable_two-factor_authentication': 'Подключить двухфакторную аутентификацию',
        'enable': 'Подключить',

    },
    'advance_balances': {
        'account_replenished_successfully': "Аккаунт {start} **** {end} был пополнен на {amount} {ticker}",
        'title': 'Авансовые балансы',
        'subtitle': 'Авансовый баланс используется для автоматического списания комиссии за все финансовые операции на сервисе, а также за комиссию сети',
        'description': 'Авансовый баланс используется для автоматического списания комиссии за все финансовые операции на сервисе, а также за комиссию сети.',
        'currency': 'Валюта',
        'currency-and-network-label': 'Валюта и сеть',
        'currency-and-network-placeholder': 'Выберите валюту и сеть',
        'currency-and-network-sublabel': 'Ваши монеты будут переданы по обменному курсу',
        'currency-and-network-search': 'Поиск',
        'balance': 'Баланс',
        'no_data_available': 'Нет данных',
        'deposit': 'Пополнить',
        'network': 'Сеть',
        'select_network': 'Выберите сеть',
        'address_for_replenishment': 'Адрес для пополнения:',
        'time': ' Время до истечения адреса:',
        'cancel': 'Закрыть',
        'get_an_address': 'Перейти к оплате',
        'replenishment': 'Пополнение баланса',
        'replenishment_subtitle': 'Для продолжения необходимо выбрать валюту и сеть',
        'unknown': 'Выбрана неизвестная монета для пополнения',
        'payments': 'Прием платежей в данной монете сейчас невозможен',
        'need_to_select': 'Для продолжения необходимо выбрать сеть',
        'creating_an_address': 'Создаем адрес',
        'balance_history': 'История баланса',
        'history_of_actions': 'История действий.',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'Replenishment': 'Пополнение',
        'withdrawal': 'Вывод',
        'withdrawal_fee': 'Комиссия вывода',
        'creating_an_order': 'Комиссия за создание ордера',
        'deposit_by_order': 'Прием платежей',
        'deposit_by_order_fee': 'Прием платежей',
        'wallet_withdrawal_fee': 'Вывод с кошелька',
        'recurrent_withdrawal_fee': 'Вывод с адреса-рекуррента',
        'personal_withdrawal_fee': 'Вывод с персонального адреса',
        'payout_withdrawal_fee': 'Вывод с выплатного баланса',
        'wallet_deposit_fee': 'Депозит на кошелек',
        'collect_withdrawal': 'Вывод с головных',
        'collect_withdrawal_fee': 'Вывод с головных',
        'deposit_by_wallet_fee': 'Депозит на кошелек',
        'recurrent_deposit_fee': 'Депозит на адрес-рекуррент',
        'personal_deposit_fee': 'Депозит на персональный адрес',
        'payout_deposit_fee': 'Пополнение выплатного баланса',
        'payout_auto_swap': 'Выплаты + автообмен',
        'payout_auto_swap_fee': 'Комиссия за автообмен в выплатах',
        'payout_auto_swap_fee_correction_decr': 'Коррекция комиссии по автообмену',
        'payout_auto_swap_fee_correction_incr': 'Коррекция комиссии по автообмену',
        'exchange_internal': 'Обмен',
        'exchange_internal_fee': 'Обмен',
        'exchange_auto_fee': 'Авто обмен',
        'transfer': 'Внутренний перевод',
        'transfer_fee': 'Комиссия внутреннего перевода',
        'kyt_address_fee': 'Риски адреса',
        'write-downs': 'Списание',
        'cancellation_of_the_operation': 'Отмена операции',
        'refund': 'Возврат',
        'bonus_accrual': 'Начисление бонуса',
        'from_address': 'С адреса:',
        'link_to_order': 'Ссылка на ордер',
        'type_operation': 'Тип операции',
        'select_type': 'Выберите тип',
        'amount_received': 'Полученная сумма',
        'amount': 'Сумма',
        'order': 'Ордер',
        'type_of_operation': 'Тип операции',
        'price': 'Цена',
        'link_to_withdrawal': 'Ссылка на вывод',
        'tariff_description': 'Комиссия за исполненную транзакцию по созданному ордеру',
        'select_currency': 'Выберите валюту',
        'will_be_converted': 'Ваши монеты будут переведены в «{currencyName}» по биржевому курсу.',
        'expire': 'Время до истечения адреса',
        'link': 'Ссылка',
        'btn-cancel': 'Отменить',
        'btn-close': 'Закрыть',
        'btn-continue': 'Продолжить',
        "btn_get-an-address": 'К оплате',
        'replenishment_method': 'Способ пополнения',
        'external_wallet': ' Внешний кошелек',
        'internal_pay-in-balance': 'Pay-in баланс',
        'internal_business-wallet': 'Бизнес кошелек',
        'internal_recurrent-address': 'Рекуррентный адрес',
        'internal_collect-address': 'Головные адреса',
        'spent_period': 'Потрачено за период',
        'one_day': '24 часа',
        'seven_day': '7 дней',
        'thirty_day': '30 дней',
        'all_period': 'Весь период',
        'choose-deposit-method': 'Выберите способ пополнения',
        'bridge_internal_fee': 'Блокчейн мост',
        'bridge_external_fee': 'Блокчейн мост API',
        'payment-address': 'Адрес оплаты',
        'kyt-transaction-fee': 'Риски транзакции',
        'kyt-withdrawal-address-fee': 'Риски вывода',
        'kyt-transaction': 'Проверка рисков транзакции',
        'kyt-withdrawal-address': 'Проверка рисков вывода',
        'exchange-auto': 'Обмен API',
        'exchange-auto-fee': 'Комиссия за обмен API',
        'orphan-deposit-withdrawal': 'Вывод сиротского депозита',
        'gas-deposit': 'Пополнение газа',
        'orphan-deposit-withdrawal-fee': 'Комиссия за вывод сиротского депозита',
        'orphan-deposit': 'Сиротский депозит',
        'timer': {
            'awaiting-payment': 'Ожидание оплаты',
            'payment-lifetime-has-expired': 'Срок оплаты истек'
        },
        'service-commission-amount': 'Service commission amount',
        'blockchain-commission-amount': 'Blockchain commission amount',
        'commission-source': 'Commission source',
        'btn-label': {
            'top-up': 'Пополнить',
            'commissions': {
                'my-commissions': 'Мои комиссии',
                'banner': {
                    'view': 'Посмотреть'
                },
                'contact-us-banner': {
                    'contact-us': 'Связаться с нами'
                }
            }
        },
        'info-card': {
            'balance': 'Баланс',
            'spend': 'Потрачено за период',
            'spend-period-select-labels': {
                'one-day': '24 часа',
                'seven-day': '7 дней',
                'thirty-day': '30 дней',
                'all-period': 'Весь период'
            }
        },
        'commissions': {
            'banner': {
                'title': 'Мои комиссии',
                'description': 'Узнайте больше о своих комиссиях на сервисе'
            },
            'contact-us-banner': {
                'title': 'Адаптируйте комиссии к вашему бизнесу',
                'description': 'На данный момент комиссии установлены за оборот транзакции в размере 1000$ в месяц. Если ваша компания осуществляет более крупные операции, свяжитесь с нами для корректировки комиссий.',
            },
            'groups': {
                'pay-in': 'Pay-in',
                'business-wallet': 'Бизнес-кошелек',
                'pay-out': 'Выплаты',
                'personal-addresses': 'Персональные адреса',
                'recurrents': 'Рекурренты',
                'internal': 'Внутренние переводы',
                'exchange': 'Обмен',
                'kyt': 'KYT',
                'orphan': 'Сиротские транзакции',
            },
            'actions': {
                'creating_an_order': 'Комиссия за создание ордера',
                'deposit_by_order': 'Прием платежей',
                'deposit_by_order_fee': 'Прием платежей',
                'transfer': 'Внутренний перевод',
                'transfer_fee': 'Комиссия внутреннего перевода',
                'wallet_withdrawal_fee': 'Вывод с кошелька',
                'recurrent_withdrawal_fee': 'Вывод с адреса-рекуррента',
                'personal_withdrawal_fee': 'Вывод с персонального адреса',
                'payout_withdrawal_fee': 'Вывод с выплатного баланса',
                'wallet_deposit_fee': 'Депозит на кошелек',
                'collect_withdrawal_fee': 'Вывод с головных',
                'collect_withdrawal': 'Вывод с головных',
                'deposit_by_wallet_fee': 'Депозит на кошелек',
                'recurrent_deposit_fee': 'Депозит на адрес-рекуррент',
                'personal_deposit_fee': 'Депозит на персональный адрес',
                'payout_deposit_fee': 'Пополнение выплатного баланса',
                'exchange_internal_fee': 'Обмен',
                'exchange_internal': 'Обмен',
                'bridge_internal_fee': 'Блокчейн мост',
                'bridge_external_fee': 'Блокчейн мост API',
                'payment_address': 'Адрес оплаты',
                'kyt_transaction_fee': 'Риски транзакции',
                'kyt_withdrawal_address_fee': 'Риски вывода',
                'kyt_transaction': 'Проверка рисков транзакции',
                'kyt_withdrawal_address': 'Проверка рисков вывода',
                'kyt_address': 'Проверка рисков адреса',
                'exchange_auto': 'Обмен API',
                'exchange_auto_fee': 'Обмен API',
                'orphan_deposit_withdrawal': 'Вывод сиротского депозита',
                'sepa-withdrawal': 'Вывод SEPA',
                'sepa-deposit': 'Депозит SEPA',
                'fiat-exchange': 'Фиат/крипто обмен',
                'payout_auto_swap': 'Выплаты + автообмен',
                'unknown_tariff': 'Неизвестный тариф'
            }
        },
        'history': {
            'filters': {
                'operation_type': {
                    'placeholder': 'Тип операции',
                    'types': {}
                }
            },
            'table': {
                'cols': {
                    'id': 'id',
                    'date': 'Дата',
                    'type': 'Тип',
                    'link': 'Ссылка',
                    'amount': 'Сумма сделки',
                    'currency-and-network': 'Валюта/Сеть',
                    'balance': 'Баланс',
                },
                'rows': {
                    'link': 'Детали транзакции',
                }
            }
        }
    },
    'swap': {
        'title': 'Обмен',
        'subtitle': 'Обменивайте валюты и фиксируйте прибыль в ручном или автоматическом режиме.',
        'divider': {
            'exchange-rates': 'Курс обмена'
        },
        'currency-network-select': {
            'from': {
                'label': 'Выберите валюту и сеть из'
            },
            'to': {
                'label': 'Выберите валюту и сеть в'
            }
        },
        'address_select': {
            'label_from': 'Выберите адрес откуда',
            'label_to': 'Выберите адрес куда',
            'placeholder': 'Адрес',
            'options_list': {
                'title': {
                    'choose_wallet': 'Выберите кошелек'
                },
                'sorting': {
                    'label': 'Сортировать по',
                    'fields': {
                        'amount': 'Сумме'
                    }
                },
                'back_btn': {
                    'label': 'Назад к выбору кошелька'
                },
                'empty': {
                    'addresses': "Нет адресов",
                    'wallets': "Нет кошельков"
                }
            }
        },
        'amount_input': {
            'label': 'Введите сумму',
            'available': 'Доступный баланс',
            'minimum': 'Минимум',
            'maximum': 'Максимум',
        },
        'amount_to_input': {
            'label': 'Вы получите',
        },
        'modals': {
            'execute': {
                'title': 'Подтверждение обмена',
                'currency': 'Валюта',
                'network': 'Сеть',
                'amount': 'Количество',
                'address': 'Адрес',
                'source': 'Источник оплаты комиссии',
                'advance_balance': 'Авансовый баланс',
                'swap_amount': 'Сумма обмена',
                'exchange_rate': 'Курс обмена',
                'service_commission': 'Комиссия сервиса',
                'network_commission': 'Комиссия сети',
                'confirm': 'Подтвердить',
                'cancel_btn': 'Отменить',
                'you_receive': 'Вы получите'
            },
            'create_address': {
                'title': 'Добавление адреса',
                'address': 'Адрес',
                'network': 'Сеть',
                'currency': 'Валюта',
                'alias': 'Псевдоним',
                'alias_placeholder': 'Введите текст',
                'comment': 'Комментарий',
                'comment_placeholder': 'Введите комментарий',
                'cancel_btn': 'Отменить',
                'save_btn': 'Сохранить',
                'warning': 'У вас нет адреса в выбранной валюте и сети. Вам нужно создать адрес.',
                'warning_btn': 'Создать адрес',
                'error': 'Ошибка'
            },
            'success': {
                'body-text': 'Обмен выполнен'
            }
        },
        'footer': {
            'execute_btn': 'Выполнить',
            'commission_source': {
                'service': 'Источник оплаты комиссии сервиса',
                'network': 'Источник оплаты комиссии сети',
            },
            'advance_balance': 'Авансовый баланс',
            'swap_amount': 'Сумма обмена',
            'service_commission': 'Комиссия сервиса',
            'network_commission': 'Комиссия сети'
        },
        'history': {
            'filters': 'Фильтры',
            'date': 'Дата',
            'copy': {
                'id_copied': 'Id скопирован',
                'id_not_copied': 'Id не скопирован',
            },
            'title': 'История обменов',
            'currency_from_placeholder': 'Из валюты',
            'currency_to_placeholder': 'В валюту',
            'network_from_placeholder': 'Сеть отправки',
            'network_to_placeholder': 'Сеть получения',
            'period': 'Период',
            'period_placeholder': 'Выберите период',
            'period_select_btn': 'Выбрать даты',
            'status_placeholder': 'Выберите статус',
            'statuses': {
                "CREATED": 'Создан',
                "PENDING": 'Обработка',
                "ERROR": 'Ошибка',
                "REJECTED": 'Отклонен',
                "PROCESSED": 'Выполнен',
            },
            'table': {
                'headers': {
                    'id': 'ID',
                    'amount': 'Сумма',
                    'date': 'Дата',
                    'state': 'Статус',
                    'currencyFrom': 'Из валюты',
                    'networkFrom': 'Сеть откуда',
                    'addressFrom': 'Адрес откуда',
                    'amountFrom': 'Из суммы',
                    'currencyTo': 'В валюту',
                    'networkTo': 'Сеть куда',
                    'addressTo': 'Адрес куда',
                    'amountTo': 'В сумму',
                    'networkCommission': 'Комиссия сети',
                    'serviceCommission': 'Комиссия  сервиса',
                },
            },
        }
    },
    'bridge': {
        'title': 'Блокчейн мост',
        'description': 'Блокчейн мост для токенов на ваших адресах между сетями.',
        'bridge_source': {
            'label': 'Источник моста',
            'business_wallet': 'Business wallet',
            'payin_wallet': 'Payin wallet',
        },
        'address_select': {
            'label_from': 'Выберите адрес откуда',
            'label_to': 'Выберите адрес куда',
            'placeholder': 'Адрес',
            'options_list': {
                'title': {
                    'choose_wallet': 'Выберите кошелек'
                },
                'sorting': {
                    'label': 'Сортировать по',
                    'fields': {
                        'amount': 'Сумме'
                    }
                },
                'back_btn': {
                    'label': 'Назад к выбору кошелька'
                },
                'empty': {
                    'addresses': "Нет адресов",
                    'wallets': "Нет кошельков"
                }
            }
        },
        'amount_input': {
            'label': 'Введите сумму',
            'available': 'Доступный баланс',
            'minimum': 'Минимум',
            'maximum': 'Максимум',
        },
        'currency_select': {
            'label': 'Выберите валюту',
            'placeholder': 'Валюта',
        },
        'network_select': {
            'label': 'Выберите сеть',
            'placeholder': 'Сеть',
        },
        'amount_to_input': {
            'label': 'Вы получите',
        },
        'footer': {
            'execute_btn': 'Выполнить',
            'source': 'Источник оплаты комиссии',
            'advance_balance': 'Авансовый баланс',
            'service_commission': 'Комиссия сервиса',
            'network_commission': 'Комиссия сети'
        },
        'modals': {
            'execute': {
                'title': 'Подтверждение блокчейн-моста',
                'currency': 'Валюта',
                'network': 'Сеть',
                'amount': 'Количество',
                'address': 'Адрес',
                'source': 'Источник оплаты комиссии',
                'advance_balance': 'Авансовый баланс',
                'service_commission': 'Комиссия сервиса',
                'network_commission': 'Комиссия сети',
                'confirm': 'Подтвердить',
                'cancel_btn': 'Отменить',
                'you_receive': 'Вы получите'
            },
            'create_address': {
                'title': 'Добавление адреса',
                'address': 'Адрес',
                'network': 'Сеть',
                'currency': 'Валюта',
                'alias': 'Псевдоним',
                'alias_placeholder': 'Введите текст',
                'comment': 'Комментарий',
                'comment_placeholder': 'Введите комментарий',
                'cancel_btn': 'Отменить',
                'save_btn': 'Сохранить',
                'warning': 'У вас нет адреса в выбранной валюте и сети. Вам нужно создать адрес.',
                'warning_btn': 'Создать адрес',
                'error': 'Ошибка'
            },
            'success': {
                'body-text': 'Блокчейн мост запущен'
            }
        },
        'errors': {
            'advance_balance_enough': 'Недостаточно средств на авансовом балансе',
        },
        'history': {
            'filters': 'Фильтры',
            'date': 'Дата',
            'copy': {
                'id_copied': 'Id скопирован',
                'id_not_copied': 'Id не скопирован',
            },
            'title': 'История блокчейн мостов',
            'description': 'Блокчейн мост для токенов на ваших адресах между сетями',
            'currency': 'Валюта',
            'currency_placeholder': 'Выберите валюту',
            'network_from_placeholder': 'Сеть отправки',
            'network_to_placeholder': 'Сеть получения',
            'period': 'Период',
            'period_placeholder': 'Выберите период',
            'period_select_btn': 'Выбрать даты',
            'status_placeholder': 'Выберите статус',
            'statuses': {
                "CREATED": 'Создан',
                "PENDING": 'Обработка',
                "ERROR": 'Ошибка',
                "REJECTED": 'Отклонен',
                "PROCESSED": 'Выполнен',
            },
            'table': {
                'headers': {
                    'id': 'ID',
                    'amount': 'Сумма',
                    'date': 'Дата',
                    'state': 'Статус',
                    'currency': 'Валюта',
                    'networkFrom': 'Сеть откуда',
                    'addressFrom': 'Адрес откуда',
                    'networkTo': 'Сеть куда',
                    'addressTo': 'Адрес куда',
                    'networkCommission': 'Комиссия сети',
                    'serviceCommission': 'Комиссия  сервиса',
                },
            },
        },
    },
    'addresses': {
        'title': 'Балансы',
        'button_transfer': 'Перевод',
        'description': 'Кошелек для приема оплаты по ордерам. Доступно сделать выводы прибыли и внутренние транзакции для пополнения авансового баланса.',
        'currency_search': 'Поиск валюты',
        'zero_balances': 'Нулевые балансы',
        'zero_balances_show': 'Нулевые балансы',
        'vacuum': 'Пылесос',
        'currency': 'Валюта',
        'address': 'Адрес',
        'network': 'Сеть',
        'balance': 'Баланс',
        'withdraw': 'Вывести',
        'deposit': 'Пополнить',
        'no_data_available': 'Нет данных',
        'search_address': 'Поиск по адресу...',
        card: {
            title: {
                currency: 'Валюта',
                network: 'Сетей:',
                addresses: 'Адресов:',
                address: 'Адрес:',
                balance: 'Общий баланс:',
                singleBalance: 'Баланс:',
            },
        },
    },
    'orders': {
        'account_replenished_successfully': "Аккаунт {start} **** {end} был пополнен на {amount} {ticker}",
        'title': 'Ордера',
        'lifetime': 'Срок действия ордера',
        'lifetimeMinValueMinute': 'от 30 минут',
        'lifetimeMinValueHour': 'от 0 часов | от {n} часа | от {n} часов',
        'lifetimeMaxValueHour': 'до 0 часов | до {n} часа | до {n} часов',
        'description': 'Описание',
        'no_data_available': 'Нет данных',
        'new_order': 'Новый ордер',
        'status': 'Статус',
        'select_the_status': 'Выберите статус',
        'with_errors': 'С ошибками',
        'select_currencies': 'Выберите валюту',
        'currencies': 'Валюта',
        'network': 'Сеть',
        'select_network': 'Выберите сеть',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'order': 'Ордер',
        'currency': 'Валюта',
        'received_amount': 'Полученная сумма',
        'amount': 'Сумма',
        'date_of_creation': 'Дата создания',
        'read_more': 'Подробнее',
        'date_of_creation_order': 'Дата создания',
        'validity': 'Дата и время истечения',
        'contract': 'Адрес контракта',
        'recipient-address': 'Адрес',
        'advance-balance': 'Авансовый баланс',
        'type-in': 'Депозит',
        'type-out': 'Вывод',
        'info_title': 'Детали ордера',
        'error-webhook': 'Webhook ошибки',
        'success-webhook': 'Webhook успеха',
        'return-url': 'URL возврата',
        'address-tag': 'Тег платежного адреса',
        'link': 'Ссылка на оплату',
        'order-number': 'Ордер №',
        'open': 'Перейти',
        'payment': 'Оплата заказа',
        'error': 'Ошибка',
        'refund': 'Возврат',
        'init': 'Инициирован',
        'pending': 'Обработка',
        'partial': 'Частично',
        'processed': 'Выполнен',
        'expired': 'Истек',
        'overpaid': 'Переплачен',
        'rejected': 'Отклонен',
        'received-order-amount': 'Получено по ордеру',
        'more': 'Подробнее',
        'alias': 'Псевдоним',
    },
    'api_documentation': {
        'title': 'Api документация',
        'description': 'Документация API сервиса {productName}.',
    },
    'api_keys': {
        'title': 'Ключи Api',
        'description': 'Доступно добавление нескольких ключей.',
        'no_data_available': 'Нет данных',
        'name': 'Название',
        'public_key': 'Публичный ключ',
        'private_key': 'Приватный ключ',
        'add_api_key': 'Добавить api ключ',
        'search': 'Поиск по названию...',
        'api_key_created': ' Api ключ создан',
        'creating_an_api_key': 'Создание api ключа',
        'editing_an_api_key': 'Редактирование api ключа',
        'name_of_the_api_key': ' Название api ключа (alias)',
        'ip_addresses': 'IPV4 адреса',
        'ipv6_addresses': 'IPV6 адреса',
        'ip_addresses_placeholder': 'Введите IPV4 адреса через пробел',
        'ipv6_addresses_placeholder': 'Введите IPV6 адреса через пробел',
        'ip_invalid': 'Неверный формат IP адреса',
        'ip_exist': 'Поле ввода IP адреса не должно быть пустым',
        'alias_error': 'Введите название api ключа',
        'be_sure_to_keep': 'Обязательно сохраните себе приватный ключ!',
        'creating_an_api_keys': 'Создание api ключей',
        'cancel': 'Отменить',
        'create': 'Создать',
        'close': 'Закрыть',
        'edit': 'Редактировать',
        'enter_a_new_api_key_name': 'Введите название api ключа',
        'save': 'Сохранить',
        'successful_deletion': 'Успешное удаление',
        'deleting_an_api_key': 'Удаление api ключа',
        'are_you_sure': 'Вы уверены что хотите удалить api ключ?',
        'delete': 'Удалить',
        'full': 'Полные права',
        'none': 'Только чтение',
        'partial': 'Частичные права',
        'list-of-available': 'Список доступных API ключей',
        'choose-api-keys': 'Выберите API ключ'
    },
    'api_keys-create': {
        'withdrawals': 'Выводы',
        'orders': 'Ордера',
        'invoices': 'Счета',
        'personal_addresses': 'Персональные адреса',
        'swaps': 'Обмены',
        'advanced_balance': 'Авансовый баланс',
        'orphan': 'Сироты',
        'recurrent': 'Рекурренты',
        'address_book': 'Адресная книга',
        'bridge': 'Мост',
        'view': 'Просмотр',
        'create': 'Создание',
        'update': 'Обновление',
        'withdraw': 'Вывод',
        'remove': 'Удаление',
        'payment': 'Платежи',
        'full-rights': 'Полные права',
        'partial-rights': 'Частичные права',
        'read-only': 'Только чтение',
    },
    'api-keys-zero': {
        'title': 'Здесь будут отображаться ваши API ключи',
        'subtitle': 'В данном разделе вы сможете создавать, редактировать и удалять ваши API ключи.',
        'title-desc': 'Обратите внимание: При создании ключа, приватный ключ будет показан только один раз. Сохраните его в надежном месте.',
        'create-title': 'Создать API ключи',
        'create-desc': 'Создайте API ключ для работы с сервисом',
    },
    'information': {
        'title': 'Информация',
        'description': 'Комиссия за транзакции в сетях блокчейн.',
        'currency': 'Валюта',
        'network': 'Сеть',
        'commission': 'Комиссия',
        'commission-source': 'Источник комиссии',
        'type': 'Тип',
        'native': 'Нативная',
        'token': 'Токен',
        'withdrawal-amount': 'Сумма вывода',
        'advance-balance': 'Авансовый баланс',
    },
    'transactions_new': {
        "transaction_types": {
            "DEPOSIT": "Депозит",
            "ORDER": "Ордер",
            "INVOICE": "Счет",
            "WITHDRAWAL": "Вывод средств",
            "AUTO_WITHDRAWAL": "Автовывод",
            "AUTO_SWAP": "Автообмен",
            "CROSSCHAIN_BRIDGE": "Кроссчейн-мост",
            "CROSSCHAIN_SWAP": "Кроссчейн-обмен"
        },
        "transaction_fields": {
            'id': "ID",
            'organizationId': "ID организации",
            'parentOperationId': "ID родительской операции",
            'operationId': "ID операции",
            'parentId': "Родительский ID",
            'organizationAlias': "Алиас организации",
            'organizationInternalName': "Внутреннее название",
            'organizationIsInternal': "Организация является внутренней",
            'organizationKyc': "KYC",
            'organizationAml': "AML",
            'organizationOwnerEmail': "Email владельца организации",
            'organizationOwnerId': "ID владельца организации",
            'organizationIsTestnet': "Тестовая сеть",
            'userId': "ID пользователя",
            'userEmail': "Email пользователя",
            'userRole': "Роль пользователя",
            'transactionType': "Тип транзакции",
            'txHash': "Хеш транзакции",
            'status': "Статус",
            'apiKeyAlias': "Алиас API-ключа",
            'apiKeyId': "ID API-ключа",
            'apiKeyPublic': "Публичный API-ключ",
            'startTs': "Начало транзакции",
            'finishTs': "Окончание транзакции",
            'amountTo': "Сумма получения",
            'currencyToType': "Тип получаемой валюты",
            'rateFromTo': "Курс обмена на валюту получения",
            'rateToFrom': "Курс обмена с валюты получения",
            'currencyTo': "Получаемая валюта",
            'networkTo': "Сеть получения",
            'addressTo': "Адрес получателя",
            'addressRiskTo': "Риск адреса получателя",
            'amountFrom': "Сумма отправки",
            'currencyFromType': "Тип отправляемой валюты",
            'currencyFrom': "Отправляемая валюта",
            'networkFrom': "Сеть отправки",
            'addressFrom': "Адрес отправителя",
            'addressRiskFrom': "Риск адреса отправителя",
            'networkFeeTo': "Комиссия сети для получателя",
            'networkFeeToSource': "Источник комиссии сети для получателя",
            'externalNetworkFee': "Внешняя комиссия сети",
            'networkFeeFrom': "Комиссия сети для отправител",
            'networkFeeFromSource': "Источник комиссии сети для отправителя",
            'networkFeeFromAmountInCurrencyFrom': "Комиссия сети в отправляемой валюте",
            'networkFeeFromUSD': "Комиссия сети в USD",
            'networkFeeFromEUR': "Комиссия сети в EUR",
            'exchangeName': "Название биржи",
            'exchangeAddressTo': "Адрес биржи для получения",
            'exchangeTxTo': "Транзакция на бирже для получения",
            'exchangeAddressFrom': "Адрес биржи для отправки",
            'exchangeTxFrom': "Транзакция на бирже для отправки",
            'advanceBalanceStart': "Начальный баланс аванса",
            'advanceBalanceFinish': "Конечный баланс аванса",
            'advanceBalanceStartTs': "Временная метка начала баланса аванса",
            'advanceBalanceFinishTs': "Временная метка окончания баланса аванса",
            'advanceBalanceFullComissionUSD': "Полная комиссия аванса в USD",
            'advanceBalanceFullComissionEUR': "Полная комиссия аванса в EUR",
            'advanceBalanceNetworkFeeUSD': "Сетевой сбор за аванс в USD",
            'advanceBalanceNetworkFeeEUR': "Сетевой сбор за аванс в EUR",
            'advanceBalanceServiceFeeAmountUSD': "Комиссия за обслуживание аванса в USD",
            'advanceBalanceServiceFeeAmountEUR': "Комиссия за обслуживание аванса в EUR",
            'advanceBalanceServiceFeePercent': "Процент комиссии за обслуживание аванса"
        },
        'labels': {
            'yes': "ДА",
            'no': "НЕТ",
            'customize': 'Настроить'
        },
        'statuses': {
            'INIT': 'Инициализировано',
            'ERROR': 'Ошибка',
            'PENDING': 'В ожидании',
            'PROCESSED': 'Обработано',
            'EXECUTED': 'Исполнено',
            'REJECTED': 'Отклонено',
            'EXPIRED': 'Просрочено',
            'REFUND': 'Возврат',
            'OVERPAID': 'Переплата'
        },
        "transaction_info_drawer": {
            'title': 'Детали транзации'
        }
    },
    'transactions': {
        'title': 'Транзакции',
        'description': 'Все ваши транзакции внутри сервиса с полной информацией по каждому движению средств.',
        'no_data_available': 'Нет данных',
        'method': 'Метод заведения',
        'type': 'Тип',
        'basis': 'Основание',
        'source': 'Источник',
        'address_from': 'Адрес откуда',
        'address_to': 'Адрес куда',
        'transfer': 'Перевод',
        'commission-transfer': 'Комиссия за перевод',
        'CurrencyNetwork': 'Валюта/Сеть',
        'debitCredit': 'Дебет/Кредит',
        'tx_hash': 'TX_hash',
        transfer_type: {
            pay_in: 'Входящий баланс',
            pay_out: 'Выплатной баланс',
            advance: 'Авансовый баланс',
        },
        'sum': {
            debit: 'Дебет',
            credit: 'Кредит',
        },
        'basis_enum': {
            'order': 'Ордер',
            'withdrawal': 'Вывод',
            'payment': 'Оплата',
            'refund': 'Возврат',
            'deposit': 'Депозит',
            'transfer': 'Перевод',
            'kyt': 'KYT',
            'exchange-auto': 'Обмен API',
            'exchange-internal': 'Обмен',
            'exchange-internal-fee': 'Комиссия за обмен',
            'collecting': 'Сбор прибыли',
            'commission_withdrawal': 'Комиссия вывода',
            'payout_auto_swap': 'Выплаты + автообмен',
            'payout_auto_swap_fee': 'Комиссия за автообмен в выплатах'
        },
        'source_enum': {
            'internal': 'Внутренний перевод',
            'external': 'Внешний перевод',
        },
        'method_enum': {
            'web': 'Web',
            'api': 'API',
            'pre_checkout': 'Донат',
        },
        'role': 'Ответственный',
        'status': 'Статус',
        'date_of_creation': 'Дата',
        'date_of_creation_order': 'Дата создания ордера',
        'network': 'Сеть',
        'write-off_currency': 'Валюта списания',
        'replenishment_currency': 'Валюта пополнения',
        'coin': 'Монета',
        'order_id': 'id ордера',
        'write-off_amount': 'Сумма списания',
        'deposit_amount': 'Сумма пополнения',
        'commission': 'Комиссия',
        'deposit': 'Пополнение авансового баланса',
        'advanced_balance_refund': 'Возврат на авансовый баланс',
        'exchange': 'Обмен валют',
        'exchange_internal': 'Обмен',
        'exchange_internal_fee': 'Комиссия за обмен',
        'network_fee': 'Комиссия сети',
        'orfan_deposit': 'Зачисление',
        'orfan_withdrawal': 'Вывод',
        'commission_create_order': 'Комиссия за создание ордера',
        'commission_execute_order': 'Комиссия за транзакцию по ордеру',
        'commission_wallet_deposit': 'Комиссия за депозит на кошелек',
        'commission_payout_deposit': 'Комиссия за депозит на выплатной баланс',
        'commission_recurrent_deposit': 'Комиссия за депозит на рекуррентный адрес',
        'commission_personal_deposit': 'Комиссия за депозит на персональный адрес',
        'commission_wallet_withdrawal': 'Комиссия за вывод с кошелька',
        'commission_payout_withdrawal': 'Комиссия за вывод с выплатного баланса',
        'deposit_collect': 'Депозит на головной',
        'commission_recurrent_withdrawal': 'Комиссия за вывод с рекуррентного адреса',
        'commission_collect_withdrawal': 'Комиссия за вывод с головных',
        'commission_personal_withdrawal': 'Комиссия за вывод с персонального адреса',
        'order_transaction': 'Транзакция ордера',
        'deposit_payout_balance': 'Депозит на выплатной баланс',
        'deposit_wallet': 'Депозит на кошелек',
        'deposit_recurrent': 'Депозит на рекуррентный адрес',
        'deposit_personal': 'Депозит на персональный адрес',
        'partners': 'Партнерская программа',
        'referrals': 'Реферальная программа',
        'rolling_reserve': 'Rolling Reserve',
        'withdrawal': 'Вывод',
        'commission_withdrawal': 'Комиссия за вывод',
        'init': 'Обработка',
        'error': 'Ошибка',
        'expired': 'Просрочено',
        'pending': 'Исполняется',
        'processed': 'Исполнена',
        'executed': 'Исполнена',
        'rejected': 'Отклонена',
        'partial': 'Частичная оплата',
        'refund': 'Возврат',
        'transaction_type': 'Тип транзакции',
        'search-address-id': 'Поиск по адресу или по хэшу...',
        'search': 'Поиск',
        'select_the_transaction_type': 'Выберите тип транзакции',
        'debiting_currencies': 'Валюта',
        'select_the_debiting_currencies': 'Выберите валюту',
        'replenishment_currencies': 'Валюта пополнения',
        'select_the_replenishment_currencies': 'Выберите валюту пополнения',
        'select_network': 'Сеть...',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'transaction_type_enum': {
            'commission': 'комиссия',
            'network_fee': 'сетевая плата',
            'deposit': 'депозит',
            'withdrawal': 'вывод',
            'exchange': 'обмен',
            'partners': 'партнеры',
            'referrals': 'рефералы',
            'rolling_reserve': 'подвижной резерв',
            'deposit_payout_balance': 'Выплатной баланс',

        },
        empty: '-',
        'bridge_external': 'Блокчейн мост API',
        'bridge_internal': 'Блокчейн мост ',
        'bridge_internal_fee': 'Комиссия за блокчейн мост',
        'bridge_external_fee': 'Комиссия за блокчейн мост API',
    },
    'withdrawal': {
        'title': 'Вывод',
        'description': 'При создании вывода необходимо указать адрес, с которого будет произведён вывод, также укажите сеть и сумму вывода.',
        'no_data_available': 'Нет данных',
        'status': 'Статус',
        'select_the_status': 'Выберите статус',
        'in_processing': 'В обработке',
        'completed': 'Выполнена',
        'error': 'Ошибка',
        'rejected': 'Отклонена',
        'pending': 'Ожидание',
        'platform': 'Платформа',
        'select_platform': 'Выберите платформу',
        'select_currencies': 'Выберите валюту',
        'currencies': 'Валюта',
        'select_network': 'Выберите сеть',
        'network': 'Сеть',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'create_a_withdrawal_request': 'Заявка на вывод',
        'currency': 'Валюта',
        'amount': 'Сумма',
        'date_of_creation': 'Дата создания',
        'date': 'Дата',
    },
    'order': {
        'title': 'Ордер',
        'advance_balance': 'Авансовый баланс:',
        'description': 'Описание:',
        'network': 'Сеть:',
        'currency': 'Валюта:',
        'amount_received': 'Полученная сумма:',
        'amount': 'Сумма:',
        'payment_address': 'Адрес получения:',
        'payment_address_tag': 'Тег адреса оплаты:',
        'link_for_payment': 'Ссылка для оплаты:',
        'date_of_order_creation': 'Дата создания ордера:',
        'validity_period_of_the_order': 'Срок действия ордера:',
        'transactions': 'Транзакции',
        'no_data_available': 'Нет данных',
        'return': 'Вернуться',
        transaction: {
            type_enum: {
                in: 'Пополнение',
                out: 'Вывод',
            },
            currency_type_enum: {
                in: 'Валюта пополнения',
                out: 'Валюта списания',
            },
        },
        empty: '-',
    },
    'transaction': {
        'title': 'Детали транзакции',
        'return': 'Вернуться',
        'type': 'Тип:',
        'status': 'Статус:',
        'id_in_the_system': 'ID в системе:',
        'order_id': 'ID ордера:',
        'network': 'Сеть:',
        'write-off_currency': 'Валюта списания:',
        'the_tag_of_the_write-off_address': 'Тег адреса списания:',
        'write-off_address': 'Адрес откуда:',
        'write-off_amount': 'Сумма списания:',
        'replenishment_currency': 'Валюта зачисления:',
        'the_tag_of_the_enrollment_address': 'Тег адреса зачисления:',
        'transfer_address': 'Адрес куда:',
        'transfer_amount': 'Сумма зачисления:',
        'transfer_amount_USD': 'Сумма зачисления в USD:',
        'transfer_amount_rate': 'Курс к USD:',
        'search-hash': 'Поиск по хэшу...',
        'search': 'Поиск...',
        'transfer_recipients': 'Адреса получателей:', confirmations_expected: 'Количество ожидаемых подтверждений:',
        'transfer_senders': 'Адреса отправителей:',
        'transaction_in_the_blockchain': 'Транзакция в блокчейне:',
        'date_of_creation': 'Дата создания:',
        confirmations: 'Подтверждений:',
        'transfer-amount': 'Сумма трансфера:',
        'comment': 'Комментарий:'

    },
    'transaction-column-select': {
      'title': 'Настройки колонок'
    },
    'request': {
        'title': 'Заявка на вывод',
        'return': 'Вернуться',
        'currency': 'Валюта:',
        'network': 'Сеть:',
        'status': 'Статус:',
        'amount': 'Сумма:',
        'commission': 'Комиссия:',
        'commission_source': 'Источник комиссии:',
        'sending_address': 'Адрес получения:',
        'tag_of_the_sending_address': 'Тег адреса отправки:',
        'date_of_application_creation': 'Дата создания заявки:',
        'address_balance': 'Баланс адреса',
        'advance_balance': 'Авансовый баланс',
        'init': 'Создан',
        'error': 'Ошибка',
        'processed': 'Успешно',
        'rejected': 'Отклонен',
        'pending': 'В обработке',
        '-': '-',
        'transaction_hash': 'Хэш транзакции',
        'withdrawal_address': 'Адрес списания',
    },

    'create-withdrawal-request-modal': {
        'application_created': 'Заявка создана',
        'creating_application': 'Вывод',
        'output_created': 'Вывод создан',
        'pending': 'Вывод находится в обработке',
        'transaction-pending': 'Транзакция в обработке',
        'error-title': 'Ошибка',
        'error': 'Произошла ошибка при создании вывода, обратитесь в поддержку',
        'the_withdrawal_is_completed': 'Транзакция в обработке',
        'withdrawal_rejected': 'Вывод отклонен, обратитесь в поддержку',
        'creating_an_application': 'Создание заявки',
        'output_address': 'Адрес, с которого выводить',
        'select_address': 'Выберите адрес',
        'receiving_address': 'Адрес получения',
        'invalid_address': 'Введите корректный адрес',
        'enter_address': 'Введите адрес зачисления',
        'tag': 'Тег:',
        'enter_tag': 'Введите тег, если он есть',
        'amount': 'Сумма:',
        'enter_amount': 'Введите сумму вывода',
        'max': 'МАКС',
        'async-title': 'Транзакция выполняется асинхронно. Результат выполнения можно увидеть в истории транзакции или в истории адреса:',
        'minimum_withdrawal': 'Минимум к выводу',
        'deposit_withdrawal_addresses_match': 'Адреса получения и вывода не должны совпадать',
        'advance_balance': 'Авансовый баланс',
        'address_balance': 'Баланс адреса',
        'commission_source': 'Источник комиссии',
        'commission': 'Комиссия:',
        'update': 'Обновить комиссии',
        'receive': 'ВЫ ПОЛУЧИТЕ',
        'cancel': 'Отменить',
        'send': 'Отправить',
        'close': 'Закрыть',
        'transaction_hash': 'Хэш транзакции:',
        'Advance_balance': 'авансовый баланс',
        'Address_balance': 'баланс адреса',
        'withdrawal_amount': 'Сумма вывода',
        'available': 'Доступно',
        'service_commission': 'Комиссия сервиса',
        'blockchain_fee': 'Комиссия блокчейн',
        'add_btn': 'Добавить новый адрес',
        'confirm': 'Отправить',
        'complete_processed_desc': 'Транзакция отправлена на подтверждение в блокчейне',
        'native': 'Нативная валюта'
    },

    'payout-modal-transfer': {
        'application_created': 'Заявка создана',
        'creating_application': 'Перевод',
        'output_created': 'Перевод создан',
        'pending': 'Перевод находится в обработке',
        'error': 'Произошла ошибка при создании перевод, обратитеть в поддержку',
        'the_withdrawal_is_completed': 'Перевод исполнен',
        'withdrawal_rejected': 'Перевод отклонен, обратитесь в поддержку',
        'creating_an_application': 'Создание заявки',
        'output_address': 'Источник перевода',
        'select_address': 'Выберите адрес',
        'receiving_address': 'Куда перевести:',
        'enter_address': 'Введите адрес зачисления',
        'tag': 'Тег:',
        'enter_tag': 'Введите тег, если он есть',
        'amount': 'Сумма',
        'enter_amount': 'Введите сумму перевода',
        'max': 'МАКС',
        'minimum_withdrawal': 'Минимум к переводу:',
        'current_advance_balance': 'Текущий авансовый баланс',
        'advance_balance': 'Авансовый баланс',
        'advance_balance_after': 'Авансовый баланс после транзакции',
        'address_balance': 'Баланс адреса',
        'service-commission-amount': 'Сумма комиссии сервиса',
        'blockchain-commission-amount': 'Сумма блокчейн комиссии',
        'commission-source': 'Источник комиссии',
        'commission': 'Комиссия',
        'update': 'Обновить Комиссии',
        'receive': 'Вы получите',
        'cancel': 'Отменить',
        'send': 'Отправить',
        'close': 'Закрыть',
        'transaction_hash': 'Хэш транзакции:',
        'Advance_balance': 'авансовый баланс',
        'Address_balance': 'баланс адреса',
        'advance': 'Авансовый баланс',
        'pay-out': 'Выплатной баланс',
        'commission_source': 'Источник комиссии:',
        'output': {
            'title-success': 'Успех',
            'title-error': 'Ошибка',
            'subtitle-success': 'Ваш внутренний перевод прошел успешно',
            'subtitle-error': 'Повторите попытку позже или обратитесь в службу поддержки',
        },
    },

    'create-order-modal': {
        'order': 'Ордер',
        'new_order': 'Новый ордер',
        'order_placeholder': 'Ордер №867123567142',
        'description': 'Описание',
        'description_of_order': 'Описание ордера',
        'currency': 'Валюта',
        'network': 'Сеть',
        'amount': 'Сумма',
        'creating_order': 'Создание ордера',
        'cancel': 'Отменить',
        'create': 'Создать',
        'close': 'Закрыть',
        'hours': 'Часы',
        'minutes': 'Минуты',
        'invalid-url': 'Некорректная ссылка',
        'enter-webhook': 'Введите webhook',
        'enter-url': 'Введите URL',
        'error-webhook': 'Webhook ошибки',
        'success-webhook': 'Webhook успеха',
        'error-url': 'URL возврата',
        'advanced-options': 'Расширенные настройки',
        'order-link-label': 'Ссылка на страницу оплаты заказа',
        'copy': 'Скопировать',
    },
    'preview-order-in-modal': {
        'address': 'Адрес:',
        'currency': 'Валюта:',
        'network': 'Сеть:',
        'amount': 'Сумма:',
        'valid_until': 'Дата и время истечения:',
        'link': 'Ссылка на страницу оплаты ордера:',
    },
    'checkout': {
        'shop': 'Магазин',
        'payment': 'Оплата',
        'back_to_the_store': 'Вернуться в магазин',
        'to_make_a_payment': 'Для совершения оплаты отправьте «{orderCurrency}» в сети «{orderAlias}» на указанный адрес',
        'payment_completed': 'Платеж исполнен',
        'the_payment_is_invalid': 'Платеж не действителен',
        'payment_rejected': 'Платеж отклонен',
        'payment_pending': 'Платеж в обработке',
        'payment_init': 'Платеж в ожидании',
        'lifetime_has_expired': 'Истекло время жизни платежа. Не отправляйте монеты по указанному адресу.',
        'contact_the_store_owner': 'Истекло время жизни платежа. Не отправляйте монеты по указанному адресу. Свяжитесь с владельцем магазина для возврата монет или продолжения оплаты',
        'order': 'Ордер:',
        'to_be_paid': 'К оплате:',
        'received': 'Получено:',
        'description': 'Описание:',
        'before_the_expiration_of_the_order': 'Cрок действия платежа: ',
        'network': 'Сеть:',
        'payment_address': 'Адрес оплаты:',
        'payment_address_not_found': 'Адрес оплаты не найден',
        'tag': 'Тег:',
        'contract_address': 'Адрес контракта:',
        'send_to_this_address_only': 'Отправляйте на этот адрес только',
        'make_sure_the_network_is_correct': 'Убедитесь, что сеть верна:',
        'network_is_correct': 'Убедитесь, что сеть верна:',
        'contract_address_matches': 'Убедитесь, что адрес контракта совпадает',
        'payment_page': 'Страница оплаты',
        'the_address_was_copied': 'Адрес успешно скопирован',
        'the_tag_was_copied': 'Тег был скопирован',
        'invoice_was_successfully_paid': 'Счет был успешно оплачен',
        'the_invoice_was_paid': 'Счет был оплачен на сумму ',
        'important_info': 'Важная информация',
        'the_contract_address_does_not_exist_on_this_network': 'адрес контракта не существует в этой сети',
        'error': 'Ошибка',
        'time_hours': 'часов',
        'time_minute': 'минут',
        'time_second': 'секунд',
        'sellers_address': 'Адрес продавца верифицирован',
        'stored_table': 'Здесь будет история входящих транзакций',
        'history_table': 'История входящих транзакций',
        'full_info': 'Показать полную информацию',
        'table_date': 'Дата',
        'table_sum': 'Сумма',
        'table_value': 'Валюта',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Сумма и дата',
        'payment_partially_paid': 'Частично оплачен',
        'paid_timer': 'Оплачено',
        'expired_timer': 'Срок действия платежа истек',
    },
    'new_checkout': {
        'details': {
            'title': 'Детали',
            'order': 'Ордер',
            'date': 'Дата',
            'description': 'Описание',
        },
        'notification': 'Пожалуйста, отправляйте только токены <b>{orderCurrency}</b> в сети <b>{orderNetwork}</b> на этот адрес.',
        'payment_address': 'Адрес платежа',
        'verified_notification': "Адрес продавца верифицирован",
        'paid_amount': "Сумма оплаты",
        'timer_notifications': {
            'remaining_time': 'Оставшееся время для оплаты',
            'completed': 'Платеж был успешно завершен',
            'expired_with_invoice': 'Срок действия ордера истек, но вы можете вернуться и создать новый.',
            'expired_without_invoice': 'Время жизни платежа истекло',
            'dont_make_transactions': "Не отправляйте транзакции на этот ордер!"
        },
        'statuses': {
            'not_paid': 'Не оплачен',
            'overpaid': 'Переплачен',
            'partially_paid': 'Частично оплачен',
            'fully_paid': 'Полностью оплачен',
        },
        'history': {
            'title': 'История платежей',
            'columns': {
                'date': 'Дата',
                'amount': 'Сумма',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Все ваши транзакции, отправленные на указанный адрес, будут доступны в этом окне.',
            'ok': 'OK'
        },
        'orphan': {
            'title': 'Потерянная транзакция',
            'description': 'В одной из транзакций этого ордера указана неправильная сеть или валюта.',
            'contact': 'Обратитесь в службу технической поддержки с ID',
        },
        'time-is-over': 'Время истекло',
        'payment-successful': 'Оплата прошла успешно',
        'partial-desc': 'Сумма была оплачена частично. Для успешной оплаты необходимо оплатить оставшуюся сумму.\n',
        'cancel': 'Отменить',
        'back_to_shop': 'Вернуться в магазин',
        'back_to_invoice': 'Назад к счету',
        'choose_another_currency': 'Выберите другую валюту',
        'you_can_pay': 'Вы можете оплатить счет в нескольких валютах!'
    },
    'error': {
        'server_error_contact_support': 'Ошибка сервера, обратитесь в службу поддержки.',
        'unknown_error_please_contact_support': 'Неизвестная ошибка, обратитесь в службу поддержки.',
        'it_is_necessary_to_choose_coin': 'Необходимо выбрать монету.',
        'no_coin_specified': 'Не указана монета',
        'you_must_specify_the_amount': 'Необходимо указать сумму',
        'the_amount_is_specified_incorrectly': 'Некорректно указана сумма',
        'the_amount_cannot_be_less_than': 'Сумма не может быть меньше или равна 0',
        'error_webhook_not_specified': 'Не указан Error Webhook',
        'invalid_URL_passed_as_Error_Webhook': 'Некорректный URL передан в качестве Error Webhook',
        'success_Webhook_is_not_specified': 'Не указан Success Webhook',
        'invalid_URL_passed_as_Success_Webhook': 'Некорректный URL передан в качестве Success Webhook',
        'incorrect_expiration_date_of_the_order': 'Некорректная дата истечения ордера',
        'empty_output_list': 'Пустой список на вывод',
        'the_output_address_is_not_specified': 'Не указан адрес для вывода',
        'invalid_output_address': 'Некорректный адрес для вывода',
        'invalid_tag_specified': 'Некорректный тег(memo) указан',
        'the_withdrawal_amount_is_not_specified': 'Не указана сумма вывода',
        'incorrect_withdrawal_amount': 'Некорректная сумма вывода',
        'the_withdrawal_amount_cannot_be_less_than': 'Сумма вывода не может быть меньше или равной 0',
        'an_unknown_coin_is_indicated': 'Указана неизвестная монета',
        'insufficient_funds_for_the_operation': 'Недостаточно средств для операции',
        'the_balance_is_less_than_the_requested_amount': 'Баланс меньше запрошенной суммы',
        'the_output_is_already_running_wait_for_it_to_finish_and_repeat': 'Вывод уже запущен, дождитесь его завершения и повторите',
        'the_debit_coin_is_not_specified': 'Не указана монета списания',
        'incorrect_debit_coin': 'Некорректная монета списания',
        'the_transfer_coin_is_not_specified': 'Не указана монета зачисления',
        'incorrect_crediting_coin': 'Некорректная монета зачисления',
        'the_amount_of_the_charge_is_not_specified': 'Не указана сумма списания',
        'incorrect_debit_amount': 'Некорректная сумма списания',
        'the_debit_amount_cannot_be_less_than': 'Сумма списания не может быть меньше или равна 0',
        'an_unknown_debit_coin_is_indicated': 'Указана неизвестная монета списания',
        'an_unknown_crediting_coin_is_specified': 'Указана неизвестная монета зачисления',
        'order_ID_not_specified': 'Не указан Order ID',
        'invalid_Order_ID': 'Некорректный Order ID',
        'invalid_URL_passed_as_Return_URL': 'Некорректный URL передан в качестве Return URL',
        'the_custom_Order_ID_is_too_long': 'Пользовательский Order ID слишком длинный',
        'payment_description_is_too_long': 'Описание платежа слишком длинной',
        'the_coin_network_is_not_specified': 'Не указана сеть монеты',
        'incorrect_Coin_network': 'Некорректная сеть монеты',
        'the_advance_balance_is_not_specified': 'Не указан авансовый баланс',
        'incorrect_advance_balance': 'Некорректный авансовый баланс',
        'address_not_specified': 'Не указан адрес',
        'invalid_address': 'Некорректный адрес',
        'no_token_specified_for_output': 'Не указан токен для вывода',
        'invalid_token_passed_for_output': 'Передан некорректный токен для вывода',
        'insufficient_funds_on_the_advance_balance': 'Недостаточно средств на авансовом балансе',
        'the_amount_is_less_than_the_minimum': 'Сумма меньше минимальной',
        'incorrect_coin_network': 'Некорректная сеть монеты',
        'the_withdrawal_token_has_expired_repeat_the_request_again': 'Токен для вывода истек, повторите запрос еще раз',
        'withdrawal_token': 'Токен для вывода был создан для другого авансового баланса или адреса',
        'the_exchange_is_prohibited': 'Обмен запрещен',
        'the_coin_is_not_available_for_operations': 'Монета недоступна для операций',
        'at_the_moment_the_withdrawal_is_suspended_in_this_coin': 'В данный момент вывод приостановлены в данной монете',
        'the_network_is_unavailable_for_operations': 'Сеть недоступна для операций',
        'the_network_is_undergoing_technical_work': 'Сеть находиться на технических работах',
        'at_the_moment_the_withdrawal_is_suspended_in_this_network': 'В данный момент вывод приостановлены в данной сети',
        'the_advance_balance_does_not_belong_to_the_current_organization': 'Авансовый баланс не принадлежит текущей организации',
        'the_address_does_not_belong_to_the_current_user': 'Адрес не принадлежит текущему пользователю',
        'the_withdrawal_amount_exceeds_the_available_balance': 'Сумма вывода превышает доступный баланс',
        'error_two-fa': 'Неверный код 2FA',
    },
    '2fa-auth-qrcode-modal': {
        'enable_two-factor_authorization': 'Подключить двухфакторную авторизацию',
        'remember_the_secret_code': 'Запомните секретный код:',
        'connect_two-factor_authentication': 'Подключите двухфакторную аутентификацию с помощью QR-кода или ввести код вручную внутри приложения',
        'enter_code_input-label': 'Введите код аутентификации после подключения',
        'enter_code_input-placeholder': 'Введите код...',
        'save_the_backup_codes_for_authorization': 'Сохраните запасные коды для авторизации',
        'close': 'Закрыть',
        '2FA_Authorization': 'Двухфакторная авторизация',
        'do_you_want_to_enable_two-factor_authorization': 'Хотите подключить двухфакторную авторизацию?',
        'Two-factor_authorization': 'Двухфакторная авторизация',
        'setup_successfully': 'Теперь вы можете использовать код аутентификации 2FA в любое время при входе в {productName}',
        'error_when_setting_up_two-factor_authorization': 'ошибка при настройке двухфакторной авторизации',
        'incorrect_code': 'неправильный код',
        'save_codes': 'Сохраните одноразовые коды, они пригодятся, если возникнут сложности со входом с помощью двухфакторной авторизации',
        'sorry_error': 'Извините что-то пошло не так, обратитесь в службу поддержки.',
        'access_recovery_keys': 'Доступ к ключам восстановления',
        'QR_code': 'QR код',
        'download': 'Скачать',
        'enable': 'Подключить',
        'next': 'Дальше',
        'invalid_code': 'Неверный код',
        'ok': 'OK',
        'success': 'Успешно',
        'success_info': 'Вы успешно подключили двухфакторную авторизацию',
        'invalid-password': 'Неверный пароль'
    },
    'donations': {
        'title': 'Донаты',
        'create_donation': 'Создать донат',
        'table_header': {
            'title': 'Название',
            'total_sum_24h': 'Сбор суммы за 24ч',
        },
        'details': 'Подробнее',
        'modal': {
            'title': 'Удаление страницы доната',
            'subtitle': 'Вы действительно хотите удалить',
            'success': 'Донат удален',
        },
    },
    'donation': {
        'title': 'Донат',
        'details': 'Список созданных страниц для сбора средств.',
        'orders': 'Ордера',

    },
    'create-donation': {
        'title': 'Создать донат',
        'title_edit': 'Редактировать донат',
        'upload-photo': 'Загрузить фото',
        'step-1': {title: 'Шаг 1', description: 'Введите ссылку, заголовок, выберите монеты'},
        'step-2': {title: 'Шаг 2', description: 'Подтвердите данные'},
        'inputs': {
            'labels': {
                'alias': 'Название',
                'color': 'Выберите цвет',
                'title': 'Заголовок',
                'description': 'Описание',
                'currency_list': 'Список монет',
                'currency_select_all': 'Выбрать все',
                'currency_list_selected': 'Выбрано',
                'currency_list_selected_out_of': 'из',
            },
            'placeholders': {
                'alias': 'Название',
                'title': 'Введите заголовок',
                'description': 'Введите описание',
            },
            'errors': {
                'title_empty': 'Введите заголовок',
                'currencies_empty': 'Добавьте валюту',
            },
        },
        'button': {
            'create_donation': 'Создать донат',
            'edit_donation': 'Редактировать донат',
            'ok': 'Готово',
            'go_back': 'Вернуться назад',
        },
        'result': {
            'success': {
                'created': 'Вы успешно создали донат',
                'edited': 'Вы успешно отредактировали донат',
            },
            'error': {
                'created': 'Что-то пошло не так. Код ошибки {error}',
                'edited_wrong': 'Что-то пошло не так.',
                'edited_unable': 'Не удалось отредактировать',
                'edited_code': 'Код ошибки {error}',

            },
        },
    },
    'preCheckout': {
        'errors': {
            'unknown_error': 'Неизвестная ошибка',
            'fail_to_create_order': 'Не удалось создать ордер',
            'external_service_error': 'Ошибка внешнего сервиса',
            'token_is_required': 'Токен обязателен',
            'currency_and_network_is_required': 'Валюта и сеть обязательны',
            'invalid_token': 'Некорректный токен',
            'page_not_found': 'Страница не найдена',
            'page_not_found_text': 'Мы не можем найти страницу, которую вы ищете',
            'page_not_accepted_deposits_in_currency': 'Некорректная валюта для этой страницы',
            'currency_not_allowed_for_deposits': 'Эта валюта не поддерживается для данной страницы',
        },
        'inputs': {
            'labels': {
                'amount': 'Сумма',
                'currency': "Валюта",
                'alias': 'Email или имя отправителя',
                'commentary': 'Комментарий',
            },
            'placeholders': {
                'amount': 'Введите сумму',
                'currency': "Выберите валюту",
                'alias': 'Введите email или имя отправителя',
                'commentary': 'Введите комментарий',
            },
        },
        'button': {
            'payment': 'Перейти к оплате',
        },
        footer: {
            product_2022: 'Все права защищены © {productName} 2023',
        },
        'new-tab': 'Страница для оплаты доната откроется в новой вкладке',
        'nothing-happening': 'Если ничего не происходит нажмите',
        here: 'здесь',
    },
    'payout-wallet': {
        'title': 'Выплаты',
        'subtitle': 'Список выплатных балансов.',
        'button-info': 'Подробнее',
        'button-create-payout': 'Перевод',
        'button-execute-registry': 'Исполнить',
        'button-replenish-payout': 'Пополнить баланс',
        'button-open-csv': 'Создать выплаты',
        'currency': 'Валюта',
        'withdrawal_amount': 'Сумма вывода',
        'add-address': 'Добавить адрес',
        'native-fee': 'Оплата комиссии сети с нативного баланса',
        'not-enough-balance': ' Недостаточно нативного баланса для оплаты комиссии.',
        'tooltip-transaction': 'Транзакция будет выполнена асинхронно. Результат выполнения транзакции отслеживайте в истории адреса или истории транзакций.',
        'tooltip-fee': 'Сумма предварительна, окончательная сумма комиссии будет после успешной транзакции.',

        'fee': {
            'advance': 'Комиссия с авансового баланса',
            'address': 'Комиссия с адресов',
        },
        'tab': {
            'balance': 'Балансы',
            'registry': 'Реестр выплат',
            'currency-and-network': 'Валюта/Сеть'
        },
        'modals': {
            'address-info': {
                'title': 'Информация об адресе',
                'labels': {
                    'currency-and-network': 'Валюта / Сеть',
                    'address': 'Адрес',
                    'auto-swap': 'Использовать для автообменов по умолчанию',
                    'direct-payout': 'Использовать для прямых выплат',
                    'alias': 'Псевдоним',
                    'comment': 'Комментарий'
                }
            }
        },
        'balance': {
            'network': 'Сеть',
            'address': 'Адрес',
            'amount': 'Сумма',
            'currency': 'Валюта',
            'gas': 'Газ',
            'menu': {
                'info': 'Информация'
            }
        },
    },
    'upload_registry': {
        'line': 'Строчка',
        'error': 'Ошибка',
        'status': {
            'init': 'Инициализирован',
            'pending': 'В обработке',
            'processed': 'Исполнен',
            'error': 'Ошибка',
            'partial': 'Исполнен частично',
            'unknown': 'Неизвестно',
        },
        'errors': {
            'amount_lte_zero': 'Сумма меньше или равно нулю',
            'incorrect_address': 'Некорректный адрес',
            'incorrect_tag': 'Некорректный тег',
            'currency_not_found': 'Валюта не найдена',
            'fail_to_process_bch': 'Ошибка обработки BCH адреса',
            'payout_address_not_found': 'Выплатной баланс не найден',
            'invalid_delimiter': 'Некорректный разделитель',
            'invalid_amount_of_columns': 'Некорректное количество колонок',
            'required_fields_are_empty': 'Обязательные поля не заполнены',
            'network_not_found': 'Сеть не найдена',
            'unknown_error': 'Неизвестная ошибка',
            'too_many_lines': 'Превышено ограничение количества строк',
        },
    },
    'payout_modal_deposit': {
        'label': 'Валюта и сеть',
        'placeholder': 'Выберите валюту и сеть',
        'title': 'Добавление адреса',
        'subtitle': 'Для продолжения необходимо выбрать валюту и сеть',
        'details': 'Вы можете отправлять сумму в неограниченном количестве',
        'alias': 'Псевдоним',
        'comment': 'Комментарий',
        'save': 'Сохранить',
        'address_for_replenishment': 'Адрес для пополнения',
        'enter_an_alias': 'Введите псевдоним',
        'enter_a_comment': 'Введите комментарий'
    },
    'payout_modal_upload': {
        'title': 'Создание выплаты',
        'subtitle': 'Для продолжения необходимо ввести название и загрузить csv файл',
        'label': 'Введите название',
        'placeholder': 'Название',
        'placeholder-file': {
            'uploaded': 'Файл загружен',
            'drag': 'Перетащите файл',
            'press': 'или нажмите сюда',
            'drop': 'Отпустите тут файл',
        },
        'button': {
            'upload': 'Загрузить',
            'cancel': 'Отменить',
            'save': 'Сохранить',
        },
    },
    'payout_modal_execute': {
        'title': 'Выполнить реестр',
        'subtitle': 'Выполнить реестр?',
        'pending': 'Реестр исполняется',
        'success': 'Реестр выполнен',
        'button': {
            'execute': 'Выполнить',
        },
    },
    'donation-order': {
        'title': 'Ордер доната',
        'status': 'Статус',
        'system_id': 'ID в системе',
        'amount': 'Сумма',
        'currency': 'Валюта',
        'network': 'Сеть',
        'orderId': 'ID ордера',
        'pageId': 'ID страницы',
        'nickname': 'Никнейм',
        'message': 'Сообщение',
        'received_usd': 'Получено в USD',
        'received': 'Получено',
        'price_usd': 'Курс к USD',
        'status_enum': {
            'pending': 'В обработке',
            'executed': 'Выполнен',
            'rejected': 'Отменен',
            'error': 'Ошибка',
            'init': 'Активный',
        },

    },
    'invoice': {
        'account_replenished_successfully': "Аккаунт {start} **** {end} был пополнен на {amount} {ticker}",
        'title': 'Счета ',
        'available-currencies-list': 'Список доступных валют',
        'select-currencies': 'Выберите валюты',
        'description': 'Создание счета в удобной валюте и отслеживание оплат счетов.',
        'account_number': 'Cчет №',
        'account_amount': 'Сумма счета',
        'payment_currency': 'Валюта оплаты',
        'payment_network': 'Сеть оплаты',
        'payment_amount': 'Сумма оплаты',
        'select_invoice_currency': 'Валюта счета...',
        'select_payment_currency': 'Валюта оплаты...',
        'select_payment_network': 'Сеть оплаты...',
        'sepa-withdrawal': 'Вывод SEPA',
        'sepa-deposit': 'Депозит SEPA',
        'fiat-exchange': 'Фиат/крипто обмен',
        'exchange_rate': 'Курс',
        'no_data_available': 'Нет данных',
        'new_bill': 'Новый Счет',
        'status': 'Статус',
        'select_the_status': 'Выберите статус',
        'active': 'Инициирован',
        'in_processing': 'Обработка',
        'partial_payment': 'Частично',
        'completed': 'Выполнен',
        'expired': 'Истек',
        'with_errors': 'С ошибками',
        'select_currencies': 'Выберите валюту',
        'currencies': 'Валюта',
        'network': 'Сеть',
        'select_network': 'Выберите сеть',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'order': 'id ордера',
        'invoice': 'Счет',
        'currency': 'Валюта',
        'received_amount': 'Полученная сумма',
        'date_of_creation': 'Дата создания',
        'error': 'Ошибка',
        'refund': 'Возврат',
        'overpaid': 'Переплачен',
        'read_more': 'Подробнее',
        'date_of_creation_order': 'Дата создания',
        'return': 'Вернуться',
        'go_to_invoice': 'Перейти к счету',
        'info_network': 'Валюта оплаты и сеть:',
        'info_title': 'Детали счета',
        'info_sum': 'Сумма счета:',
        'info_rate': 'Курс:',
        'info_pay_sum': 'Оплаченная сумма:',
        'info_description': 'Описание',
        'info_main_title': 'Счет',
        'invoice_currency': 'Валюта счета',
        'rejected': 'Отклонен',
        'created': 'Создан',
        'info_order_amount': 'Сумма ордера:',
        'order_amount': 'Сумма ордера',
        'light': 'Светлая',
        'dark': 'Темная',
        'sum-invoice': 'Сумма счета:',
        'select-invoice': 'Выберите валюту оплаты',
        'coin-network': 'Валюта и сеть:',
        'rate': 'Курс:',
        'sum-payment': 'Сумма оплаты:',
        'button': 'Оплатить',
        'search': 'Поиск по id...',
        'validity': 'Дата и время истечения',
        'info_btn_label': 'Перейти к счету',
        'amount': 'Сумма',
        'goto': 'Перейти',
    },
    'new_invoice': {
        'details': {
            title: 'Подробности',
            invoice: 'Счет',
            date: 'Дата',
            description: 'Описание'
        },
        'amount': 'Сумма',
        'btn_label': 'Далее',
        'cancel': 'Назад',
        'search': 'Поиск...',
        'back_to_shop': 'Вернуться в магазин',
        'choose-currency-and-network': 'Выберите валюту и сеть',
        'status': 'Статус',
        'you-payed': 'Вы заплатили',
        'statuses': {
            "ERROR": 'ОШИБКА',
            "PENDING": 'ОБРАБОТКА',
            "PROCESSED": 'ВЫПОЛНЕН',
            "REJECTED": 'ОТКЛОНЕН',
            'OVERPAID': 'ПЕРЕПЛАЧЕН',
            "CREATED": 'СОЗДАН',
            'PARTIAL': 'ЧАСТИЧНО ОПЛАЧЕН',
            'INIT': 'ИНИЦИИРОВАН',
            'EXPIRED': 'ИСТЕК'
        },
        'active_order': 'Активный ордер',
        'payment-successful': 'Оплата прошла успешно'
    },
    'create-invoice-modal': {
        'invoice': 'Счет',
        'new_invoice': 'Новый счет',
        'invoice_placeholder': 'Счет №',
        'exchange_rate_percent': '% на курс обмена',
        'checkbox_label': 'Комиссия сети на плательщике',
        'allowable_price': '% допустимый для проскальзывания цены',
        'description': 'Описание',
        'description_of_order': 'Описание счета',
        'currency': 'Валюта',
        'network': 'Сеть',
        'amount': 'Сумма',
        'creating_order': 'Создание счета',
        'cancel': 'Отменить',
        'create': 'Создать',
        'close': 'Закрыть',
        'hours': 'Часы',
        'minutes': 'Минуты',
        'invalid-url': 'Некорректная ссылка',
        'invalid-number': 'Некорректное число',
    },

    'address_book': {
        'title': 'Адресная книга',
        'sub_title': 'Удобное хранение внешних адресов для быстрого вывода.',
        'cancel': 'Отменить',
        'create': 'Создать',
        'close': 'Закрыть',
        'edit': 'Редактировать',
        'save': 'Сохранить',
        'deleting_an_book_key': 'Удаление адреса',
        'delete': 'Удалить',
        'are_you_sure': 'Вы действительно хотите удалить адрес',
        'note_line': 'Псевдоним',
        'address_line': 'Адрес',
        'network_line': 'Сеть',
        'button': 'Добавить адрес',
        'add_modal_title': 'Добавление адреса',
        'add_modal_placeholder': 'Введите текст',
        'add_modal_placeholder_address': 'Введите адрес',
        'add_modal_placeholder_network': 'Выберите сеть',
        'add_modal_comment': 'Комментарий (необязательно)',
        'change_modal_title': 'Информация об адресе',
    },
    'history-addresses': {
        'title': 'История адресов',
    },
    'downCommission': {
        'title': 'Хотите снизить комиссии?',
        'description': 'На данный момент для вашего аккаунта установлены максимальные ставки комиссии. Чтобы обсудить индивидуальные условия и снизить комиссию, напишите нам.',
        'confirm': 'Снизить комиссии',
        'cancel': 'Мне не интересно',
        'congratulations': 'Вы создали запрос на снижение комиссии. Мы свяжемся с вами.',
        'ready': 'Готово',
    },
    'historyAddresses': {
        'date_of_creation': 'Дата',
        'method': 'Метод заведения',
        'status': 'Статус',
        'basis': 'Основание',
        'source': 'Источник',
        'coin': 'Валюта',
        'network': 'Сеть',
        'type': 'Тип',
        'name': 'Псевдоним',
        'sum': 'Сумма',
        'risk': "Риск",
        'transaction': 'ТХ_hash',
        'from_to': 'Адрес откуда/куда',
        'address': 'Адрес',
        'pending': 'Ожидание',
        'title':  'Транзакция (tx hash)',
        'type_transaction': 'Тип транзакции',
        'write-off_address': 'Адрес откуда',
        'transfer_address': 'Адрес куда',
        'write-off_currency': 'Валюта',
        'executed': 'Выполнен',
        'title_main': 'История адресов',
        'comment': 'Комментарий',
        'information': 'Информация о транзакции',
        'input': 'Ввод',
        'conclusion': 'Вывод',
        'withdrawal': 'Вывод',
        'deposit': 'Депозит',
        'title_main_current': 'История адреса',
        'take_type': 'Выберите тип',
        'search-address-hash': 'Поиск по адресу или хэшу...',
    },
    'business-wallet': {
        'title': 'Бизнес-кошелек',
        'subtitle': 'Адреса для приема свободных депозитов без ордеров и счетов.',
        'header': {
            'search': {
                'placeholder': 'Поиск по псевдониму, адресу...'
            },
            'selects': {
                'currency': {
                    'placeholder': 'Валюта...'
                },
                'network': {
                    'placeholder': 'Сеть...'
                }
            },
            'buttons': {
                'archived': 'Архивные',
                'zero-balance': 'Нулевой баланс',
                'add-address': 'Добавить адрес'
            }
        },
        'table': {
            'columns': {
                'alias': 'Псевдоним',
                'network': 'Сеть',
                'currency': 'Валюта',
                'address': 'Адрес',
                'amount': 'Сумма'
            },
            'buttons': {
                'withdraw': 'Вывод'
            },
            'row-menu': {
                'info': 'Информация',
                'history': 'История',
                'archive':  {
                    'to': 'В архив',
                    'from': 'Из архива'
                },
            }
        },
        'modals': {
            'archive-toggle-modal': {
                'is-archived': {
                    'title': 'Возврат адреса из архива',
                    'info': 'Вы возвращаете адрес {address} из архива. Этот адрес будет виден в общем списке. Адрес можно отправить в архив, чтобы не видеть его в общем списке.'
                },
                'is-not-archived': {
                    'title': 'Отправка адреса в архив',
                    'info': 'Вы отправляете адрес {address} в архив. Этот адрес больше не будет виден в общем списке. Адрес можно вернуть из архива или увидеть все архивные адреса командой “архивные”.'
                },
                'buttons': {
                    'close': 'Закрыть',
                    'to-archive': 'Отправить в архив',
                    'from-archive': 'Вернуть из архива'
                }
            }
        },
        'transaction_hash': 'Хэш транзакции:',
        'button-info': 'Подробнее',
        'button-create-payout': 'Перевод',
        'button-execute-registry': 'Исполнить',
        'button-replenish-payout': 'Пополнить баланс',
        'button-open-csv': 'Создать выплаты',
        'currency': 'Валюта',
        'withdrawal_amount': 'Сумма вывода',
        'amount': 'Сумма',
        'address': 'Адрес',
        'pseudo': 'Псевдоним',
        'success': 'Адрес успешно добавлен',
        'success_update': 'Адрес успешно обновлен',
        'currency-and-select': 'Валюта/Сеть',
        'close': 'Закрыть',
        'enter-alias': 'Введите псевдоним',
        'fee': {
            'advance': 'Комиссия с авансового баланса',
            'address': 'Комиссия с адресов',
        },
        tab: {
            balance: 'Балансы',
            registry: 'Реестр выплат',
        },

        'balance': {
            'network': 'Сеть',
            'address': 'Адрес',
            'amount': 'Сумма',
            'currency': 'Валюта',
        },
        'add-address': 'Добавить адрес',
        'comment': 'Комментарий',
        'add-modal': {
            'title': 'Добавление адреса',
            'cancel': 'Отменить',
            'confirm': 'Сохранить',
            'continue': 'Продолжить',
            'creating': 'Добавление адреса',
            'alias-placeholder': 'Введите текст',
            'comment-placeholder': 'Введите комментарий',
        },
        'info-modal-title': 'Информация об адресе',
        'empty-currencies': 'Нет доступных монет',
        'added-address-desc': 'Вы успешно создали адрес получателя для {addressString}',
        'added-address-title': 'Адрес создан',
        'added-address-subtitle': 'Полный адрес',
    },
    'not-enough-advance-balance-modal': {
        'title': 'Вывод недоступен',
        'error-text': 'Сумма авансового баланса отрицательная:',
        'btn-label': 'Авансовый баланс',
    },
    'referral': {
        'title': 'Реферальная программа',
        'description': 'Щедрая и прозрачная программа вознаграждения.',
        'active_user': 'Активных: ',
        'zero_user': 'Не активных: ',
        'buffer_balance': 'Буферный баланс',
        'balance': 'Баланс',
        'frozen_balance': 'Замороженный баланс',
        'daily_charges': 'Начислений за 24 часа',
        'reward_balance': 'Баланс вознаграждения',
        'last_reward': 'Последнее вознаграждение',
        'next_reward': 'Следующее вознаграждение',
        'withdrawal': 'Вывод',
        'referral': 'Реферал',
        'curr_month': 'Текущий месяц',
        'total': 'Всего',
        'ref_code_copied': 'Реферальный код скопирован',
        'ref_link_copied': 'Реферальная ссылка скопирована',
        'success-withdraw': 'Вывод успешно проведен',
        'error-withdraw': 'При выводе произошла ошибка. Попробуйте позже.',
    },
    'buffer_balance_history': {
        'title': 'История буферного баланса',
        'merchant': 'Мерчант (email)',
        'select_merchant': 'Выберите мерчант',
        'commission_type': 'Тип комиссии',
        'select_commission_type': 'Выберите тип комиссии',
        'type': 'Тип',
        'select_type': 'Выберите тип',
        'period': 'Период',
        'deposit': 'Начисление',
        'withdrawal': 'Списание',
        'transfer_fee': 'Комиссия за перевод',
        'percent': 'Процент ',
        'sum': 'Сумма',
        'date': 'Дата',
        'empty': 'Нет данных',
        'accrual': 'Начисление',
        'write-downs': 'Списание',
    },
    'referral-commission-type': {
        'deposit_by_order_fee': 'Прием платежей',
        'wallet_withdrawal_fee': 'Вывод с кошелька',
        'payout_withdrawal_fee': 'Вывод с выплатного баланса',
        'deposit_by_wallet_fee': 'Депозит на кошелек',
        'payout_deposit_fee': 'Пополнение выплатного баланса',
        'bridge_external_fee': 'Блокчейн мост API',
        'bridge_internal_fee': 'Блокчейн мост',
        'exchange_internal_fee': 'Обмен',
        'exchange_auto_fee': 'Обмен API',
        'refund': 'Возврат',
        'reward_transfer': 'Вывод вознаграждения',
        'recurrent_deposit_fee': 'Депозит на адрес рекуррента',
        'personal_deposit_fee': 'Депозит на персональный адрес',
        'recurrent_withdrawal_fee': 'Вывод с адреса рекуррента',
        'personal_withdrawal_fee': 'Вывод с персонального адреса',

    },
    'reward_balance_history': {
        'title': 'История баланса вознаграждения',
        'sum': 'Сумма',
        'date': 'Дата',
        'type': 'Тип',
        'select_type': 'Выберите тип',
        'address': 'Адрес',
        'transaction': 'Транзакция',
        'address-copied': 'Адрес скопирован',
        'transaction-copied': 'Хеш транзакции скопирован',
    },
    'create-subscription': {
        'title_create': 'Новый мерчант',
        'title_edit': 'Редактировать мерчанта',
        'name': 'Название',
        'image-link': 'Или ссылка на изображение',
        'upload': 'Загрузить фото',
        'change': 'Изменить фото',
        'allowFreeSpend': 'Доступно использование методов рекуррентов',
        'maxSpendAmountUSD': 'Сумма списания',
        'approveAmountUSD': 'Сумма апрува',
        'interval': 'Частота вызовов',
        'restrictions': 'Данные по ограничениям',
        '15min': '15 минут',
        'week': 'Неделя',
        'day': 'День',
        'month': 'Месяц',
        'quater': 'Квартал',
        'year': 'Год',
        'update': 'Сохранить',
        'enter_name': 'Введите имя мерчанта',
        'enter_link': 'Введите ссылку на изображение',
        'currency_and_network': {
            'label': 'Валюта и сеть',
            'placeholder': 'Выберите валюты и сети для приема платежей',
        },
        'inputs': {
            'merchant_name': {
                'label': 'Название',
                'placeholder': 'Введите название мерчанта'
            },
            'merchant_image_link': {
                'label': 'Или ссылка на изображение',
                'placeholder': 'Введите ссылку на изображение'
            },
        },
        'actions': {
            'cancel': 'Отмена',
            'create': 'Создать',
            'save': 'Сохранить',
            'select_file': 'Выберите файл'
        }
    },
    'subscriptions': {
        'title': 'Платежные связки',
        'description': 'Для приема рекуррентных платежей создайте мерчанта',
        'second-description': 'Список и история платежных связок плательщиков.',
        'create-merchant': 'Создать мерчанта',
        'create-bundle': 'Создать связку',
        'search-label': 'Email или Ex ID',
        'email': 'Email',
        'token': 'Токен',
        'currency': 'Валюта',
        'status': 'Статус',
        'address': 'Адрес',
        'network': 'Сеть',
        'enter_email': 'Поиск по email или Ex id',
        'success': 'Активна',
        'pending': 'Обработка',
        'blocked': 'Заблокирована',
        'canceled': 'Отменена',
        'declined': 'Отклонена',
        'table': {
            'headers': {
                'email': 'Email',
                'ex_id': 'Ex id',
                'token': 'Токен',
                'currency': 'Валюта',
                'network': 'Сеть',
                'address': 'Адрес',
                'status': 'Статус'
            }
        },
        'statuses': {
            'SUCCESS': 'Активна',
            'PENDING': 'Обработка',
            'BLOCKED': 'Заблокирована',
            'CANCELED': 'Отменена',
            'DECLINED': 'Отклонена'
        }

    },
    'subscriptions-addresses': {
        'title': 'Платежные адреса',
        'description': 'Адреса для приема рекуррентных платежей',
        'top-bar': {
            'address': {
                'placeholder': 'Поиск по адресу...'
            },
            'network': {
                'placeholder': 'Сеть...'
            },
            'currency': {
                'placeholder': 'Валюта...'
            }
        },
        'table': {
            'headers': {
                'amount': 'Количество',
                'currency': 'Валюта',
                'network': 'Сеть',
                'address': 'Адрес'
            }
        },
        'actions': {
            'history': 'История',
            'withdraw': 'Вывод'
        },
        "modals": {
            'withdraw': {
                'title': "Вывод",
                'selects':  {
                    'withdrawal-address': {
                        'label': "Адрес вывода:",
                    },
                    'receiving-address': {
                        'placeholder': "Введите адрес",
                        'label': "Адрес получения:",
                    },
                    'amount': {
                        'placeholder': "0",
                        'label': "Количество",
                    },
                    'Comment': {
                        'placeholder': "Введите описание",
                        'label': "Комментарий",
                    }
                },
                'switcher': {
                    'label': 'Источником сетевой комиссии является отправитель',
                    'description': 'Описание',
                }
            },
            'success': {
                'withdraw': {
                    'title': 'Транзакция в обработке',
                    'subtitle': 'Хэш транзакции:',
                },
            },
            'error': {
                'withdraw': {
                    'title': 'Ошибка',
                    'subtitle': 'Внутренняя ошибка',
                    'subtitle-with-request-id': 'Внутренняя ошибка. ID запроса:',
                }
            },
            'reject': {
                'withdraw': {
                    'title': 'Вывод невозможен',
                    'subtitle': 'Сумма аванса отрицательная:',
                }
            },
            'actions-labels': {
                'close': "Закрыть",
                'create': "Создать",
            }
        }
    },
    'upload-avatar': {
        'title': 'Загрузка изображения',
        'upload_formats_description': 'Вы можете загрузить изображение в формате',
        'upload_formats': 'JPEG, PNG, WebP, GIF',
        'max_file_size_description': 'Максимально допустимый размер файла',
        'max_file_size': '5 мб',
        'choose_file': 'Выберите файл',
        'error': 'Файл не удовлетворяет требованиям'
    },
    'create-bundle': {
        'title': 'Создание платежной связки',
        'externalId': 'Внешний ID плательщика',
        'email': 'Email подписчика',
        'bundle_link': 'Ссылка на связку',
        'enter_email': 'Введите email',
        'enter_external': 'Введите внешний id',
    },
    'organization': {
        'organizations_title': 'Организация',
        'organizations_description': 'Управление организацией, пользователями и ролями доступа.',
        'organization': 'Организация',
        'organization_users': 'Пользователи',
        'organization_user': 'Пользователь',
        'organization_roles': 'Роли',
        'organization_role': 'Роль',
        'organization_last_login': 'Последний визит',
        'organization_btn_add': 'Добавить',
        'organization_btn_add_role': 'Добавить',
        'organization_btn_save': 'Сохранить',
        'organization_btn_close': 'Закрыть',
        'organization_btn_transfer': 'Передать',
        'organization_btn_create': 'Создать',
        'organization_btn_back': 'Вернуться',
        'organization_company_name': 'Имя',
        'organization_enter_title': 'Введите название',
        'organization_user_and_role': 'Пользователь и роль',
        'organization_information_role': ' Информация о роли',
        'organization_comment': 'Комментарий',
        'address_book': 'Адресная книга',
        'advanced_balance': 'Авансовый Баланс',
        'api_keys': 'Ключи API',
        'donations': 'Донаты',
        'invoices': 'Счета',
        'orders': 'Ордера',
        'payout_balances': 'Выплаты',
        'transactions': 'Транзакции',
        'wallets_business': 'Бизнес-кошелек',
        'wallets_crosschain_swap': 'Обмены',
        'wallets_crosschain_transfer': 'Мост',
        'wallets_payin': 'Pay-in',
        'organization_enter_input': 'Введите данные',
        'organization_enter_email': 'Введите email',
        'organization_enter_name': 'Введите имя',
        'organization_enter_comment': 'Введите комментарий',
        'modal_user': 'Новый пользователь',
        'modal_create_user': 'Ссылка для нового пользователя успешно создана',
        'modal_transfer': 'Передача',
        'modal_transfer_new_title': 'Новый владелец организации:',
        'modal_transfer_success': 'Успешно',
        'modal_transfer_error': 'Внутренняя ошибка',
        'modal_transfer_error_id': 'ID запроса:',
        'modal_transfer_empty': 'В вашей организации нет ни одного пользователя. Пригласите пользователя в организацию.',
        'modal_transfer_desc': 'Выберите нового владельца организации. После подтверждения передачи владения организации вы останетесь в роли администратора с полными правами.',
        'modal_name': 'Имя',
        'modal_take_role': 'Выберите роль',
        'modal_take_user': 'Выберите пользователя',
        'modal_email': 'Email',
        'modal_link_invite': 'Ссылка для приглашения',
        'modal_btn_invite': 'Пригласить',
        'modal_btn_close': 'Закрыть',
        'modal_link_copy': 'Ссылка скопирована',
        'modal_delete_role': 'Удаление роли',
        'modal_delete_user': 'Удаление пользователя',
        'modal_delete_message': 'Вы действительно хотите удалить роль',
        'modal_delete_message_user': 'Вы действительно хотите удалить пользователя',
        'modal_delete_info': 'Информация о пользователе',
        'modal_delete_title': 'Предупреждение!',
        'organization_no_user_found': 'Пользователь не найден или не зарегистрирован',
        'organization_user_already_in': 'Пользователь уже состоит в этой организации',
        'recurrent': 'Рекурренты',
        'personal': 'Персональные адреса',
        'webhooks': 'Вебхуки',
        'view_webhooks': 'Просмотр вебхуков',
        'view_dashboard': 'Просмотр дашборда',
        'send_webhook_again': 'Отправить вебхук заново',
        'collecting': 'Сбор прибыли',
        'view_settings': 'Просмотр настроек',
        'view_history': 'Просмотр истории',
        'manage_settings': 'Управление настройками',
        'success': 'Успех',
        'success-invite-desc': 'Для вступления в вашу организацию, отправьте ссылку новому пользователю',
        'manage_address_book': 'Добавление/удаление адреса',
        'update_address_book': 'Редактирование адреса',
        'view_address_book': 'Просмотр адресной книги',
        'view_advanced_balance_history': 'Просмотр истории авансового баланса',
        'view_analytics': 'Просмотр аналитики',
        'manage_api_keys': 'Управление API ключами',
        'view_api_keys': 'Просмотр API ключей',
        'manage_donations': 'Управление донатами',
        'view_donations': 'Просмотр донатов',
        'create_invoice': 'Создание счета',
        'view_invoices': 'Просмотр счетов',
        'create_order': 'Создание ордера',
        'view_orders': 'Просмотр ордеров',
        'make_withdrawal': 'Создание вывода',
        'view_transactions': 'Просмотр транзакций',
        'create_payout_balances': 'Создание выплатного баланса',
        'make_transfer': 'Создание перевода',
        'view_payout_balances': 'Просмотр выплатных балансов',
        'manage_addresses': 'Управление адресами',
        'manage_users': 'Управление пользователями',
        'view_addresses': 'Просмотр адресов',
        'view_users': 'Просмотр пользователей',
        'create_billing_link': 'Создание платежной связки',
        'create_merchant': 'Создание мерчанта',
        'view_billing_links': 'Просмотр платежных связок',
        'view_withdrawals': 'Просмотр выводов',
        'create_business_address': 'Создание бизнес-адреса',
        'manage_business_address': 'Управление бизнес-адресами',
        'view_wallets': 'Просмотр кошельков',
        'iframe_widgets': "Виджеты",
        'make_crosschain_swap': 'Создание обмена',
        'view_crosschain_swaps': 'Просмотр истории обменов',
        'make_crosschain_transfer': 'Создание блокчейн моста',
        'view_crosschain_transfers': 'Просмотр истории мостов',
        'create_payin_address': 'Создание pay-in адреса',
        'orphan': 'Сиротские транзакции',
        'analytics': 'Аналитика',
        'bank_accounts': 'Банк',
        'view': 'Просмотр',
        'create': 'Создание',
        'update': 'Редактирование',
        'delete': 'Удаление',
        'modals': {
            'settings': {
                'organization_title': 'Организация',
                'upload_image': 'Загрузка изображения',
                'inputs': {
                    'organization_name_input_label': 'Название',
                    'organization_name_input_placeholder': 'Введите название организации',
                    'organization_link_img_input_label': 'Ссылка на изображение',
                    'organization_link_img_input_placeholder': 'Введите ссылку на изображение'
                },
                'file_uploader': {
                    'subtitle': 'Нажмите для загрузки изображения',
                    'file_format': {
                        'text': 'Вы можете загрузить изображения следующий форматов',
                        'formats': 'JPEG, PNG, WebP, GIF'
                    },
                    'file_size': {
                        'text': 'Максимально возможный размер',
                        'value': '5 мб'
                    },
                    'drop_text': {
                        'waiting': 'Или переместите свое изображение сюда',
                        'success': 'Ваше изображение загружено',
                        'error': 'Ваше изображение не было загружено. Пожалуйста, попробуйте еще раз'
                    }
                },
                'buttons': {
                    'save': 'Сохранить',
                    'close': 'Закрыть',
                    'select_file': 'Выберите файл'
                }
            }
        },
        'number-of-participants': 'Количество участников',
        'exchanges-bridges': 'Обмены и мосты',
        'recurring': 'Рекуррентные платежи',
        'connect': 'Подключить',
        'pass-kyb': 'Пройти KYB',
        'members': 'Участники',
        'available': 'Доступно',
        'organization-information': 'Информация об организации',
        'more': 'Подробнее',
        'organization-new-users': 'Новые добавленные пользователи',
        'organization-new-roles': 'Новые добавленные роли',
        'history': 'История',
        'disabled': 'Отключено',
        'no-users': 'У вас нет добавленных пользователей',
        'no-roles': 'У вас нет добавленных ролей',
        'passed': 'Пройдено',
        'list': {
            'user': 'Пользователь',
            'role': 'Роль',
            'last-visit': 'Последний визит'
        },
        'save': 'Сохранить',
        'organization-form': {
            'website': 'Вебсайт',
            'enter-website': 'Введите ваш вебсайт',
            'telegram': 'Телеграм владельца',
            'enter-telegram': 'Введите телеграм владельца',
            'email': 'Электронная почта владельца',
            'enter-email': 'Введите почту',
            'time-zone': 'Часовой пояс',
            'choose-time-zone': 'Выберите часовой пояс',
            'main-currency': 'Основная валюта',
            'choose-main-currency': 'Выберите основную валюту'
        }
    },
    'info_modals': InfoModals,
    'rules': {
        'not_allowed': 'Нет прав доступа к разделу',
    },
    'kyc-modal': {
        'title': 'Предупреждение!',
        'description': 'Уважаемый клиент, пожалуйста, обратите внимание, что в настоящее время наш сервис недоступен для использования без прохождения обязательной процедуры KYB, тем не менее, вы можете перейти на эту веб-страницу, чтобы поближе ознакомиться с тем, что мы можем вам предложить. Принимая это предупреждение, вы подтверждаете свою осведомленность о текущих требованиях KYB.',
    },
    'languages': {
        'select': 'Выберите язык'
    },
    'two-fa-restrictions': {
        'title': 'Действие недоступно',
        'enableTwoFA': 'Включить 2FA',
        'cancel': 'Отменить',
        'description': 'Действие недоступно без двухфакторной аутентификации.'
    },
    'two-fa-confirmation': {
        'title': 'Двухфакторная аутентификация',
        'input-label': 'Введите код для подтверждения действия',
        'input-placeholder': 'Введите код 2FA',
        'cancel': 'Отменить',
        'next': 'Далее',
        'error_length': 'Код должен быть не менее 6 символов',
        'error_letter': 'Код должен быть не менее 6 символов',
        'error_two-fa': 'Неверный код 2FA',
    },
    'orphan': {
        'title': 'Потерянные транзакции',
        'subtitle': 'Розыск сиротских транзакций',
        'where-found': 'Где найдено:',
        'date': 'Дата',
        'amount': 'Количество',
        'currency-network': 'Валюта/Сеть',
        'info': 'Информация о транзакции',
        'return': 'Вернуться',
        'transfer-address': 'Адрес передачи',
        'status-processed': 'Успешно',
        'status-error': 'Ошибка',
        'status-rejected': 'Отклонено',
        'status-init': 'Инициирован',
        'status-pending': 'В обработке',
        'select-status': 'Выберите статус',
        'empty': 'Не найдено',
        'search': 'Поиск по хэшу...',
        'info-btn': 'Информация',
        'success-withdrawal': 'Транзакция является асинхронной. Хэш транзакции будет доступен в ближайшее время.',
        'withdrawal-comment': 'Комментарий вывода',
    },
    'webhooks': {
        'title': 'Вебхуки',
        'subtitle': 'Отслеживайте статусы оплат через API.',
        'where-found': 'Где найдено',
        'date': 'Дата события',
        'amount': 'Количество',
        'currency-network': 'Валюта/Сеть',
        'info': 'Информация о транзакции',
        'return': 'Вернуться',
        'empty': 'Не найдено',
        'search': 'Поиск по ID, URL...',
        'info-btn': 'Информация',
        'response-status': 'Статус ответа...',
        'event': 'Событие',
        'period': 'Период...',
        'status': 'Статус',
        'event-date': 'Последняя попытка',
        'order': 'Ордер',
        'invoice': 'Счет',
        'withdrawal': 'Вывод',
        'deposit_orphan': 'Сиротский депозит',
        'deposit_personal': 'Депозит на персональный адрес',
        'crosschain_transfer': 'Мост',
        'crosschain_swap': 'Обмен',
        'rec_billing_link': 'Платежные связки',
        'rec_subscription': 'Подписка',
        'rec_free_payment': 'Свободный платеж',
        'success': 'Успех',
        'redirect': 'Редирект',
        'client-error': 'Ошибка клиента',
        'server-error': 'Ошибка сервера',
        'internal-error': 'Внутренняя ошибка',
        'auto_swap': 'Автообмен',
        'auto_withdrawal': 'Авто-вывод',
        'auto_withdrawal_approve': 'Разрешение авто-вывода'
    },
    'webhook-info': {
        'title': 'Детализация вебхука',
        'api-key-alias': 'Имя API ключа',
        'signature': 'Подпись',
        'request-headers': 'Заголовки запроса',
        'request-body': 'Тело запроса',
        'response-body': 'Тело ответа',
        'send-hook': 'Отправить вебхук заново'
    },
    "personal-addresses": {
        "title": "Персональные адреса",
        'subtitle': 'Формируйте список адресов для каждого плательщика индивидуально.',
        "description": "Описание раздела",
        "modals": {
            'add-new-user': {
                'title': "Новый пользователь",
                'inputs':  {
                    'email': {
                        'placeholder': "Введите email",
                        'label': "Email",
                        'not-valid': "Неверный адрес электронной почты",
                        'exist': "Этот адрес занят другим пользователем",
                    },
                    'external-id': {
                        'placeholder': "Введите id",
                        'label': "Внешний id",
                    },
                },
                'checkboxes': {
                    'create-addresses': {
                        'label': "Создать адреса",
                    },
                }
            },
            'add-new-address': {
                'title': "Добавление адреса",
                'subtitle': "Добавление адреса для {clientEmail}",
                'selects':  {
                    'network': {
                        'placeholder': "Выберите сеть",
                        'label': "Сеть",
                    },
                    'currency': {
                        'placeholder': "Выберите валюту",
                        'label': "Валюта",
                    }
                }
            },
            'withdraw': {
                'title': "Вывод",
                'selects':  {
                    'withdrawal-address': {
                        'label': "Адрес вывода:",
                    },
                    'receiving-address': {
                        'placeholder': "Введите адрес",
                        'label': "Адрес получения:",
                    },
                    'amount': {
                        'placeholder': "0",
                        'label': "Количество",
                    },
                    'Comment': {
                        'placeholder': "Введите описание",
                        'label': "Комментарий",
                    }
                }
            },
            'success': {
                'add-user': {
                    'title': 'Успех',
                    'subtitle': 'Новый пользователь успешно создан',
                },
                'add-address': {
                    'title': 'Адрес создан',
                    'subtitle': {
                        'first-line': "Для пользователя {clientEmail} успешно создан адрес в валюте {currencyNetwork}",
                        'second-line': "Вы успешно создали адрес получателя:"
                    },
                },
                'withdraw': {
                    'title': 'Транзакция в обработке',
                    'subtitle': 'Хэш транзакции:',
                }
            },
            'error': {
                'add-user': {
                    'title': 'Ошибка',
                    'subtitle': 'Произошла ошибка',
                    'subtitle-with-request-id': 'Произошла ошибка. ID запроса:',
                },
                'add-address': {
                    'title': 'Ошибка',
                    'subtitle': 'Произошла ошибка',
                    'subtitle-with-request-id': 'Произошла ошибка. ID запроса:',
                },
                'withdraw': {
                    'title': 'Ошибка',
                    'subtitle': 'Внутренняя ошибка',
                    'subtitle-with-request-id': 'Внутренняя ошибка. ID запроса:',
                }
            },
            'actions-labels': {
                'close': "Закрыть",
                'create': "Создать",
            }
        },
        "buttons": {
            "add-new-user": "Добавить пользователя",
            "add-address": "Добавить адрес",
            "withdraw": "Вывод"
        },
        "inputs": {
            "search": {
                "placeholder": {
                    "common": "Поиск",
                    "persons":  "Поиск по Email, адресу, внешнему id..."
                }
            }
        },
        'persons-list': {
            'cols': {
                'email': 'E-mail:',
                'id': 'ID:',
                'external-id': 'Внешний ID:',
                'total-balance': 'Общий баланс:',
            },
            'empty': {
                'title': 'Пока нет или не найдено ни одного пользователя',
                'subtitle': 'Создайте пользователй'
            }
        },
        'addresses-list': {
            'cols': {
                'address': 'Адрес:',
                'balance': 'Баланс:',
                'currency-network': 'Валюта/Сеть:',
                'status': {
                    'label': 'Статус:',
                    'active': 'Активный',
                    'inactive': 'Неактивный'
                }
            },
            'empty': {
                'title': 'Нет ни одного адреса',
                'subtitle': 'Создайте адреса для пользователя'
            }
        }
    },
    "analytics": {
        'title': 'Аналитика',
        'filters': {
            'button-select': {
                'date': 'Дата',
                'period': 'Период'
            },
            'grouping': {
                'placeholder': 'Группировать по...',
                'labels': {
                    'network': 'По сети',
                    'wallet': 'По кошельку',
                }
            },
            'wallets': {
                'placeholder': 'Кошелек...',
                'labels': {
                    'pay_in': 'Pay-in',
                    'personal_address': 'Персональные адреса',
                    'business_wallets': 'Бизнес-кошелек',
                    'pay_out': 'Выплаты',
                    'payment_addresses': 'Платежные адреса',
                    'main': 'Головные адреса'
                },
                'select-all': 'Выбрать все',
                'selected-label': 'Выбрано: {selected} из {length}',
            },
            'date-picker': {
                'btn-label': 'Выбрать',
                'btn-cancel': 'Отмена',
                'placeholder': {
                    'period': 'Период',
                    'date': 'Дата',
                }
            },
        },
        'tables': {
            'wallets': {
                'date': {
                    'columns': {
                        'income': 'Приход',
                        'outcome': 'Расход',
                        'balance': 'Баланс'
                    },
                    'rows': {
                        'date': 'Дата',
                        'currency': 'Валюта',
                        'wallet': 'Кошелек',
                        'wallets': {
                            'PAY_IN': 'pay-in',
                            'PAY_OUT': 'выплаты',
                            'BUSINESS': 'бизнес',
                            'RECURRENT': 'рекуррент',
                            'PERSONAL': 'персональный',
                            'COLLECT': 'головной'
                        },
                        'network': 'Сеть',
                    }
                },
                'period': {
                    'columns': {
                        'sum_start': 'Сумма, начало',
                        'sum_end': 'Сумма, конец',
                        'income': 'Приход',
                        'outcome': 'Расход'
                    },
                    'rows': {
                        'period_start': 'Начало периода',
                        'period_end': 'Конец периода',
                        'currency': 'Валюта',
                        'wallet': 'Кошелек',
                        'wallets': {
                            'PAY_IN': 'pay-in',
                            'PAY_OUT': 'выплаты',
                            'BUSINESS': 'бизнес',
                            'RECURRENT': 'рекуррент',
                            'PERSONAL': 'персональный',
                        },
                        'network': 'Сеть',
                    }
                }
            },
            'advanced': {
                'date': {
                    'rows': {
                        'date': 'Дата',
                        'balance': 'Баланс',
                        'income': 'Приход',
                        'outcome': 'Расход',
                        'blockchain_commission': 'Блокчейн комиссия',
                        'refund': 'Возврат',
                        'tariffs': {
                            "ORPHAN_DEPOSIT_WITHDRAWAL_FEE": "Вывод сиротского депозита",
                            "PERSONAL_WITHDRAWAL_FEE": "Вывод с персонального адреса",
                            "PERSONAL_DEPOSIT_FEE": "Депозит на персональный адрес",
                            "RECURRENT_DEPOSIT_FEE": "Депозит на адрес-рекуррент",
                            "PAYOUT_WITHDRAWAL_FEE": "Вывод с выплатного баланса",
                            "WALLET_WITHDRAWAL_FEE": "Вывод с кошелька",
                            "WALLET_DEPOSIT_FEE": "Депозит на кошелек",
                            "COLLECT_WITHDRAWAL_FEE": "Вывод с головных",
                            "ORDER_DEPOSIT_FEE": "Депозит на ордер",
                            "KYT_WITHDRAWAL_ADDRESS_FEE": 'Риски вывода',
                            'KYT_TRANSACTION_FEE': 'Риски транзакции',
                            'RECURRENT_WITHDRAWAL_FEE': 'Вывод с адреса рекуррента',
                            'BRIDGE_INTERNAL_FEE': 'Блокчейн мост',
                            'BRIDGE_EXTERNAL_FEE': 'Блокчейн мост API',
                            'EXCHANGE_AUTO_FEE': 'Комиссия за обмен API',
                            "EXCHANGE_INTERNAL_FEE": "Внутренний обмен",
                            'PAYOUT_DEPOSIT_FEE': 'Пополнение выплатного баланса',
                            'TRANSFER_FEE': 'Внутренний перевод',
                            'KYT_ADDRESS_FEE': 'Риски адреса',
                            'PAYOUT_AUTO_SWAP_FEE': 'Выплаты + автообмен',
                            'PAYOUT_AUTO_SWAP_FEE_CORRECTION_DECR': 'Коррекция комиссии по автообмену',
                            'PAYOUT_AUTO_SWAP_FEE_CORRECTION_INCR': 'Коррекция комиссии по автообмену',
                        },
                        'networks-fee': {
                            'service': 'Комиссия сервиса',
                            'network': 'Комиссия сети',
                        }
                    }
                },
                'period': {
                    'rows': {
                        'date_start': 'Дата начала',
                        'date_end': 'Дата окончания',
                        'balance_start': 'Баланс, начало',
                        'balance_end': 'Баланс, конец',
                        'income': 'Приход',
                        'outcome': 'Расход',
                        'blockchain_commission': 'Блокчейн комиссия',
                        'refund': 'Refund',
                        'tariffs': {
                            "ORPHAN_DEPOSIT_WITHDRAWAL_FEE": "Вывод сиротского депозита",
                            "PERSONAL_WITHDRAWAL_FEE": "Вывод с персонального адреса",
                            "PERSONAL_DEPOSIT_FEE": "Депозит на персональный адрес",
                            "RECURRENT_DEPOSIT_FEE": "Депозит на адрес-рекуррент",
                            "PAYOUT_WITHDRAWAL_FEE": "Вывод с выплатного баланса",
                            "WALLET_WITHDRAWAL_FEE": "Вывод с кошелька",
                            "WALLET_DEPOSIT_FEE": "Депозит на кошелек",
                            "COLLECT_WITHDRAWAL_FEE": "Вывод с головных",
                            "ORDER_DEPOSIT_FEE": "Депозит на ордер",
                            "KYT_WITHDRAWAL_ADDRESS_FEE": 'Риски вывода',
                            'KYT_TRANSACTION_FEE': 'Риски транзакции',
                            'RECURRENT_WITHDRAWAL_FEE': 'Withdrawals from recurrent',
                            'BRIDGE_INTERNAL_FEE': 'Блокчейн мост',
                            'BRIDGE_EXTERNAL_FEE': 'Блокчейн мост API',
                            'EXCHANGE_AUTO_FEE': 'Комиссия за обмен API',
                            "EXCHANGE_INTERNAL_FEE": "Внутренний обмен",
                            'PAYOUT_DEPOSIT_FEE': 'Пополнение выплатного баланса',
                            'TRANSFER_FEE': 'Внутренний перевод',
                            'KYT_ADDRESS_FEE': 'Риски адреса',
                            'PAYOUT_AUTO_SWAP_FEE': 'Выплаты + автообмен',
                            'PAYOUT_AUTO_SWAP_FEE_CORRECTION_DECR': 'Коррекция комиссии по автообмену',
                            'PAYOUT_AUTO_SWAP_FEE_CORRECTION_INCR': 'Коррекция комиссии по автообмену'
                        },
                        'networks-fee': {
                            'service': 'Комиссия сервиса',
                            'network': 'Комиссия сети',
                        }
                    }
                }
            }
        }
    },
    'closed-session': {
        'title': 'Сообщение службы безопасности',
        'subtitle': 'Аккаунт заблокирован из-за дублирования сессии',
        'support': 'Свяжитесь с техподдержкой для уточнения деталей.',
        'contact-support': 'Связаться с техподдержкой',
    },
    'common': {
        'empty': 'Список пуст',
    },
    'common-labels': {
        'placeholders': {
            'input': 'Начните ввод'
        },
        'select-all': 'Выбрать все',
        'selected-label': 'Выбрано {selected} из {length}',
        'back-btn-label': 'Вернуться',
        'wallets': {
            'pay_in': 'Pay-in',
            'personal_address': 'Персональные адреса',
            'business_wallets': 'Бизнес-кошелек',
            'pay_out': 'Выплаты',
            'payment_addresses': 'Платежные адреса',
            'main': 'Головные адреса',
        }
    },
    'common-components': {
        'inputs': {
            'errors': {
                'empty': 'Не заполнено',
                'e-mail': 'Неверный формат почты',
            }
        },
        'modals': {
            'success': {
                'title': 'Успех',
                'btn': 'Готово'
            },
            'error': {
                'title': 'Ошибка',
                'body-text': "Пожалуйста, повторите попытку позже или обратитесь в службу поддержки",
                'btn': 'Закрыть'
            },
            'warning': {
                'title': 'Предупреждение',
                'body-text': "У вас нет прав. Вы можете обратиться в нашу тех. поддержку:",
                'btn': 'Ок'
            }
        },
        'date-picker': {
            'btn-label': 'Выбор',
            'btn-cancel': 'Отмена',
            'placeholder': {
                'period': 'Период',
                'date': 'Дата',
            }
        },
        'filter-toggler': {
            'btn-confirm-label': 'Подтвердить',
            'btn-reset-label': 'Сбросить',
        },
        'currency-network-select': {
            'label': 'Выберите вашу валюту и сеть',
            'placeholder': 'Выберите вашу валюту и сеть',
            'option': {
                'minimum': 'Минимум',
                'maximum': 'Максимум',
                'available-balance': 'Сумма балансов',
            }
        }
    },
    'profits': {
        'title': 'Сбор прибыли',
        'subtitle': 'Собирайте прибыль с ваших кошельков на один адрес без комиссии.',
        'history-title': 'История сборов',
        'create-title': 'Создание сбора прибыли',
        'update-title': 'Редактирование сбора прибыли',
        'history-individual-title': 'История сбора',
        'hash': 'Хэш...',
        'date': 'Дата',
        'alias': 'Название',
        'enter-alias': 'Введите название',
        'save': 'Сэкономлено',
        'save-btn': 'Сохранить',
        'amount': 'Сумма',
        'create-setting': 'Создать сбор',
        'create': 'Создать',
        'amount-fee-setup': 'Сумма сбора',
        'amount-modal': 'Сумма, USD',
        'currency-network': 'Валюта/Сеть',
        'address-from': 'Адрес откуда',
        'wallet-from': 'Кошелек откуда',
        'address-to': 'Адрес куда',
        'transaction': 'Транзакция',
        'status': 'Статус',
        'transactions-collected': 'Собранные транзакции',
        'empty-history-title': 'Истории нет',
        'empty-settings-title': 'У вас нет настроек сбора прибыли',
        'empty-history-subtitle': 'Начните собирать прибыль для заполнения истории',
        'empty-settings-subtitle': 'Создайте настройку сбора прибыли, нажав на кнопку создания настройки',
        'select-wallet': 'Выберите кошелек',
        'personal-wallet': 'Персональный',
        'personal-wallet-lowercase': 'персональный',
        'success': 'Успех',
        'success-desc-create': 'успешно создан',
        'success-desc-updated': 'успешно отредактирован',
        'success-desc-delete': 'Настройка была успешно удалена',
        'error': 'Ошибка',
        'error-conflict': 'Конфликт настроек',
        'error-internal': 'Внутренняя ошибка.  ID Запроса:',
        'error-amount': 'Минимальная сумма',
        'error-invalid-address': 'Неверный адрес',
        'close': 'Закрыть',
        'cancel': 'Отменить',
        'delete': 'Удалить',
        'delete-title': 'Удаление настройки',
        'delete-subtitle': 'Вы уверены что хотите удалить настройку сбора?',
        'wallet': 'Кошелек',
        'collected': 'Собрано',
        'address': 'Адрес',
        'amount-to-collect': 'Сумма для сбора',
        'history': 'История',
        'edit': 'Редактировать',

    },
    'auth-history': {
        'title': 'История авторизаций',
        'container-title': 'Пользователь',
        'date': 'Дата',
        'client': 'Клиент',
    },
    'zero-states': {
        'advanced-balances': {
            'title': 'Пополните баланс',
            'subtitle': 'Для списания комиссий и газа',
            'notifications': {
                'first-line': 'Авансовый баланс - специальный баланс для оплаты всех комиссий за транзакции. Это позволяет не забирать их из тела транзакции, и оперировать точными суммами при переводах.',
                'second-line': 'Если авансовый баланс станет отрицательным, вы можете продолжать принимать оплаты и переводы, но вывод будет приостановлен.'
            },
            'actions': {
                'replenishment': {
                    'title': 'Пополнить',
                    'info': 'Пополнить можно с внешнего кошелька или переведя полученные средства от клиентов с других балансов',
                    'btn-name': 'Пополнить'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Пополняйте авансовый баланс с помощью метода API.",
                    'btn-name': 'К методу'
                }
            }
        },
        'pay-in': {
            'title': 'Получите первую оплату',
            'subtitle': 'В данном разделе будут отображаться ваши балансы, на которые зачисляются оплаты по счетам и ордерам',
            'notifications': {
                'first-line': 'Мы автоматически создадим баланс в валюте оплаты клиента и зачислим на него средства.',
            },
            'actions': {
                'create-invoice': {
                    'title': "Создать счет",
                    'info': "Создайте счет на оплату товаров и услуг. Клиент заплатит в удобной ему криптовалюте и сети.",
                    'btn-name': 'Перейти'
                },
                'create-order': {
                    'title': "Создать ордер",
                    'info': "Отправьте готовый ордер, с выбранной валютой, сетью и суммой. Клиенту достаточно просканировать QR код для оплаты.",
                    'btn-name': 'Перейти'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте счета и ордера с помощью API. Вы всегда можете написать нам для получения технической помощи.",
                    'btn-name': 'Документация'
                }
            }
        },
        'invoices': {
            'title': 'Создайте первый счет',
            'subtitle': 'В этом разделе будут отображаться все счета, созданные как в ручном режиме, так и через API.',
            'actions': {
                'create-invoice': {
                    'title': "Создать счет",
                    'info': "Выберите валюту и сумму к оплате. Клиент выбирает и платит в удобной ему криптовалюте и сети.",
                    'btn-name': 'Создать'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте счета с помощью API. Вы всегда можете написать нам для получения технической помощи.",
                    'btn-name': 'К методу'
                }
            }
        },
        'orders': {
            'title': 'Создайте первый ордер',
            'subtitle': 'В этом разделе будут отображаться все ордера, созданные как в ручном режиме, так и через API.',
            'actions': {
                'create-order': {
                    'title': "Создать ордер",
                    'info': "Отправьте готовый ордер с выбранной валютой, сетью и суммой. Клиенту достаточно просканировать QR код для оплаты.",
                    'btn-name': 'Создать'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте ордера с помощью API. Вы всегда можете написать нам для получения технической помощи.",
                    'btn-name': 'К методу'
                }
            }
        },
        'api-keys': {
            'title': 'Здесь будут отображаться ваши API ключи',
            'subtitle': 'В данном разделе вы сможете создавать, редактировать и удалять ваши API ключи.',
            'notifications': {
                'first-line': 'Обратите внимание: При создании ключа, приватный ключ будет показан только один раз. Сохраните его в надежном месте.',
            },
            'actions': {
                'create-keys': {
                    'title': "Создать API ключи",
                    'info': "Создайте API ключ для работы с сервисом",
                    'btn-name': 'Создать'
                }
            },
        },
        'business-wallet': {
            'title': 'Создайте первый бизнес-кошелек',
            'subtitle': 'В данном разделе будут отображаться все ваши бизнес кошельки.',
            'notifications': {
                'first-line': 'Бизнес кошельки - криптовалютные адреса зарезервированные за вами, для приема оплат в разных сетях и монетах.',
            },
            'actions': {
                'create-wallet': {
                    'title': "Создать бизнес-кошелек",
                    'info': "Получайте оплаты переводами криптовалют напрямую от клиентов на свои адреса",
                    'btn-name': 'Создать'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте бизнес-кошельки с помощью метода API",
                    'btn-name': 'К методу'
                }
            }
        },
        'subscriptions': {
            'title': 'Создайте первую платежную связку',
            'subtitle': 'В данном разделе будет отображаться информация о ваших платежных связках.',
            'notifications': {
                'first-line': 'Платежные связки позволяют связать кошельки подписчиков и ваши адреса для приема платежей рекуррентных оплат с помощью создания подписок.',
            },
            'actions': {
                'create-merchant': {
                    'title': "Создать платежную связку",
                    'info': "Создайте платежную связку и получайте оплату от клиентов по подпискам на рекуррентные адреса.",
                    'btn-name': 'Создать'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте платежные связки с помощью API. Вы всегда можете написать нам для получения технической помощи.",
                    'btn-name': 'К методу'
                }
            }
        },
        'pay-out': {
            'title': 'Пополните выплатные балансы для их отображения',
            'subtitle': 'У вас нет активных балансов для выплат.',
            'notifications': {
                'first-line': 'Пополните из внешнего кошелька или переведите средства полученные от клиентов.',
            },
            'actions': {
                'create-replenishment': {
                    'title': "Пополнить с внешнего",
                    'info': "Пополните счет с внешнего кошелька",
                    'btn-name': 'Создать'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте выплатные балансы с помощью API. Вы всегда можете написать нам для получения технической помощи.",
                    'btn-name': 'К методу'
                }
            }
        },
        'transactions': {
            'title': 'Совершите транзакцию для первой записи в истории',
            'subtitle': 'Получите оплату или пополните любой счет с внешнего кошелька для появления первой записи в истории.',
            'notifications': {
                'first-line': 'Мы отобразим детализацию по каждой транзакции на вашем аккаунте.',
            },
            'actions': {
                'advanced-balance-replenishment': {
                    'title': "Пополнить авансовый баланс",
                    'info': "Пополнить можно с внешнего кошелька или переведя полученные средства от клиентов с других балансов.",
                    'btn-name': 'Перейти'
                },
                'create-invoice': {
                    'title': "Создать счет",
                    'info': "Создайте счет на оплату товаров и услуг. Клиент заплатит в удобной ему криптовалюте и сети.",
                    'btn-name': 'Перейти'
                },
                'create-order': {
                    'title': "Создать ордер",
                    'info': "Отправьте готовый ордер с выбранной валютой, сетью и суммой. Клиенту достаточно просканировать QR код для оплаты.",
                    'btn-name': 'Перейти'
                },
                'create-subscription': {
                    'title': "Создать платежную связку",
                    'info': "Создайте платежную связку и получайте оплату от клиентов по подпискам на рекуррентные адреса.",
                    'btn-name': 'Перейти'
                }
            }
        },
        'address-book': {
            'title': 'Добавьте первый адрес',
            'subtitle': 'В данном разделе будут отображаться ваши сохраненные адреса.',
            'notifications': {
                'first-line': 'Сохраните первый адрес и подставляйте его при выводе средств с сервиса.',
            },
            'actions': {
                'create-address': {
                    'title': "Сохранить в адресную книгу",
                    'info': "Добавьте и сохраните адрес для удобного вывода средств",
                    'btn-name': 'Добавить'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Добавляйте и сохраняйте адреса для последующего использования с помощью метода API",
                    'btn-name': 'К методу'
                }
            }
        },
        'orphan-transactions': {
            'title': 'Найдите потерянные транзакции',
            'subtitle': 'Здесь найдутся все ошибочные или потерянные транзакции от плательщиков.',
            'notifications': {
                'first-line': 'Если адрес принадлежит сервису то платеж точно не потеряется, даже если плательщик перепутал сеть или валюту.',
            }
        },
        'personal-addresses': {
            'title': 'Добавьте первого пользователя',
            'subtitle': 'Формируйте список адресов для каждого плательщика индивидуально.',
            'actions': {
                'add-user': {
                    'title': "Добавить пользователя",
                    'info': "Добавьте и сохраните адрес для удобного вывода средств",
                    'btn-name': 'Добавить'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте пользователей и адреса для них с помощью методов API",
                    'btn-name': 'К методу'
                }
            }
        },
        'subscriptions-addresses': {
            'title': 'Принимайте оплаты подписок',
            'subtitle': 'В данном разделе будут отображаться ваши платежные адреса для приема оплаты по подпискам.',
            'actions': {
                'create-subscription': {
                    'title': "Создать платежную связку",
                    'info': "Создайте платежную связку и получайте оплату от клиентов по подпискам на рекуррентные адреса.",
                    'btn-name': 'Перейти'
                },
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте платежные связки с помощью API. Вы всегда можете написать нам для получения технической помощи.",
                    'btn-name': 'К методу'
                }
            }
        },
        'bridge': {
            'title': 'Выполняйте мосты между сетями',
            'subtitle': 'Простой функционал смены сети для удобства расчетов с контрагентами',
            'notifications': {
                'first-line': 'Выполнить мосты в личном кабинете или методом API. Мосты выполняются асинхронно. О завершении мостов принимайте вебхуки.',
            },
            'actions': {
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте мосты с помощью метода API.",
                    'btn-name': 'К методу'
                }
            }
        },
        'swap': {
            'title': 'Выполняйте обмен валют',
            'subtitle': 'Простой функционал для фиксации прибыли',
            'notifications': {
                'first-line': 'Выполнить обмены в личном кабинете или методом API. Обмены выполняются асинхронно. О завершении обменов принимайте вебхуки.',
            },
            'actions': {
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Создавайте обмены с помощью метода API.",
                    'btn-name': 'К методу'
                }
            }
        },
        'webhooks': {
            'title': 'Отслеживайте сообщения',
            'subtitle': 'Быстро и удобно отладить код при интеграции',
            'notifications': {
                'first-line': 'В разделе будут отображаться все отправленные вебхуки со всеми данными в сообщении. Также доступно повторно отправить вебхуки.',
            },
            'actions': {
                'api-integration': {
                    'title': "API интеграция",
                    'info': "Подробности отправки и рекомендации по обработке вебхуков.",
                    'btn-name': 'Документация'
                }
            }
        },
        'organization': {
            'title': 'Управляйте организацией',
            'subtitle': 'Приглашайте пользователей в свою организацию и создавайте роли с индивидуальными настройками доступа для каждого',
            'notifications': {
                'first-line': 'В данном разделе будет отображаться вся информация о вашей организации',
            },
            'actions': {
                'create-role': {
                    'title': "Создать роль",
                    'info': "Создавайте роли для каждого пользователя с индивидуальными настройками доступа",
                    'btn-name': 'Создать'
                },
                'add-user': {
                    'title': "Добавить пользователя",
                    'info': "Добавляйте пользователей в свою организацию. Обратите внимание: прежде чем добавлять пользователя необходимо создать роль",
                    'btn-name': 'Добавить'
                },
            }
        },
        'profit-collection': {
            'title': 'Создайте настройку по сбору прибыли',
            'subtitle': 'В разделе будут отображаться все созданные настройки сбора прибыли',
            'notifications': {
                'first-line': 'Сбор прибыли создается только для Pay-in и Персональных кошельков. Как только на адресе монеты и сети указанной в настройке скапливается сумма не менее указанной в сборе прибыли, происходит автоматический вывод всего баланса адреса на адрес указанный также в настройке сбора прибыли. При этом комиссия вывода отсутствует.',
            },
            'actions': {
                'create-collection': {
                    'title': "Создать сбор",
                    'info': "Создавайте настройки сбора прибыли и автоматически выводите полученную прибыль на свой адрес",
                    'btn-name': 'Создать'
                }
            }
        },

    },
    'bank': {
        'alias': 'Псевдоним',
        'enter-alias': 'Введите псевдоним',
        'enter-sepa-swift': 'Выберите SEPA/SWIFT',
        'enter-iban': 'Введите IBAN',
        'wallet': 'Валюта',
        'select-wallet': 'Выберите валюту',
        'company': 'Компания',
        'enter-company': 'Введите название компании',
        'enter-bank-address': 'Введите адрес',
        'bank-address': 'Адрес банка',
        'bank': 'Банк',
        'enter-bank': 'Введите название банка',
        'bank-code': 'Код страны банка',
        'enter-bank-code': 'Введите код',
        'enter-bic': 'Введите SWIFT/BIC',
        'postal-code': 'Почтовый индекс',
        'enter-postal-code': 'Введите почтовый индекс',
        'add-invoice': 'Создать',
        'deposit': 'Депозит',
        'withdraw': 'Вывод',
        'create-account': 'Создать',
        'status': 'Статус',
        'type': 'Тип',
        'account': 'Счет',
        'enter-account': 'Введите счет',
        'info-title': 'Детали счета',
        PRE_MODERATION: 'Создан',
        MODERATION: 'На модерации',
        REJECTED: 'Отклонен',
        SUCCESS: 'Подтвержден',
        'iban-error': 'Неверный IBAN',
        'country-code-error': 'Неверный код страны',
        'info': 'Информация',
    },
    'iframe-order': {
        'labels': {
            'order': 'Ордер',
            'currency': 'Валюта',
            'e-mail': 'Введите ваш адрес электронной почты',
            'amount': 'Сумма',
            'address': 'Адрес',
            'paid-amount': 'Сумма оплаты',
            'remaining-time': 'Оставшееся время для оплаты'
        },
        'placeholders': {
            'e-mail': 'E-mail'
        },
        'btn': {
            'continue': 'Далее'
        },
        'info': {
            'cookie-policy': {
                'by-clicking': 'Нажав на кнопку «Далее», вы автоматически соглашаетесь c',
                'our': 'нашей политикой использования файлов cookie',
            },
            'amount': {
                'in-eur': 'Сумма в EUR',
                'in-usd': 'Сумма в USD',
                'exchange-rate': 'Обменный курс',
                'payment-id': 'ID платежа',
            },
            'payment-history': {
                'title': 'История платежей',
                'date': 'Дата',
                'amount': 'Сумма',
                'tx-hash': 'Tx_hash',
                'empty': 'Пусто'
            },
            'status': {
                'success': 'Успешная оплата',
                'expired': 'Время вышло',
                'wrong-organization-id': 'Неверный идентификатор организации',
                'wrong-order-id': 'Неверный идентификатор ордера'
            },
            'popup': {
                'partially-paid': {
                    'title': 'Частично оплачено',
                    'info': 'Сумма оплачена частично. Оставшаяся сумма должна быть внесена для успешной оплаты.'
                },
                'orphan-danger': {
                    'title': 'Сиротская транзакция',
                    'info': 'Одна из транзакций этого ордера содержит неправильную сеть или валюту.',
                    'support': 'Свяжитесь со службой технической поддержки по ID:'
                }
            }
        }
    },
    addressRisks: {
        'white': 'Безопасный',
        'green': 'Низкий',
        'yellow': 'Cредний',
        'red': 'Высокий',
        'black': 'Недопустимый',
        'unchecked': 'Непроверенный',
        'placeholder': 'Риск'
    },
    testnet: {
        'testnet-desc': 'доступна организация <br> для проведения тестов при интеграции',
        'create': 'Создать Testnet',
        'redirect': 'Перейти',
        'faucet-title': 'Faucet от Onchainpay',
        'faucet-title-apollo': 'Faucet от Apollopayment',
        'faucet-desc': 'Если у вас не получилось воспользоваться известными Faucet’ами, напишите нам и мы оперативно поможем',
        'modal-testnet-error-title': 'Не удалось создать Testnet',
        'modal-testnet-create-title': 'Testnet создан',
        'modal-testnet-error-desc': 'Не удалось создать Testnet организацию. Обратитесь в тех. поддержку или попробуйте позже.',
        'modal-testnet-create-desc': 'Новая Testnet организация успешно создана. Перейдите с помощью модального окна или через выбор организации.',
        'go-to-testnet': 'Перейти в Testnet',
        'close': 'Закрыть',
        'support': 'Тех.поддержка',
        'settings-title': 'Настройки интеграции',
        'settings-desc': 'Настройте свои параметры один раз и используйте их в каждом API-запросе автоматически',

    },
    'directory-currencies': {
        'page-title': 'Валюты и сети',
        'filters': {
            'placeholders': {
                'currency': 'Валюта',
                'network': 'Сеть',
            },
            'currency-select': {
                'selected-label': '{selected} из {length} сетей',
            },
            'network-select': {
                'selected-label': '{selected} из {length} валют',
            },
        }
    },
    'integration-settings': {
        'title': 'Настройки интеграции',
        'elements': {
            'time-picker': {
                'placeholder': 'Выберите период'
            },
            'buttons': {
                'labels': {
                    'save': 'Сохранить',
                    'cancel': 'Отмена',
                    'undo': 'Вернуть'

                }
            },
            'currency-select': {
                'selected-label': '{selected} из {length} валют',
            },
            'text-field': {
                'labels': {
                    'name': 'Имя',
                    'type': 'Тип',
                    'description': 'Описание',
                    'value': 'Значение'
                }
            },
            'inputs': {
                'placeholders': {
                    'url': 'Введите URL',
                    'percent': 'Введите процент'
                }
            },
            'selects': {
                'placeholders': {
                    'currency': 'Выберите валюту и сеть'
                }
            },
            'info-modal': {
                'title': 'Информация',
                'body': {
                    'to-configure': 'Для настройки параметров в API-запросах используйте строку шаблона. Строка шаблона позволяет использовать любой передаваемый параметр из текущего запроса, а также собственные наименования параметров.',
                    'example': 'Пример:',
                    'support': 'Предположим, у вас есть следующий запрос:',
                    'to-use': 'Чтобы использовать paramFoo в строке шаблона, укажите следующее:',
                    'on-the-server': 'На стороне сервера строка будет собрана следующим образом:',
                    'thus': 'Таким образом, параметры из текущего запроса будут автоматически подставлены в строку шаблона, что позволит гибко настраивать запросы к API.',
                    'important': 'Важно!',
                    'if-you': 'Если вы указали шаблон с параметрами, но при запросе не передали эти параметры, то мы ответим ошибкой о необходимости указания параметров для формирования строки из шаблона.'
                }
            }
        },
        'settings': {
            'create-user': {
                'name': 'Создание персональных адресов',
                'params': {
                    'description': {
                        'depositWebhookUrl': 'URL для уведомлений о новых пополнениях',
                        'createAddresses': 'Создать все адреса для пользователя. В ответе вернется массив данных по адресам в параметре addresses',
                        'groupByBlockchain': 'Сгруппировать адреса по блокчейн сетям (например, 1 адрес для сетей bsc, fantom, ethereum). Данный параметр имеет смысл указвать только при включенном параметре createAddresses: true',
                    }
                }
            },
            'make-order': {
                'name': 'Создание ордера h2h',
                'params': {
                    'description': {
                        'paymentTolerancePercent': 'Допустимый процент недоплаты. Например, ордер выставлен на 100 USDT. Если параметр установлен как 1.5%, то ордер завершится с успехом при оплате 98.5 USDT',
                        'errorWebhook': 'URL для отправки вебхука при ошибке или истечении ордера',
                        'successWebhook': 'URL для отправки вебхука при успешной оплате',
                        'returnUrl': 'URL для размещения на странице оплаты в качестве ссылки "Вернуться в магазин"',
                        'lifetime': 'Время жизни ордера в секундах, доступные значения от 1800 (30 минут) до 43200 (12 часов)'
                    }
                }
            },
            'make-invoice': {
                'name': 'Создание счета',
                'params': {
                    'description': {
                        'includeFee': 'Флаг позволяет включить в сумму к оплате комиссию сети блокчейна выбранного для оплаты. Будет полезно чтобы заложить свои издержки на вывод монет после оплаты.',
                        'insurancePercent': 'Позволяет добавить к сумме оплаты указанный процент',
                        'slippagePercent': 'При открытии страницы инвойса пользователь может провести на ней столько времени, что курс сменится. Если после перехода к оплате сумма изменится больше указанного процента, то сумма к оплате будет пересчитана по текущему курсу',
                        'paymentTolerancePercent': 'Допустимый процент недоплаты ОРДЕРА. Например, после выбора монеты счета был создан ордер на 100 USDT. Если параметр установлен как 1.5%, то ордер завершится с успехом при оплате 98.5 USDT',
                        'webhookURL': 'URL для уведомлений при изменении статуса счета или полученной суммы',
                        'returnURL': 'URL для указания в качестве "Вернуться в магазин" на странице оплаты',
                        'lifetime': 'Время жизни счета в минутах',
                        'currencies': 'Список монет и сетей доступных для оплаты, при указании пустого массива будут выбраны все монеты/сети доступные в системе'
                    }
                }
            }
        }
    },

    'payments': {
        'total-balance': 'Общий баланс',
        'available-withdrawal': 'Доступно для вывода',
        'total-increase': 'Oбщий рост',
        'number-orders': 'Количество созданных ордеров',
        'total-personal': 'Количество персональных депозитов',
        'personal-deposits': 'Персональные депозиты',
        'payments-orders': 'Платежи по ордерам',
        'amount-next-collection': 'Сумма следующего сбора',
        'dirty-crypto': 'Грязная криптовалюта',
        'dust': 'Пыль',
        'next-collection': 'Следующий сбор',
        'frequency-collection': 'Частота сбора',
        'minimum-collection': 'Минимальная сумма для сбора',
        'date-collection': 'в {time} UTC',
        'days': 'дней',
        'day': 'день',
        'short-days': 'дня',
        'currency': 'Валюта',
        'network': 'Сеть',
        'expand': 'Раскрыть',
        'withdraw': 'Вывести',
        'swap-confirmation-title': 'Подтверждение <br> обмена',
        'swap': 'Обмен',
        'cancel': 'Отменить',
        'confirm': 'Подтвердить',
        more: 'Подробнее',
        'dirty-tooltip-title': 'Грязная криптовалюта',
        'dirty-tooltip-desc': 'Это криптовалюта, которую использовали или подозревают в использовании для незаконной деятельности.',
        'dust-tooltip-title': 'Пыль',
        'risk-tooltip-title': 'Риски',
        'risk-tooltip-desc': 'Идентификатор использования цифровых активов для незаконной деятельности.',
        'dust-tooltip-desc': 'Эта сумма монет, стоимость которых не покрывает даже комиссию за транзакцию и их сложно использовать.',
        'zero-state': {
            'all-balances': 'Все балансы в одном месте',
            'get-payments': 'Принимайте платежи на разные адреса и управляйте средствами в одном месте',
            'total-balance': 'Общий баланс по валюте и сети',
            'swap-click': 'Обмены в пару кликов',
            'more': 'Подробная аналитика по каждому балансу',
            'main-addresses': 'Головные адреса',
            'enable-access': 'Включите полный доступ к функционалу головных адресов с помощью одной кнопки',
            'enable-aml': 'Включить AML',
            'enable': 'Включить',
            'convenient': 'Удобное и понятное отслеживание риска платежей в разрезе валюты или сети.'
        }
    },
    'info-banners': {
        'migration': 'Уведомляем, что мы провели <b>миграцию с MATIC на POL</b>, все ваши активы были автоматически перенесены'
    },
    'widgets': {
        title: "Виджеты",
        addWidget: "Создать виджет",
        sumOfDeposits: "Сумма депозитов",
        countOfDeposits: "Количество депозитов",
        payin: "Прием платежей",
        payout: "Выплаты",
        invoices: "Счета",
        yourSiteUrl: 'URL вашего сайта',
        siteUrl: 'URL сайта',
        enterSiteUrl: 'Введите URL сайта',
        personal: "Персональные адреса",
        active: "Включены",
        disabled: "Выключены",
        demo: "Демо",
        demoMode: "Демо режим",
        demoModeDesc: "Для проверки работы виджета, заполните динамичные данные и перейдите по ссылке.",
        edit: "Редактировать",
        return: 'Вернуться',
        back: 'Назад',
        depositWidget: 'Виджет депозита',
        customField: 'Custom Field для вебхука',
        enterCustomField: 'Введите значение для customField',
        payoutWidget: 'Виджет выплаты',
        copyLink: 'Копировать',
        copied: 'Скопировано!',
        goto: 'Перейти',
        widgetAlias: 'Имя виджета',
        deposit: 'Депозит',
        withdraw: 'Вывод',
        startPageLocked: 'При выборе прямой ссылки, окно отсутствует',
        startPage: 'Стартовая страница',
        oneOption: '(Выберите один вариант)',
        startPageDesc: 'С помощью стартовой страницы ваш плательщик сможет самостоятельно выбрать депозит или вывод без лишней интеграции с вашей стороны.',
        directLink: 'Прямая ссылка',
        directLinkDesc: 'Созданная ссылка будет ввести напрямую в выбранный функционал.',
        paymentAccept: 'Прием оплаты',
        personalDesc: 'Позволяет формировать список адресов для каждого плательщика индивидуально. Адреса закрепляются за плательщиком.',
        invoicesDesc: 'Прием оплаты через выставление счета. Вы или ваш плательщик можете указать валюту счета ( в том числе фиат) и сумму и оплатить в одной из криптовалют.',
        payoutDesc: 'Функционал позволяет совершать переводы на внешние адреса вашего пользователя.',
        freePayment: 'Свободный платеж',
        commissionOnPayer: 'Комиссия на плательщике',
        apiKey: 'API Ключ',
        allowablePercent: 'Допустимый % на курс обмена',
        allowableSlippage: 'Допустимый % для проскальзывания цены',
        allowableUnderpayment: 'Допустимый % для недоплаты счета',
        enterAllowableUnderpayment: 'Введите допустимый %',
        enter: 'Введите',
        enterWebhook: 'Введите webhook',
        enterApprovalWebhook: 'Введите webhook подтверждения',
        paymentCurrency: 'Валюта оплаты',
        payoutCurrency: 'Валюта выплаты',
        invoiceCurrency: 'Валюта счета',
        approvalWebhook: 'Webhook подтверждения',
        enterAllowablePercent: 'Введите допустимый %',
        enterSlippagePercent: 'Введите допустимый %',
        lockedAmount: 'Параметр выключен. Выберите только одну валюту счета для настройки',
        invoiceAmount: 'Сумма счета',
        payoutAmount: 'Сумма выплаты',
        orderId: "ID счета",
        description: "Описание",
        email: "Email адрес",
        theme: "Тема",
        lightTheme: 'Светлая тема',
        darkTheme: 'Темная тема',
        lockedFreePayment: 'Параметр выключен. Для настройки выключите “свободный платеж”',
        lockedEmail: 'Параметр выключен. Для настройки включите “поле E-mail”',
        emailEnabled: 'Поле ввода E-mail доступно',
        emailRequired: 'E-mail обязателен для ввода',
        warning: 'Внимание!',
        warningDeleteDesc: 'Вы действительно хотите <span class="desc-accent">удалить виджет?</span>',
        delete: 'Удалить',
        noApiKeys: 'У вас отсутствуют API ключи',
        noApiKeysDesc: 'Перед созданием виджета, создайте API ключ в разделе "API ключи"',
        createApiKey: 'Создать API ключ',
        next: 'Далее',
        end: 'Завершить',
        enterOrderId: 'Введите order ID',
        enterSum: 'Введите сумму',
        enterEmail: 'Введите e-mail адрес',
        enterDescription: 'Введите описание',
        paymentButtonTag: "Тэг платежной кнопки",
        iframeIntegrationLink: "Ссылка для iframe интеграции",
        paymentButtonScript: "Скрипт платежной кнопки",
        iframeIntegrationLinkDesc: "Вставьте ссылку в <iframe> HTML тэг.",
        paymentButtonScriptDesc: "Для установки виджета необходимо прописать на сайте скрипт в раздел head.",
        paymentButtonDesc: "Для отображения платежной кнопки, вставьте HTML-тег кнопки в нужное место на странице, где необходимо ее разместить.",
        receiveCodeDesc: "Код приема",
        payoutCodeDesc: "Код выплат",
        buttonPreview: "Предпросмотр платежной кнопки",
        buttonPreviewDesc: "Введите текст который хотите видеть на кнопке",
        enterButtonLabel: "Введите текст кнопки",
        mainStyle: "Основной стиль",
        or: "Или",
        tooltip: {
            description: 'Свободное поле с описанием для плательщика.',
            email: 'Параметр отвечает за передачу Email в виджет в формате email@example.com.',
            orderId: 'Ваш уникальный идентификатор платежа.',
            invoiceAmount: 'Параметр отвечает за выставлени суммы для вашего счета. Поле доступно только при одной выбранной валюте счета',
            payoutAmount: 'Параметр отвечает за выставлени суммы для вашего счета. Поле доступно только при одной выбранной валюте выплаты',
            siteUrl: 'Вставьте ссылку сайта, на котором будет использован виджет. Формат: https://www.service.com/',
            apiKey: 'Для работы виджета, необходимо выбрать API ключ. Если он не создан, создайте его в отдельной вкладке.',
            webhook: 'Для взаимодействия с виджетом и приема сообщений об оплате, вам необходимо указать ссылку для принятия вебхуков. Формат: https: https://www.webhook.com/',
            freePayment: 'Включите этот параметр, если вы хотите принимать оплату без определенной валюты и суммы. Останется выбрать лишь валюты оплаты',
            commissionOnPayer: 'Выберите кто будет платить комиссию за транзакцию - вы или ваш плательщик.',
            allowablePercent: 'Добавочный процент курса обмена из валюты счета в валюту оплаты счета. По умолчанию выставлен 0%.',
            allowableSlippage: 'Допустимое значение изменения цены в процентах валюты оплаты при высокой волатильности. По умолчанию выставлен 0%.',
            underpayment: 'Допустимое значение для недоплаты счета.',
            invoiceCurrency: 'Доступные валюты для выставления счета. Доступна криптовалюта и фиат',
            paymentCurrency: 'Доступные валюты для оплаты в криптовалюте.',
            emailAllowed: 'Параметр отвечает за отображение поля Email в виджете.',
            emailRequired: 'Параметр отвечает за необходимость ввода Email плательщика при оплате.',
            approvalWebhook: 'Ссылка, куда мы будем отправлять вебхуки подтвереждения для вывода',
            payoutCurrency: 'Параметр отвечает за валюту выплаты ( не валюта оплаты ). Может быть (USD) одна валюта или массив (USD, USDT, EUR, RUB, USDT, ETH).',
            customField: 'Свободное поле для дополнительной идентификации платежа.'
        }
    },
    buyCrypto: {
        title: 'Купить криптовалюту'
    }
}
