<template>
  <div>
    <div class="preview-transaction-new" @click="openTransaction">
      <div :class="['preview-transaction-new__inner-row', !isActiveColBorder ? 'border-bottom-row' : '']">
          <template v-for="({ title , active}) in columns">
            <p v-if="active" class="preview-transaction-new__col">
              <field-value
                :field="title"
                :type="fieldsType[title]"
                :value="getValue(title)"
                :transaction="data"
              />
            </p>
          </template>
      </div>
    </div>
    <div :class="['preview-transaction-new_mobile']">
      <div :class="['preview-transaction-new__mobile']" >
          <div class="preview-transaction-new__preview" @click="openTransaction">
              <div :class="['preview-transaction-new__info-status', `preview-transaction-new__info-status--${data.status}`]"></div>
              <div class="preview-transaction-new__currency" :style="`--colorStatus:${mobileStatusTextColor};`">
                  <div class="preview-transaction-new__currency-container">

                      <p class="preview-transaction-new__currency-text">
                          {{ transactionTypeTranslate }}
                      </p>
                      <p class="secondary-text">{{ id | preparedString }}</p>
                  </div>

              </div>

              <div class="preview-transaction-new__amount-container">
                  <p class="preview-transaction-new__preview-text preview-transaction-new__preview-text_deposit negative"
                     v-if="getValue('amountFrom') ">
                      - {{ getValue('amountFrom') }}
                  </p>

                  <p class="preview-transaction-new__preview-text preview-transaction-new__preview-text_deposit positive"
                     v-else-if="getValue('amountTo')">
                      + {{ getValue('amountTo') }}
                  </p>

                  <span class="secondary-text">{{ currencyAlias }}<template v-if="networkId">&nbsp;{{ networkName | getNetworkShortNameByNetworkName }}</template></span>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import AtCollapse from 'at-ui/src/components/collapse/';
import AtCollapseItem from 'at-ui/src/components/collapse-item/';
import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import {
    getCurrencyInfoById,
    getNetworkSystemName,
    transactionHistoryBasis,
    transactionHistorySource,
    transactionHistoryStatus,
    transactionHistoryType, transferAddressType,
} from "../../../filters";
import {TRANSACTION_HISTORY_STATUS_ENUM} from "../../../../common/constants/transaction-history-status-enum";
import BigNumber from "bignumber.js";
import Copy from "@/public/components/common/copy.vue";
import CurrencyNetworkIcon from "@/public/components/elements/orphan/Select/CurrencyNetworkIcon.vue";
import {BlockchainLinks} from "@/public/mixins/blockchain-links";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
    name: 'PreviewTransaction',
    components: {
        FieldValue: () => import(/* webpackChunkName: "public/components/elements/transactions/TransactionInfoNew/FieldValue.vue" */ '/public/components/elements/transactions/TransactionInfoNew/FieldValue.vue'),
        BaseButton,
        CurrencyNetworkIcon,
        Copy,
        CopyString,
        CurrencyImg,
        AtCollapse,
        AtCollapseItem,
    },
    mixins: [
        BlockchainLinks,
    ],
    props: {
        data: {
            type: Object,
            required: true,
        },
        routeName: {
            type: String,
            default: 'transaction',
        },
        fieldsType: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            isPreviewOpened: false
        }
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapState('transactions', ['columns']),
        activeColumnsCount () {
          return this.columns.filter(({active}) => active).length
        },
        isActiveColBorder () {
            return this.activeColumnsCount > 4
        },
        networkName() {
            const name = getNetworkSystemName(this.networkId);
            return name === '-' ? '' : name
        },
        id() {
            return this.data.id;
        },
        type({ data }) {
            return data.transactionType;
        },
        transactionTypeTranslate({ type }) {
          const pre = 'transactions_new.transaction_types'


          return this.$t(`${pre}.${type}`)
        },
        status() {
            return transactionHistoryStatus(this.data.status);
        },
        basis() {
            return transactionHistoryBasis(this.data.basis);
        },
        source() {
            return transactionHistorySource(this.data.side);
        },
        createdAt() {
            return this.data.createdAt;
        },
        method() {
            return this.$options.filters.transactionHistoryMethod(this.data.source);
        },
        networkId() {
            return this.data.networkToId;
        },
        isAddressToTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.data.addressTo] || false;
        },
        isAddressFromTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.data.addressFrom] || false;
        },
        currencyFrom() {
            const {
                data: {
                    currencyFrom,
                },
            } = this;

            return currencyFrom;
        },
        currencyTo() {
            const {
                data: {
                    currencyToId,
                },
            } = this;

            return currencyToId;
        },
        currencyAlias () {
          return getCurrencyInfoById(this.data.currencyToId).alias
        },
        mobileStatusTextColor() {
            switch (this.data.status) {
            case TRANSACTION_HISTORY_STATUS_ENUM.INIT:
                return '#6190e8';
            case TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED:
                return '#13ce66';
            case TRANSACTION_HISTORY_STATUS_ENUM.PENDING:
                return '#6190e8';
            case TRANSACTION_HISTORY_STATUS_ENUM.ERROR:
                return '#EB5757';
            case TRANSACTION_HISTORY_STATUS_ENUM.REJECTED:
                return '#EB5757';
            default:
                return '#8F949A';
            }
        },
        hasCurrencyFrom() {
            return Boolean(this.currencyFrom);
        },
        hasCurrencyTo() {
            return Boolean(this.currencyTo);
        },
        orderId() {
            return this.data.orderId;
        },
        hasOrderId() {
            return Boolean(this.orderId);
        },
        orderCreatedAt() {
            return this.data.orderCreatedAt;
        },
        credit() {
            if (this.data.credit) {
                const credit = new BigNumber(this.data.credit);
                return credit.decimalPlaces(8).toNumber();
            }
            return null;
        },
        deposit() {
            if (this.data.deposit) {
                const credit = new BigNumber(this.data.deposit);
                return credit.decimalPlaces(8).toNumber();
            }
            return null;
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },
        addressToTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.data.addressTo));
        },
        addressFromTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.data.addressFrom));
        },
    },
    methods: {
        openTransaction () {
            this.$emit('openTransaction', this.id)
        },
        txLink(txId, networkId) {
            return this.$txLink(txId, networkId);
        },
        togglePreview () {
            this.isPreviewOpened = !this.isPreviewOpened
        },
        getValue(v) {
          return this.data[v]
        }
    }
};

</script>

<style lang="scss" scoped>
p {
    color: var(--new-front-primary-font-color);
}
.border-bottom-row {
  border-bottom: 1px solid var(--new-front-divider-color);
    .preview-transaction-new__col {
        border-bottom: none;
    }
}
.secondary-text {
  color: var(--new-front-secondary-font-color);
    font-weight: 500;
}
.preview-transaction-new__amount-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.preview-transaction-new__info-status {
    width: 3px;
    height: 55px;
    border-radius: 13px;
    position: absolute;
    left: 0;
    padding: 0;
    &--init {
        background: var(--new-front-status-text-active);
    }
    &--expired {
        background: var(--new-front-status-text-expired);
    }
    &--processed {
        background: var(--new-front-status-text-processed);
    }
    &--pending {
        background: var(--new-front-status-text-pending);
    }
    &--partial {
        background: var(--new-front-status-text-pending);
    }
    &--error {
        background: var(--new-front-status-text-error);
    }
    &--rejected {
        background: var(--new-front-status-text-error);
    }
}
.item__status {
  width: 130px;
  height: 32px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 15px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  &--init {
    color: var(--new-front-status-text-active);
    background: var(--new-front-status-active);
  }
  &--expired {
    color: var(--new-front-status-text-expired);
    background: var(--new-front-status-expired);
  }
  &--processed {
    color: var(--new-front-status-text-processed);
    background: var(--new-front-status-processed);
  }
  &--pending {
      color: var(--new-front-status-text-pending);
      background: var(--new-front-status-pending);
  }
  &--partial {
    color: var(--new-front-status-text-pending);
    background: var(--new-front-status-pending);
  }
  &--error {
    color: var(--new-front-status-text-error);
    background: var(--new-front-status-error);
  }
    &--rejected {
        color: var(--new-front-status-text-error);
        background: var(--new-front-status-error);
    }
  @media (max-width: 950px) {
    font-size: 13px;
  }
}
.preview-transaction-new {
  padding: 0;
  position: relative;
  color: var(--new-front-primary-font-color);
  cursor: pointer;
&__btn {
    margin-top: 20px;
}
  &_mobile {
    display: none;
  }
    &__chevron {
        position: absolute;
        right: 25px;
        transition: transform .3s ease;
        &--active {
            transform: rotate(180deg);
        }
    }
  &:hover {

    & .preview-transaction-new__col {
      background-color: var(--new-front-main-bg);
    }

  }

  &__inner-row {
    display: grid;
    grid-template-columns: repeat(61, 185px);
    width: 100%;
    min-height: 88px;
    align-items: center;
  }

  &__write_off_currency {
    text-align: start;

  }

  &__col {
    font-size: $h4;
    font-weight: 500;
    font-family: $Gilroy;
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
    text-align: start;
    min-height: 85px;
    display: flex;
    align-items: center;
    word-break: break-word;
      border-bottom: 1px solid var(--new-front-divider-color);
    a {
      color: var(--new-front-purple);
    }
    //
    //&_deposit {
    //  color: var(--green-200);
    //}
    //
    //&_credit {
    //  color: var(--red-200);
    //}

    //&_status {
    //  color: var(--colorStatus);
    //}

    &:first-child {
      padding-left: 40px;
    }

    &:last-child {
      padding-right: 40px;
      //justify-content: flex-end;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 255%;
    height: 100%;
    z-index: 0;
  }

  &__id {
    position: relative;
    z-index: 2;
    a {
      color: var(--new-front-purple);
    }
  }

  &__currency {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  &__currency_write-off {
    align-items: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 1330px) {
  .preview-transaction-new {
    &__col {
      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }

}

@media screen and (max-width: 950px) {
    ::v-deep {
        .copy-wrapper {
            margin-right: 0;
        }
    }
  .preview-transaction-new {

    display: none;

    &__title-text {
      font-size: $h6;
      font-family: $Gilroy;
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color);
      text-align: right;

      &_secondary {
        color: var(--new-front-secondary-font-color);
          font-weight: 500;
      }
    }

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 53px;
      border-bottom: 1px solid var(--new-front-divider-color);
      color: var(--new-front-primary-font-color);
        font-size: $h6;
        font-weight: 500;
        a {
            color: var(--new-front-purple);
        }
        &:last-of-type {
            margin-bottom: 20px;
        }
      ::v-deep .copy-string__string {
        font-family: $Gilroy;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: var(--new-front-primary-font-color);
      }
        &-value {
            display: flex;
            align-items: center;
        }
    }

    &__preview-text {
        font-size: $h6;
        font-weight: 500;
      font-family: $Gilroy;
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color);
      text-align: right;

      //&_deposit {
      //  color: var(--green-200);
      //}
      //
      //&_credit {
      //  color: var(--red-200);
      //}
    }

    &__currency {
      &-status {
        font-family: $Gilroy;
        font-style: normal;
        font-weight: 400;
        font-size: var(--font-size-text-caption);
        line-height: var(--line-height);
        color: var(--colorStatus);
      }

      &-text {
        font-size: var(--font-size-text-main);
        font-family: $Gilroy;
        line-height: var(--line-height);
        color: var(--new-front-primary-font-color);


      }
    }

    &_mobile {
      display: block;
        max-height: 68px;
        overflow: hidden;
        transition: max-height .3s ease;
        border-bottom: 1px solid var(--new-front-divider-color);
        &--active {
            max-height: 650px;
        }
    }

    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 20px;
        padding-left: 20px;
      align-items: center;
        min-height: 68px;
        cursor: pointer;
        position: relative;
    }

    &__read-more {
      width: 100%;
      max-width: 300px;
      border-radius: 12px;
      background: #0059F7;
      font-size: var(--font-size-text-main);
      font-family: $Gilroy;
      line-height: var(--line-height);
      text-align: center;
      color: var(--white-100);
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    &__detailed-info {
      align-items: center;
      display: flex;
      flex-direction: column;
        padding: 0 20px;
        margin-bottom: 20px;
    }

    &__mobile {
      display: block;

      ::v-deep .at-collapse {
        border: 1px solid var(--new-front-divider-color);
        border-right: none;
        border-left: none;
      }

      ::v-deep .at-collapse__content {
        padding: 0 10px;
        background: var(--secondary-color);
      }

      ::v-deep .at-collapse__header {
        background: var(--secondary-color);
        min-height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px;
      }

      ::v-deep .at-collapse__icon {
        right: 10px;
        top: 17px;
        font-size: 20px;
        left: unset;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
      }
    }
  }
}

.positive {
    color: var(--new-front-text-success-bg-secondary) !important;
}
.negative {
    color: var(--new-front-status-text-error) !important;
}
.df {
  display: flex;
}
.addressBookAlias {
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 5px
}
</style>
